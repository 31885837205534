import { makeObservable, observable } from 'mobx';
import { getProducts, getById } from 'src/endpoints/products';
import genericErrorHandler from 'src/utils/genericErrorHandler';

class ProductsStore {
  data = [];

  product = [];

  isLoading = false;

  constructor() {
    makeObservable(this, { data: observable, isLoading: observable, product: observable });
  }

  getAll = async () => {
    this.isLoading = true;

    const { content } = await getProducts();
    this.data = content;

    this.isLoading = false;
  };

  getProductById = async (id) => {
    this.isLoading = true;

    const content = await getById(id);
    this.product = content;

    this.isLoading = false;
  };
}

export default new ProductsStore();
