import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { Add } from '@carbon/react/icons';
import { CreateClientParams } from 'src/endpoints/clients';
import { useStores } from 'src/stores';
import {
  Dropdown,
  TextInput,
  Button,
  Grid,
  Column,
  DatePicker,
  DatePickerInput,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Loading,
  NumberInput,
  Form,
  FormLabel,
} from '@carbon/react';
import { LoanParams } from 'src/endpoints/loans';

const StyledH4 = styled.h4`
  margin: 16px 0 24px 0;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
`;

const StyledContainer = styled.div`
  padding: 0;
  background-color: white;

  > div {
    max-width: 800px;

    > strong {
      display: block;
      margin-bottom: 32px;
    }
    > strong:first-child {
      margin-top: 32px;
    }

    > button {
      margin-top: 32px;
    }
  }
`;
const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;

const AddNewLead = observer(({ phrases, loan }: any) => {
  const [clientData, setClientData] = useState<Partial<CreateClientParams>>({});
  const [loanData, setLoanData]: any = useState<Partial<CreateClientParams>>({});
  const [selectedTabIndex, setSelectedTabIndex]: any = useState(loan === 'mortgage' ? 1 : 0);
  const [agentId, setAgentId] = useState(null);
  const { clientsStore, productsStore, mortgagesStore, loansStore, agentsStore, authStore }: any = useStores();
  const { id } = useParams();

  const onSubmit = async (productId, e) => {
    if (productId === 2) {
      const data = { ...loanData };
      const mortgage: any = {};
      Object.entries(data).forEach((ele) => {
        mortgage[_.camelCase(ele[0])] = ele[1];
      });
      mortgage.rateId = 1;
      mortgage.currency = 'RSD';
      mortgage.productId = 2;
      const transform = { ...clientData, mortgage };
      authStore.userInfo.roles[0] === 'PARTNER_AGENT'
        ? await clientsStore.createWithMortgage(transform as CreateClientParams)
        : await clientsStore.createAsAdminMortgage(agentId, transform as CreateClientParams);
    }
    if (productId === 1) {
      const data = { ...loanData };
      const cashLoan: any = {};
      Object.entries(data).forEach((ele) => {
        cashLoan[_.camelCase(ele[0])] = ele[1];
      });
      cashLoan.rateId = 1;
      cashLoan.currency = 'EUR';
      cashLoan.productId = 1;
      cashLoan.clientId = 'aew';
      const transform = { ...clientData, cashLoan };
      authStore.userInfo.roles[0] === 'ROLE_AGENT'
        ? await clientsStore.createWithCashLoan(transform as CreateClientParams)
        : await clientsStore.createAsAdminCashLoan(agentId, transform as CreateClientParams);
    }
  };
  const onSubmitEdit = () => {
    const data = { ...loanData };
    const final: any = {};
    Object.entries(data).forEach((ele) => {
      final[_.camelCase(ele[0])] = ele[1];
    });
    final.id = id;
    loan === 'cash' && loansStore.edit(final as LoanParams);
    loan === 'mortgage' && mortgagesStore.edit(final as LoanParams);
  };

  const labelSearcher = (inputData) => {
    const find: any = Object.entries(phrases).find((ele) => ele[0] === inputData);
    if (!find) {
      return '';
    }
    return find[1];
  };

  useEffect(() => {
    productsStore.getAll();
    if (
      authStore.userInfo.roles[0] === 'ROLE_SUPER_ADMIN'
       || authStore.userInfo.roles[0] === 'ROLE_ACCOUNT_EXECUTIVE'
       || authStore.userInfo.roles[0] === 'ROLE_PARTNER'
    ) {
      agentsStore.getAll();
    }
    if (loan === 'cash' || loan === 'mortgage') {
      loansStore.getDataByClientId(id);
    }
  }, []);

  if (productsStore.isLoading || agentsStore.isLoading) {
    return (
      <StyledLoading>
        <Loading withOverlay={false} />
      </StyledLoading>
    );
  }
  const inputGenerator = (inputData: any) => {
    const camel = _.camelCase(inputData.label);
    if (inputData.fieldType === 'DATE') {
      return (
        <DatePicker
          dateFormat="m/d/Y"
          datePickerType="single"
          onChange={(value) => {
            const date = new Date(value);
            const format = date.toISOString().slice(0, -9);
            const format2 = format.split('-').reverse().join('-');
            const data = { ...loanData };
            data[inputData.label] = format2;
            setLoanData(data);
          }}
        >
          <DatePickerInput
            datePickerType="range"
            type="string"
            id="date-picker-default-2"
            placeholder="dd-mm-yyyy"
            labelText={`${labelSearcher(inputData.label)}*`}
          />
        </DatePicker>
      );
    }
    if (inputData.fieldType === 'ENUMERATION') {
      return (
        <Dropdown
          label={phrases.select}
          titleText={`${labelSearcher(inputData.label)}*`}
          items={inputData.enumerationLabels.map((label, index) => {
            const formLabel: any = Object.entries(phrases).find((ele) => ele[0] === label) || '';
            return {
              id: index,
              label: formLabel[1],
              value: label,
            };
          })}
          initialSelectedItem={loan ? labelSearcher(loansStore.dataById[camel]) : null}
          onChange={({ selectedItem: { value } }) => {
            const data = { ...loanData };
            data[inputData.label] = value;
            setLoanData(data);
          }}
        />
      );
    }
    if (inputData.fieldType === 'TEXT') {
      return (
        <TextInput
          required
          type="string"
          placeholder={`Enter ${labelSearcher(inputData.label)}`}
          labelText={`${labelSearcher(inputData.label)}*`}
          onBlur={({ target: { value } }) => {
            const data = { ...loanData };
            data[inputData.label] = value;
            setLoanData(data);
          }}
          defaultValue={loan ? loansStore.dataById[camel] : null}
        />
      );
    }
    if (inputData.fieldType === 'BOOL') {
      return (
        <Dropdown
          label="Select"
          titleText={`${labelSearcher(inputData.label)}*`}
          items={[
            { id: 'one', label: 'TRUE', name: 'TRUE' },
            { id: 'two', label: 'FALSE', name: 'FALSE' },
          ]}
          onChange={({ selectedItem: { label } }) => {
            const data = { ...loanData };
            data[inputData.label] = Boolean(label);
            setLoanData(data);
          }}
          selectedItem={loan ? loansStore.dataById[inputData.label] : null}
        />
      );
    }
    if (inputData.fieldType === 'INTEGER') {
      return (
        <NumberInput
          placeholder={phrases.enter_number}
          allowEmpty={false}
          iconDescription="number input"
          type="number"
          label={`${labelSearcher(inputData.label)}*`}
          onBlur={({ target: { value } }) => {
            const data = { ...loanData };
            data[inputData.label] = Number(value);
            setLoanData(data);
          }}
          defaultValue={loan ? loansStore.dataById[camel] : null}
        />
      );
    }
    return (
      <NumberInput
        placeholder={phrases.enter_number}
        allowEmpty={false}
        iconDescription="number input"
        type="number"
        label={
          inputData.label === 'credit_amount' && selectedTabIndex === 0
            ? `${labelSearcher(inputData.label)} RSD*`
            : inputData.label === 'credit_amount' && selectedTabIndex === 1
              ? `${labelSearcher(inputData.label)} EUR*`
              : `${labelSearcher(inputData.label)}*`
        }
        onBlur={({ target: { value } }) => {
          const data = { ...loanData };
          data[inputData.label] = Number(value);
          setLoanData(data);
        }}
        defaultValue={loan ? loansStore.dataById[camel] : null}
      />
    );
  };

  const tabListStylesCash = selectedTabIndex === 0 ? { backgroundColor: 'white' } : null;
  const tabListStylesMortgage = selectedTabIndex === 1 ? { backgroundColor: 'white' } : null;

  return (
    <Tabs
      defaultSelectedIndex={loan === 'mortgage' ? 1 : 0}
      onChange={(selectedItem) => setSelectedTabIndex(selectedItem.selectedIndex)}
    >
      <StyledH4>Lead</StyledH4>
      <TabList aria-label="tabList" contained style={{ marginTop: 0, paddingleft: 20 }}>
        <Tab style={tabListStylesCash}>{phrases.cash_loan}</Tab>
        <Tab style={tabListStylesMortgage}>{phrases.mortgage}</Tab>
      </TabList>
      <StyledContainer>
        <TabPanels>
          {productsStore.data.map((product) => (
            <TabPanel key={product.id}>
              <Form onSubmit={(e) => e.preventDefault()}>
                {!loan && (
                  <FormLabel
                    style={{
                      marginTop: '25px',
                      marginBottom: '25px',
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                  >
                    {phrases.user_information}
                  </FormLabel>
                )}
                {!loan && (
                  <Grid style={{ padding: 0 }}>
                    <Column
                      lg={8}
                      md={4}
                      sm={2}
                      style={{ marginRight: 0, marginBottom: 24, marginLeft: 0 }}
                    >
                      <TextInput
                        required
                        type="string"
                        labelText={`${phrases.name}*`}
                        placeholder={phrases.enter_name}
                        onBlur={({ target: { value } }) => setClientData({ ...clientData, firstName: value })}
                      />
                    </Column>
                    <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                      <TextInput
                        required
                        type="string"
                        labelText={`${phrases.surname}*`}
                        placeholder={phrases.enter_surname}
                        onBlur={({ target: { value } }) => setClientData({ ...clientData, surname: value })}
                      />
                    </Column>
                    <Column
                      lg={8}
                      md={4}
                      sm={2}
                      style={{ marginRight: 0, marginBottom: 24, marginLeft: 0 }}
                    >
                      <DatePicker
                        dateFormat="m/d/Y"
                        datePickerType="single"
                        onChange={(value) => {
                          const date = new Date(value);
                          const format = date.toISOString().slice(0, -14);
                          const format2 = format.split('-').reverse().join('-');
                          setClientData({ ...clientData, dateOfBirth: format2 });
                        }}
                      >
                        <DatePickerInput
                          required
                          datePickerType="range"
                          type="string"
                          id="date-picker-default-2"
                          placeholder="dd-mm-yyyy"
                          labelText={`${phrases.date_of_birth}*`}
                        />
                      </DatePicker>
                    </Column>
                    <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                      <TextInput
                        required
                        type="string"
                        labelText={`${phrases.email_address}*`}
                        placeholder={phrases.enter_email}
                        onBlur={({ target: { value } }) => setClientData({ ...clientData, email: value })}
                      />
                    </Column>
                    <Column
                      lg={8}
                      md={4}
                      sm={2}
                      style={{ marginRight: 0, marginBottom: 24, marginLeft: 0 }}
                    >
                      <TextInput
                        required
                        type="string"
                        labelText={`${phrases.phone_number}*`}
                        placeholder={phrases.enter_phone_number}
                        onBlur={({ target: { value } }) => setClientData({ ...clientData, phone: value })}
                      />
                    </Column>
                    {authStore.userInfo.roles[0] !== 'ROLE_AGENT' && !agentsStore.isLoading && (
                      <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                        <Dropdown
                          required
                          label={phrases.select}
                          titleText={`${phrases.select_an_agent}*`}
                          items={agentsStore.data.content.map((ele, index) => {
                            return {
                              id: index,
                              label: `${ele.firstName} ${ele.surname}`,
                              name: ele.id,
                            };
                          })}
                          onChange={({ selectedItem: { name } }) => setAgentId(name)}
                        />
                      </Column>
                    )}
                  </Grid>
                )}
                {Object.entries(product.sections).map((section: any) => (
                  <>
                    <FormLabel
                      style={{
                        marginTop: '25px',
                        marginBottom: '25px',
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                    >
                      {labelSearcher(section[0])}
                    </FormLabel>
                    <Grid style={{ padding: 0 }}>
                      {section[1].map((input) => (
                        <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                          <style>
                            {'div:nth-of-type(odd) { margin-left: 0;}'}
                          </style>
                          {inputGenerator(input)}
                        </Column>
                      ))}
                    </Grid>
                  </>
                ))}
                {loan !== 'cash' && loan !== 'mortgage' && (
                  <Button renderIcon={Add} onClick={(e) => onSubmit(product.id, e)} type="submit" style={{ marginTop: '16px' }}>
                    {phrases.add_a_client}
                  </Button>
                )}
                {(loan === 'cash' || loan === 'mortgage') && (
                  <Button renderIcon={Add} onClick={() => onSubmitEdit()} style={{ marginTop: '16px' }}>
                    {phrases.edit_a_loan}
                  </Button>
                )}
              </Form>
            </TabPanel>
          ))}
        </TabPanels>
      </StyledContainer>
    </Tabs>
  );
});

export default AddNewLead;
