import axiosInstance from '.';

export interface LoginParams {
  email: string;
  password: string;
}

export const login = async (params: LoginParams): Promise<any> => {
  return axiosInstance.post('/auth/login', params).then((res) => res.data);
};

export interface GetRefreshTokenParams {
  refreshToken: string;
}

export const getRefreshToken = async (params: GetRefreshTokenParams): Promise<any> => {
  return axiosInstance.post('/auth/refreshToken', params).then((res) => res.data);
};

export const getUserDetails = async (): Promise<any> => {
  return axiosInstance.get('/auth/getUserDetails').then((res) => res.data);
};

export const getAllPrivileges = async (): Promise<any> => {
  return axiosInstance.get('/auth/getAllPrivileges').then((res) => res.data);
};

export const forgotPassword = async (mail:string): Promise<any> => {
  return axiosInstance.post('/auth/forgotPassword', { mail });
};

export const passwordChange = async (newPassword:string, token: string): Promise<any> => {
  return axiosInstance.post(`/auth/passwordChangeVerification/${token}`, { newPassword });
};
