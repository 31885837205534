import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Modal, Dropdown, ComboBox } from '@carbon/react';
import { useStores } from 'src/stores';
import { PostLanguageParams } from 'src/endpoints/languages';

const StyledDropdown = styled.div`
  margin: 16px 0;
  height: 250px;
`;

const AddNewLanguageModal = observer(
  ({ isOpen, setIsOpen, phrases, cardList, setCardList }: any) => {
    const { languagesStore }: any = useStores();
    const [data, setData] = useState<Partial<PostLanguageParams>>({});

    const onSubmit = async () => {
      await languagesStore.postLanguage(data as PostLanguageParams);
      languagesStore.isLoading = false;
      setIsOpen(false);
    };
    const items = languagesStore.countryCodes.map((ele, index) => {
      return { id: index, label: ele.name, iso3: ele.iso3 };
    });

    return (
      <Modal
        open={isOpen}
        size="sm"
        modalHeading={phrases.add_new_language}
        primaryButtonText={phrases.add_a_language}
        secondaryButtonText={phrases.cancel}
        hasScrollingContent
        onRequestSubmit={onSubmit}
        onRequestClose={() => setIsOpen(false)}
      >
        <StyledDropdown>
          <ComboBox
            initialSelectedItem={items[0]}
            id="lang-selection"
            style={{ height: 600, 'z-index': 9999 }}
            label={phrases.select_language}
            titleText={phrases.language}
            items={items}
            onChange={(selectedItem) => {
              if (selectedItem.selectedItem) {
                cardList.push(selectedItem.Label);
                setData({ iso3: selectedItem.selectedItem.iso3, phrases: [] });
              }
              if (!Object.keys(selectedItem).length) {
                setData({ iso3: '', phrases: [] });
              }
            }}
          />
        </StyledDropdown>
      </Modal>
    );
  },
);

export default AddNewLanguageModal;
