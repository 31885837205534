import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/stores';
import usePagination from 'src/utils/pagination';
import FilterMenu from 'src/utils/FilterMenu';
import styled from 'styled-components';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableToolbarMenu,
  TableToolbarAction,
  Button,
  DataTableSkeleton,
} from '@carbon/react';
import { Add, Edit, TrashCan, DocumentExport } from '@carbon/react/icons';
import StatusChangeModal from 'src/utils/StatusChangeModal';
import CreateNewMeetingModal from './CreateNewMeetingModal';
import EditMeetingModal from './EditMeetingModal';
import DeleteMeetingModal from './DeleteMeetingModal';

const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;

const StatusIndicator = styled.div`
  width: max-content;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 24px;
  color: ${({ color }) => color};
  background-color: ${({ bg }) => bg};
`;

const MeetingRequests = observer(({ phrases }: any) => {
  const { meetingsStore, authStore }: any = useStores();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedMeetingParam, setSelectedMeetingParam] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [filterFrom, setFilterFrom]: any = useState();
  const [filterTo, setFilterTo]: any = useState();
  const [leadsValidity, setLeadsValidity]: any = useState();
  const [approvalStatus, setApprovalStatus]: any = useState();
  const [paymentStatus, setPaymentStatus]: any = useState();
  const [meetingStatus, setMeetingStatus]: any = useState();
  const [pageSize, setPageSize] = useState(10);
  const [isStatusChangeModalopen, setIsStatusChangeModalOpen] = useState(false);
  const [statusType, setStatusType] = useState({ type: '', id: '' });
  useEffect(() => {
    meetingsStore.getAll(0, 10);
  }, []);
  const formatDate = (date) => {
    if (!date) {
      return 'n/a';
    }
    const format = date.substring(0, 10).split('-').reverse().join('-');
    const time = date.substring(10, 16);
    const finalDate = `${format}${time}`;
    const event = new Date(finalDate);
    return event.toLocaleString('en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const headersData = [
    { key: 'id', header: 'Meeting ID' },
    { key: 'partnerCompanyName', header: phrases.partner },
    { key: 'clientName', header: phrases.client_name },
    { key: 'clientSurname', header: phrases.client_surname },
    { key: 'clientPhone', header: phrases.phone_number },
    { key: 'agentName', header: phrases.agent_name },
    { key: 'productId', header: phrases.product },
    { key: 'meetingOption1', header: phrases.proposed_meeting_option_1 },
    { key: 'meetingOption2', header: phrases.proposed_meeting_option_2 },
    { key: 'meetingOption3', header: phrases.proposed_meeting_option_3 },
    { key: 'meetingStatus', header: phrases.meeting_status },
  ];

  const formatName = (value) => {
    return value.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
  };

  const isAnyModalOpen = isCreateModalOpen || isDeleteModalOpen || isStatusChangeModalopen || isUpdateModalOpen;

  return (
    <div className="data-table">
      <CreateNewMeetingModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        phrases={phrases}
      />
      <DeleteMeetingModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        meetingId={selectedMeetingParam}
        phrases={phrases}
      />
      <EditMeetingModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        phrases={phrases}
        meetingData={selectedMeetingParam}
      />
      <StatusChangeModal
        isOpen={isStatusChangeModalopen}
        phrases={phrases}
        setIsOpen={setIsStatusChangeModalOpen}
        statusType={statusType}
        setStatusType={setStatusType}
      />
      {meetingsStore.isLoading && <DataTableSkeleton columnCount={8} rowCount={pageSize} />}
      {Object.keys(meetingsStore.data).length && (
        <DataTable headers={headersData} rows={meetingsStore.data.content} isSortable isSelectable>
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getBatchActionProps,
          }) => {
            const batchActionProps = getBatchActionProps();

            return (
              <TableContainer title={phrases.meeting_requests}>
                <TableToolbar {...getToolbarProps()}>
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      onChange={(v) => {
                        setTimeout(() => {
                          setFilter(v.target.value);
                        }, 800);
                      }}
                      onClear={() => setFilter('')}
                    />
                    <TableToolbarMenu tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0} renderIcon={DocumentExport}>
                      <TableToolbarAction onClick={() => alert('Alert 1')}>
                        Export excel file
                      </TableToolbarAction>
                      {/* <TableToolbarAction onClick={() => alert('Alert 2')}>
                        Action 2
                      </TableToolbarAction>
                      <TableToolbarAction onClick={() => alert('Alert 3')}>
                        Action 3
                      </TableToolbarAction> */}
                    </TableToolbarMenu>
                    <FilterMenu
                      setFilterFrom={setFilterFrom}
                      setFilterTo={setFilterTo}
                      comingFrom="meetings"
                      setApprovalStatus={setApprovalStatus}
                      setLeadsValidity={setLeadsValidity}
                      setPaymentStatus={setPaymentStatus}
                      setMeetingStatus={setMeetingStatus}
                    />
                    {authStore.userInfo.roles[0] !== 'ROLE_BRANCH' && (
                      <Button
                        tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                        renderIcon={Add}
                        onClick={() => setIsCreateModalOpen(true)}
                      >
                        {phrases.add_a_meeting}
                      </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>
                {Object.keys(meetingsStore.data).length && !meetingsStore.isLoading && (
                  <Table {...getTableProps()}>
                    <TableHead style={{ whiteSpace: 'nowrap' }}>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                        ))}
                        <TableHeader />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.cells.map((cell) => {
                            switch (cell.info.header) {
                              case 'meetingOption1':
                              case 'meetingOption2':
                              case 'meetingOption3':
                                return (
                                  <TableCell key={cell.id} style={{ whiteSpace: 'nowrap' }}>
                                    {formatDate(cell.value)}
                                  </TableCell>
                                );
                              case 'agentName':
                                return (
                                  <TableCell key={cell.id} style={{ whiteSpace: 'nowrap' }}>
                                    {formatName(cell.value)}
                                  </TableCell>
                                );
                              case 'productId':
                                return cell.value === 1 ? (
                                  <TableCell key={cell.id} style={{ whiteSpace: 'nowrap' }}>
                                    Cash loan
                                  </TableCell>
                                ) : (
                                  <TableCell key={cell.id} style={{ whiteSpace: 'nowrap' }}>
                                    Mortgage
                                  </TableCell>
                                );
                              case 'meetingStatus':
                                return (
                                  <TableCell
                                    key={cell.id}
                                    onClick={() => {
                                      setIsStatusChangeModalOpen(true);
                                      setStatusType({ type: 'meetingRequests', id: row.id });
                                    }}
                                    style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                                  >
                                    {(() => {
                                      switch (cell.value) {
                                        case 'scheduled':
                                          return (
                                            <StatusIndicator color="#121619" bg="#DDE1E6">
                                              Scheduled
                                            </StatusIndicator>
                                          );
                                        case 'not_attended':
                                          return (
                                            <StatusIndicator color="#DA1E28" bg="#FFF1F1">
                                              Not attended
                                            </StatusIndicator>
                                          );
                                        case 'na':
                                          return <StyledTableCell>n/a</StyledTableCell>;
                                        default:
                                          return (
                                            <StatusIndicator color="#0E6027" bg="#A7F0BA">
                                              Completed
                                            </StatusIndicator>
                                          );
                                      }
                                    })()}
                                  </TableCell>
                                );
                              default:
                                return (
                                  <TableCell key={cell.id} style={{ whiteSpace: 'nowrap' }}>
                                    {cell.value}
                                  </TableCell>
                                );
                            }
                          })}
                          <StyledTableCell>
                            <TrashCan
                              onClick={() => {
                                setSelectedMeetingParam(row);
                                setIsDeleteModalOpen(true);
                              }}
                            />
                            <Edit
                              onClick={() => {
                                const data = meetingsStore.data.content.find(({ id }) => row.id === id) || {};
                                setSelectedMeetingParam(data);
                                setIsUpdateModalOpen(true);
                              }}
                            />
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            );
          }}
        </DataTable>
      )}
      {usePagination(
        phrases,
        meetingsStore,
        filter,
        filterFrom,
        filterTo,
        leadsValidity,
        approvalStatus,
        paymentStatus,
        meetingStatus,
        isAnyModalOpen,
      )}
    </div>
  );
});

export default MeetingRequests;
