import { makeObservable, observable, action } from 'mobx';
import { getAllInternals,
  createInternal,
  updateInternal,
  deleteInternal,
  InternalAccountParams, getBranchAccounts } from 'src/endpoints/internals';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class InternalsStore {
  data = [];

  isLoading = false;

  branchAccounts = [];

  constructor() {
    makeObservable(this, {
      data: observable,
      branchAccounts: observable,
      isLoading: observable,
      getAll: action,
      create: action,
      delete: action,
    });
  }

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus) => {
    this.isLoading = true;

    const content = await getAllInternals(pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
    this.data = content;

    this.isLoading = false;
  };

  getBranches = async () => {
    this.isLoading = true;

    const content = await getBranchAccounts();
    this.branchAccounts = content;

    this.isLoading = false;
  };

  create = async (data: InternalAccountParams) => {
    try {
      await createInternal(data);
      this.getAll(0, 10, '', '', '', null, null, null, null);
      genericSuccessHandler('Account created successfully!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  update = async (id, data: InternalAccountParams) => {
    try {
      await updateInternal(id, data);
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  delete = async (id: number) => {
    try {
      await deleteInternal(id);
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}

export default new InternalsStore();
