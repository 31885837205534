import { paginationHelperMethod } from 'src/utils/paginationRoute';
import axiosInstance from '.';

export const getAllPartners = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'partner', filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
};

export const getPartner = async (id: number): Promise<any> => {
  return axiosInstance.get(`/partner/getById/${id}`).then((res) => res.data);
};

export interface PartnerAccountParams {
  id: number;
  accountExecutiveId: number;
  partnerType: string;
  companyName: string;
  companyAddress: string;
  companyNumber: string;
  taxNumber: string;
  firstName: string;
  surname: string;
  email: string;
  products: object[];
}
export interface AddProductParams {
  productId: number;
  commission: number;
}

export const createPartner = async (params: Partial<PartnerAccountParams>): Promise<any> => {
  return axiosInstance.post('/partner/savePartnerAdmin/', params).then((res) => res.data);
};

export const updatePartner = async (id: string, params: Partial<PartnerAccountParams>): Promise<any> => {
  return axiosInstance.put(`/partner/${id}`, params);
};

export const deletePartner = async (id: number): Promise<any> => {
  return axiosInstance.delete(`/partner/${id}`);
};
export const addProduct = async (partnerId: number, params: Partial<AddProductParams>): Promise<any> => {
  return axiosInstance.post(`/partner/addProducts/${partnerId}`, params);
};
