import { makeObservable, observable, action } from 'mobx';
import {
  getNotifications,
  updateNotifications,
  NotificationsParams,
} from 'src/endpoints/notifications';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class NotificationsStore {
  data = [];

  isLoading = false;

  constructor() {
    makeObservable(this, { data: observable, isLoading: observable, getAll: action, update: action });
  }

  getAll = async () => {
    this.isLoading = true;

    const content = await getNotifications();
    this.data = content;

    this.isLoading = false;
  };

  update = async (data: NotificationsParams) => {
    try {
      await updateNotifications(data);
      // this.getAll();
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}

export default new NotificationsStore();
