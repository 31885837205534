import React, { useEffect } from 'react';
import { useStores } from 'src/stores';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Loading,
} from '@carbon/react';
import { useParams } from 'react-router-dom';

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;
const StyledBoldCell = styled(TableCell)`
  font-weight: 900;
`;

const DeepDiveLoan = observer(({ phrases }: any) => {
  const { loansStore }: any = useStores();
  const { id }: any = useParams();

  const headersData = [
    { key: 'first', header: phrases.id },
    { key: 'second', header: loansStore.dataById.id },
  ];

  useEffect(() => {
    loansStore.getDataByClientId(id);
  }, []);
  const rowData = Object.entries(loansStore.dataById)
    .map((ele, index) => {
      loansStore.dataById.client && delete loansStore.dataById.client;
      loansStore.dataById.currency && delete loansStore.dataById.currency;
      loansStore.dataById.rate && delete loansStore.dataById.rate;
      loansStore.dataById.meeting && delete loansStore.dataById.meeting;
      return {
        id: index,
        first: ele[0],
        second: ele[1],
      };
    })
    .slice(1);

  const capitalizeWords = (string) => {
    if (typeof string === 'string') {
      string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
      string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
      string = string.replace(/_/g, ' ');
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  };

  if (loansStore.isLoading) {
    return (
      <StyledLoading>
        <Loading withOverlay={false} />
      </StyledLoading>
    );
  }
  return (
    <div className="data-table">
      {rowData && (
      <DataTable headers={headersData} rows={rowData} isSortable isSelectable>
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getBatchActionProps,
        }) => {
          const batchActionProps = getBatchActionProps();

          return (
            <TableContainer title={phrases.loan_info}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => {
                        return <StyledBoldCell key={cell.id}>{capitalizeWords(cell.value)}</StyledBoldCell>;
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      )}
    </div>
  );
});

export default DeepDiveLoan;
