import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import {
  Modal,
  Dropdown,
  DatePicker,
  DatePickerInput,
  DropdownSkeleton,
  ComboBox,
} from '@carbon/react';
import { useStores } from 'src/stores';

const StyledRow = styled(Row)`
  margin-bottom: 32px;
`;

const timeTable = [
  { id: 1, value: '08:00', hour: 8 },
  { id: 2, value: '08:30', hour: 8 },
  { id: 3, value: '09:00', hour: 9 },
  { id: 4, value: '09:30', hour: 9 },
  { id: 5, value: '10:00', hour: 10 },
  { id: 6, value: '10:30', hour: 10 },
  { id: 7, value: '11:00', hour: 11 },
  { id: 8, value: '11:30', hour: 11 },
  { id: 9, value: '12:00', hour: 12 },
  { id: 10, value: '12:30', hour: 12 },
  { id: 11, value: '13:00', hour: 13 },
  { id: 12, value: '13:30', hour: 13 },
  { id: 13, value: '14:00', hour: 14 },
  { id: 14, value: '14:30', hour: 14 },
  { id: 15, value: '15:00', hour: 15 },
  { id: 16, value: '15:30', hour: 15 },
  { id: 17, value: '16:00', hour: 16 },
  { id: 18, value: '16:30', hour: 16 },
  { id: 19, value: '17:00', hour: 17 },
];

const CreateNewMeetingModal = observer(({ isOpen, setIsOpen, phrases, meetingInfo }: any) => {
  const { meetingsStore, internalsStore, clientsStore, loansStore, mortgagesStore }: any = useStores();
  const [data, setData]: any = useState({});
  const [selectedClient, setSelectedClient]: any = useState(0);
  const [meetingTimes, setMeetingTimes]: any = useState({ time1: timeTable, time2: timeTable, time3: timeTable });

  const onSubmit = async () => {
    if (meetingInfo) {
      data.clientId = meetingInfo.client.id12;
      data.pipelineId = meetingInfo.id;
      await meetingsStore.create(data);
      meetingInfo.productId === 1 ? loansStore.getAll(0, 10, '') : mortgagesStore.getAll(0, 10, '');
    } else {
      await meetingsStore.create(data);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      internalsStore.getBranches();
      clientsStore.getAll();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedClient) {
      loansStore.getDataById(selectedClient);
    }
  }, [selectedClient]);

  const todaysDate = new Date();
  const day = todaysDate.getDate();
  const month = todaysDate.getMonth() + 1;
  const year = todaysDate.getFullYear();
  const timeHours = todaysDate.getHours();
  const timeMinutes = todaysDate.getMinutes();
  const currentTime = `${timeHours}:${timeMinutes}`;
  const minDate = `${day}/${month}/${year}`;

  const dateCompare = (date, index) => {
    const test = new Date();
    const adjustedDate = test.toLocaleDateString('en-GB');
    const filteredValues = meetingTimes[index].filter((time) => time.hour > timeHours);
    if (adjustedDate === date) {
      setMeetingTimes({ ...meetingTimes, [index]: filteredValues });
    }
    if (adjustedDate !== date) {
      setMeetingTimes({ ...meetingTimes, [index]: timeTable });
    }
  };

  return (
    isOpen && (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.add_a_new_meeting}
      primaryButtonText={phrases.add_a_meeting}
      primaryButtonDisabled={meetingsStore.isLoading}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => {
        setIsOpen(false);
        setData({});
      }}
    >
      <StyledRow>
        {Object.keys(clientsStore.data).length && !meetingInfo && (
          <Col>
            <ComboBox
              placeholder={phrases.select}
              titleText={phrases.client_name}
              items={clientsStore.data.content.map((ele) => {
                return { id: ele.id, label: `${ele.firstName} ${ele.surname}` };
              })}
              onChange={(selectedItem) => {
                if (selectedItem.selectedItem) {
                  setData({ ...data, clientId: selectedItem.selectedItem.id || null });
                  setSelectedClient(selectedItem.selectedItem.id);
                }
                if (!Object.keys(selectedItem).length) {
                  setData({ ...data, clientId: null });
                  setSelectedClient(null);
                }
              }}
            />
          </Col>
        )}
        {Object.keys(internalsStore.branchAccounts).length && (
          <Col>
            <Dropdown
              label={phrases.select}
              titleText={phrases.branch_advisor}
              items={internalsStore.branchAccounts.content
                .map((ele) => {
                  return { id: ele.id, label: `${ele.firstName} ${ele.surname}` };
                })}
              onChange={({ selectedItem: { id } }) => setData({ ...data, branchId: id })}
            />
          </Col>
        )}
      </StyledRow>
      <StyledRow>
        <Col>
          <DatePicker
            dateFormat="d/m/Y"
            datePickerType="single"
            minDate={minDate}
            onChange={(value) => {
              const date = new Date(value);
              const format = date.toLocaleDateString('en-GB');
              const format2 = format.split('/').join('-');
              setData({ ...data, meetingDate1: format2 });
              dateCompare(format, 'time1');
            }}
          >
            <DatePickerInput
              datePickerType="range"
              type="string"
              id="date-picker-default-2"
              placeholder="dd-mm-yyyy"
              labelText={phrases.proposed_meeting_option_1}
            />
          </DatePicker>
        </Col>
        <Col>
          <Dropdown
            label="hh:mm"
            titleText={phrases.meeting_option_1_time}
            placeholder="hh:mm"
            disabled={!data.meetingDate1}
            items={meetingTimes.time1.map((ele) => {
              return { id: ele.id, label: ele.value };
            })}
            onChange={({ selectedItem: { label } }) => {
              setData({ ...data, meetingTime1: label });
            }}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <DatePicker
            dateFormat="d/m/Y"
            datePickerType="single"
            minDate={minDate}
            onChange={(value) => {
              const date = new Date(value);
              const format = date.toLocaleDateString('en-GB');
              const format2 = format.split('/').join('-');
              setData({ ...data, meetingDate2: format2 });
              dateCompare(format, 'time2');
            }}
          >
            <DatePickerInput
              datePickerType="range"
              type="string"
              id="date-picker-default-2"
              placeholder="dd-mm-yyyy"
              labelText={phrases.proposed_meeting_option_2}
            />
          </DatePicker>
        </Col>
        <Col>
          <Dropdown
            label="hh:mm"
            titleText={phrases.meeting_option_2_time}
            placeholder="hh:mm"
            disabled={!data.meetingDate2}
            items={meetingTimes.time2.map((ele) => {
              return { id: ele.id, label: ele.value };
            })}
            onChange={({ selectedItem: { label } }) => {
              setData({ ...data, meetingTime2: label });
            }}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <DatePicker
            dateFormat="d/m/Y"
            datePickerType="single"
            minDate={minDate}
            onChange={(value) => {
              const date = new Date(value);
              const format = date.toLocaleDateString('en-GB');
              const format2 = format.split('/').join('-');
              setData({ ...data, meetingDate3: format2 });
              dateCompare(format, 'time3');
            }}
          >
            <DatePickerInput
              datePickerType="range"
              type="string"
              id="date-picker-default-2"
              placeholder="dd-mm-yyyy"
              labelText={phrases.meeting_option_3_time}
            />
          </DatePicker>
        </Col>
        <Col>
          <Dropdown
            label="hh:mm"
            titleText={phrases.meeting_option_3_time}
            placeholder="hh:mm"
            disabled={!data.meetingDate3}
            items={meetingTimes.time3.map((ele) => {
              return { id: ele.id, label: ele.value };
            })}
            onChange={({ selectedItem: { label } }) => {
              setData({ ...data, meetingTime3: label });
            }}
          />
        </Col>
      </StyledRow>
      {loansStore.isLoading && <DropdownSkeleton />}
      {!meetingInfo && (
        <StyledRow>
          <Col>
            {Object.keys(loansStore.loansByClientId).length && !loansStore.isLoading ? (
              <Dropdown
                label={phrases.select}
                titleText={phrases.loans_by_client}
                items={loansStore.loansByClientId.content.map((ele) => {
                  return {
                    id: ele.id,
                    label: `${ele.loanAmount}, ${
                      ele.productType === 'mortgage' ? 'Mortgage' : 'Cash loan'
                    }`,
                  };
                })}
                onChange={({ selectedItem: { id } }) => {
                  setData({ ...data, pipelineId: id });
                }}
              />
            ) : null}
          </Col>
        </StyledRow>
      )}
    </Modal>
    )
  );
});

export default CreateNewMeetingModal;
