import { makeObservable, observable, action } from 'mobx';
import {
  getAllAggregation,
  postCashOffer,
  CashOfferParams,
  postMortgageOffer,
  deleteCashOffer,
  getCashOfferById,
  editCashOffer,
} from 'src/endpoints/offers';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class CashOffers {
  data = [];

  isLoading = false;

  dataById = [];

  constructor() {
    makeObservable(this, {
      data: observable,
      dataById: observable,
      isLoading: observable,
      getAll: action,
    });
  }

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus) => {
    this.isLoading = true;

    const content = await getAllAggregation(pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
    this.data = content;

    this.isLoading = false;
  };

  create = async (data: CashOfferParams) => {
    try {
      await postCashOffer(data);
      await this.getAll(0, 10, '', '', '', null, null, null, null);
      genericSuccessHandler('Offer successfully created!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  createMortgageOffer = async (data: CashOfferParams) => {
    try {
      await postMortgageOffer(data);
      await this.getAll(0, 10, '', '', '', null, null, null, null);
      genericSuccessHandler('Offer successfully created!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  delete = async (id) => {
    try {
      await deleteCashOffer(id);
      await this.getAll(0, 10, '', '', '', null, null, null, null);
      genericSuccessHandler('Offer successfully deleted!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  update = async (id, data: CashOfferParams) => {
    try {
      await editCashOffer(id, data);
      genericSuccessHandler('Offer successfully updated!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  getById = async (id) => {
    this.isLoading = true;

    const content = await getCashOfferById(id);
    this.dataById = content;

    this.isLoading = false;
  };
}

export default new CashOffers();
