import React from 'react';
import styled from 'styled-components';
import { Add } from '@carbon/react/icons';
import { Dropdown, TextInput, Button, Grid, Column } from '@carbon/react';

const StyledH4 = styled.h4`
  margin: 16px 0 24px 0;
`;

const StyledContainer = styled.div`
  padding: 32px 16px;
  background-color: white;

  > div {
    max-width: 800px;

    > strong {
      display: block;
      margin-bottom: 32px;
    }

    > button {
      margin-top: 32px;
    }
  }
`;

const AddPartner = ({ phrases }) => {
  return (
    <>
      <StyledH4>{phrases.add_a_partner}</StyledH4>
      <StyledContainer>
        <div>
          <strong>{phrases.partner}</strong>
          <Grid style={{ padding: 0 }}>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <Dropdown
                label={phrases.select_type}
                titleText="Partner type"
                items={[
                  { id: 'one', label: 'one', name: 'one' },
                  { id: 'two', label: 'two', name: 'two' },
                ]}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput
                type="text"
                labelText={phrases.legal_name}
                placeholder={phrases.enter_name}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <TextInput
                type="text"
                labelText={phrases.legal_address}
                placeholder={phrases.address}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput
                type="text"
                labelText={phrases.other_addresses}
                placeholder={phrases.other}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <TextInput
                type="text"
                labelText={phrases.tax_number}
                placeholder={phrases.enter_number}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput
                type="text"
                labelText={phrases.company_number}
                placeholder={phrases.enter_number_company}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <TextInput
                type="text"
                labelText={phrases.foundation}
                placeholder={phrases.foundation}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput
                type="text"
                labelText={phrases.director}
                placeholder={phrases.director_name}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <TextInput
                type="text"
                labelText={phrases.number_of_employees}
                placeholder={phrases.enter_number_of_employees}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput
                type="text"
                labelText={phrases.placeholder}
                placeholder={phrases.placeholder}
              />
            </Column>
          </Grid>
          <strong>{phrases.mortgage}</strong>
          <Grid style={{ padding: 0 }}>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <Dropdown
                label={phrases.yes}
                titleText={phrases.applicability}
                items={[
                  { id: 'yes', label: 'yes', name: 'yes' },
                  { id: 'no', label: 'no', name: 'no' },
                ]}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText={phrases.commission} placeholder="XX%" />
            </Column>
          </Grid>
          <strong>{phrases.document_requirements}</strong>
          <Grid style={{ padding: 0 }}>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <Dropdown
                label={phrases.yes}
                titleText={phrases.applicability}
                items={[
                  { id: 'yes', label: 'yes', name: 'yes' },
                  { id: 'no', label: 'no', name: 'no' },
                ]}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText={phrases.commission} placeholder="XX%" />
            </Column>
          </Grid>
          <Button renderIcon={Add}>{phrases.add_a_partner}</Button>
        </div>
      </StyledContainer>
    </>
  );
};

export default AddPartner;
