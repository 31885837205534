import React from 'react';
import styled from 'styled-components';
import { Add } from '@carbon/react/icons';
import { Dropdown, TextInput, Button, Grid, Column } from '@carbon/react';

const StyledH4 = styled.h4`
  margin: 16px 0 24px 0;
`;

const StyledContainer = styled.div`
  padding: 32px 16px;
  background-color: white;

  > div {
    max-width: 800px;

    > strong {
      display: block;
      margin-bottom: 32px;
    }

    > button {
      margin-top: 32px;
    }
  }
`;

const AddProduct = ({ phrases }) => {
  return (
    <>
      <StyledH4>{phrases.add_a_product}</StyledH4>
      <StyledContainer>
        <div>
          <strong>{phrases.product}</strong>
          <Grid style={{ padding: 0 }}>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <Dropdown
                label={phrases.select}
                titleText={phrases.add_a_product}
                items={[
                  { id: 'one', label: 'one', name: 'one' },
                  { id: 'two', label: 'two', name: 'two' },
                ]}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <Dropdown
                label={phrases.select_rate}
                titleText={phrases.rate}
                items={[
                  { id: 'one', label: 'one', name: 'one' },
                  { id: 'two', label: 'two', name: 'two' },
                ]}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <Dropdown
                label={phrases.currency}
                titleText={phrases.select_currency}
                items={[
                  { id: 'one', label: 'one', name: 'one' },
                  { id: 'two', label: 'two', name: 'two' },
                ]}
              />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText="Placeholder" placeholder="placeholder" />
            </Column>
          </Grid>
          <strong>{phrases.data_requirements}</strong>
          <Grid style={{ padding: 0 }}>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText={phrases.add_a_product} placeholder="placeholder" />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText="Data field 2" placeholder="placeholder" />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText="Data field 3" placeholder="placeholder" />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText="Data field 4" placeholder="placeholder" />
            </Column>
          </Grid>
          <strong>{phrases.document_requirements}</strong>
          <Grid style={{ padding: 0 }}>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText="Document 1" placeholder="placeholder" />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText="Document 2" placeholder="placeholder" />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText="Document 3" placeholder="placeholder" />
            </Column>
            <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
              <TextInput type="text" labelText="Document 4" placeholder="placeholder" />
            </Column>
          </Grid>
          <Button renderIcon={Add}>{phrases.add_a_product}</Button>
        </div>
      </StyledContainer>
    </>
  );
};

export default AddProduct;
