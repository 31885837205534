import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from 'src/routes';
import { DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableToolbarMenu,
  TableToolbarAction,
  Pagination,
  Button } from '@carbon/react';
import { Add, Edit } from '@carbon/react/icons';

const rowsData = [
  { id: 1,
    partnerType: 'Real estate agent',
    legalName: 'Name',
    legalAddress: 'Address' },
  { id: 2,
    partnerType: 'Mortgage broker',
    legalName: 'Name',
    legalAddress: 'Address' },
  { id: 3,
    partnerType: 'Lead gen platform',
    legalName: 'Name',
    legalAddress: 'Address' },
];

const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;

const PartnersManagement = ({ phrases }) => {
  const navigate = useNavigate();

  const headersData = [
    { key: 'type', header: phrases.type },
    { key: 'legalName', header: phrases.legal_name },
    { key: 'legalAddress', header: phrases.legal_address },
    { key: '...', header: '...' },
  ];

  return (
    <div className="data-table">
      <DataTable headers={headersData} rows={rowsData} isSortable>
        {({ rows,
          headers,
          getHeaderProps,
          getTableProps,
          getToolbarProps,
          getBatchActionProps }) => {
          const batchActionProps = getBatchActionProps();

          return (
            <TableContainer title={phrases.partners_management}>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                  <TableToolbarSearch
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    onChange={(v) => console.log(v)}
                  />
                  <Button
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    renderIcon={Add}
                    onClick={() => navigate(RoutesEnum.PARTNERS_MANAGEMENT_ADD_PARTNER, { replace: true })}
                  >
                    {phrases.add_a_partner}
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                      <StyledTableCell>
                        <Edit onClick={() => console.log(row.id)} />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      <Pagination
        className="data-table__pagination"
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText={phrases.items_per_page}
        page={1}
        pageNumberText="Page Number"
        pageSize={10}
        pageSizes={[10, 20, 30, 40, 50]}
        totalItems={103}
      />
    </div>
  );
};

export default PartnersManagement;
