import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/stores';
import { useParams } from 'react-router-dom';
import {
  Dropdown,
  TextInput,
  Button,
  Grid,
  Column,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Loading,
  NumberInput,
  MultiSelect,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import { toJS } from 'mobx';
import { CashOfferParams } from 'src/endpoints/offers';

const StyledH4 = styled.h4`
  margin: 16px 0 24px 0;
`;

const StyledContainer = styled.div`
  padding: 32px 16px;
  background-color: white;

  > div {
    max-width: 800px;

    > strong {
      display: block;
      margin-bottom: 32px;
    }
    > strong:first-child {
      margin-top: 32px;
    }

    > button {
      margin-top: 32px;
    }
  }
`;
const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;

const CreateNewOffer = observer(({ phrases, offerType }: any) => {
  const [offerData, setOfferData] = useState({});
  const [mortgageOfferData, setMortgageOfferData] = useState({});
  const { productsStore, cashOffersStore, mortgageOffersStore }: any = useStores();
  const { id } = useParams();

  useEffect(() => {
    productsStore.getAll();
    if (offerType === 'cash') {
      cashOffersStore.getById(id);
    }
    if (offerType === 'mortgage') {
      mortgageOffersStore.getById(id);
    }
    // offerType === 'cash' ? cashOffersStore.getById(id) : mortgageOffersStore.getById(id);
  }, []);

  const submitCashOffer = async () => {
    await cashOffersStore.create(offerData as CashOfferParams);
  };
  const submitMortgageOffer = async () => {
    await mortgageOffersStore.createMortgageOffer(mortgageOfferData as CashOfferParams);
  };
  const editMortgageOffer = async () => {
    await mortgageOffersStore.update(id, mortgageOfferData as CashOfferParams);
  };
  const editCashOffer = async () => {
    await cashOffersStore.update(id, mortgageOfferData as CashOfferParams);
  };

  const labelSearcher = (inputData) => {
    const find: any = Object.entries(phrases).find((ele) => ele[0] === inputData);
    if (!find) {
      return '';
    }
    return find[1];
  };
  if (productsStore.isLoading || cashOffersStore.isLoading || mortgageOffersStore.isLoading) {
    return (
      <StyledLoading>
        <Loading withOverlay={false} />
      </StyledLoading>
    );
  }

  return (
    <div className="data-table">
      <Tabs defaultSelectedIndex={offerType === 'mortgage' ? 1 : 0}>
        <StyledH4>{phrases.lead}</StyledH4>
        <TabList aria-label="tabList" contained style={{ marginTop: 20, paddingleft: 20 }}>
          <Tab>{phrases.cash_offers}</Tab>
          <Tab>{phrases.mortgage_offers}</Tab>
        </TabList>
        <StyledContainer>
          <TabPanels>
            <TabPanel>
              <strong>{phrases.offer_information}</strong>
              <Grid style={{ padding: 0 }}>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24, marginLeft: 0 }}>
                  <TextInput
                    type="string"
                    labelText={phrases.offer_name}
                    placeholder={phrases.enter_name}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, offerName: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.offerName : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.interest_rate}
                    placeholder={phrases.enter_interest}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, interestRate: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.interestRate : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  {(offerType === 'cash' ? Object.keys(cashOffersStore.dataById).length : true)
                   && (
                   <Dropdown
                     label={phrases.select}
                     titleText="3M Belibor"
                     items={[
                       { id: 'one', label: 'TRUE', value: 'TRUE' },
                       { id: 'two', label: 'FALSE', value: 'FALSE' },
                     ]}
                     onChange={({ selectedItem: { label } }) => {
                       setOfferData({ ...offerData, with3MBelibor: label });
                     }}
                     initialSelectedItem={offerType === 'cash' ? cashOffersStore.dataById.with3MBelibor.toString().toUpperCase() : null}
                   />
                   )}
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  {(offerType === 'cash' ? Object.keys(cashOffersStore.dataById).length : true) && (
                  <Dropdown
                    label={phrases.select}
                    titleText="6M Belibor"
                    items={[
                      { id: 'one', label: 'TRUE', name: 'TRUE' },
                      { id: 'two', label: 'FALSE', name: 'FALSE' },
                    ]}
                    onChange={({ selectedItem: { label } }) => {
                      setOfferData({ ...offerData, with6MBelibor: label });
                    }}
                    selectedItem={offerType === 'cash' ? cashOffersStore.dataById.with6MBelibor.toString().toUpperCase() : null}
                  />
                  )}
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    placeholder={phrases.enter_value}
                    label={phrases.additional_costs}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, additionalCosts: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.additionalCosts : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  {(offerType === 'cash' ? Object.keys(cashOffersStore.dataById).length : true) && (
                  <Dropdown
                    label="Select"
                    titleText={phrases.loan_processing_costs_included}
                    items={[
                      { id: 'one', label: 'TRUE', name: 'TRUE' },
                      { id: 'two', label: 'FALSE', name: 'FALSE' },
                    ]}
                    onChange={({ selectedItem: { label } }) => {
                      setOfferData({ ...offerData, includesLoanProcessingCosts: label });
                    }}
                    initialSelectedItem={offerType === 'cash' ? cashOffersStore.dataById.includesLoanProcessingCosts.toString().toUpperCase() : null}
                  />
                  )}
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.costs}
                    placeholder={phrases.costs}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, costs: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.costs : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <TextInput
                    type="string"
                    labelText={phrases.life_insurance_information}
                    placeholder={phrases.enter_information}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, lifeInsuranceInformation: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.lifeInsuranceInformation : null}
                  />
                </Column>
              </Grid>
              <strong>{phrases.offer_criteria}</strong>
              <Grid>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  {(offerType === 'cash' ? Object.keys(cashOffersStore.dataById).length : true) && (
                  <Dropdown
                    label={phrases.select}
                    titleText={phrases.is_acitve}
                    items={[
                      { id: 'one', label: 'TRUE', name: 'TRUE' },
                      { id: 'two', label: 'FALSE', name: 'FALSE' },
                    ]}
                    onChange={({ selectedItem: { label } }) => {
                      setOfferData({ ...offerData, isActive: label });
                    }}
                    initialSelectedItem={offerType === 'cash' ? cashOffersStore.dataById.isActive.toString().toUpperCase() : null}
                  />
                  )}
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.min_credit_amount}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, minCreditAmount: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.minCreditAmount : null}
                  />
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  <NumberInput
                    type="number"
                    label={phrases.max_credit_amount}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, maxCreditAmount: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.maxCreditAmount : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.min_credit_period}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, minCreditPeriod: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.minCreditPeriod : null}
                  />
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  <NumberInput
                    type="number"
                    label={phrases.max_credit_period}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, maxCreditPeriod: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.maxCreditPeriod : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.min_credit_period_window}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, minCreditPeriodWindow: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.minCreditPeriodWindow : null}
                  />
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  <NumberInput
                    type="number"
                    label={phrases.max_credit_period}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, maxCreditPeriod: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.maxCreditPeriod : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.min_age}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, minAge: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.minAge : null}
                  />
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  <NumberInput
                    type="number"
                    label={phrases.max_age}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setOfferData({ ...offerData, maxAge: value })}
                    defaultValue={offerType === 'cash' ? cashOffersStore.dataById.maxAge : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  {Object.keys(productsStore.data).length && (
                    <MultiSelect
                      label={phrases.select}
                      placeholder={phrases.enter_value}
                      titleText={phrases.allowed_employment_durations}
                      items={productsStore.data[0].sections.employment_information[2].enumerationLabels.map(
                        (ele, index) => {
                          return { id: index, label: labelSearcher(ele), value: ele };
                        },
                      )}
                      onChange={(value) => { const values = value.selectedItems.map((ele) => { return ele.value; }); setOfferData({ ...offerData, allowedEmploymentDurations: values }); }}
                      initialSelectedItem={offerType === 'cash' ? cashOffersStore.dataById.allowedEmploymentDurations : null}
                    />
                  )}
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  {Object.keys(productsStore.data).length && (
                    <MultiSelect
                      label={phrases.select}
                      placeholder={phrases.enter_value}
                      titleText={phrases.allowed_company_existence}
                      items={productsStore.data[0].sections.employment_information[3].enumerationLabels.map(
                        (ele, index) => {
                          return { id: index, label: labelSearcher(ele), value: ele };
                        },
                      )}
                      onChange={(value) => { const values = value.selectedItems.map((ele) => { return ele.value; }); setOfferData({ ...offerData, allowedCompanyExistence: values }); }}
                    />
                  )}
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  {Object.keys(productsStore.data).length && (
                    <MultiSelect
                      label={phrases.select}
                      placeholder={phrases.enter_value}
                      titleText={phrases.not_supported_industries}
                      items={productsStore.data[0].sections.employment_information[3].enumerationLabels.map(
                        (ele, index) => {
                          return { id: index, label: labelSearcher(ele), value: ele };
                        },
                      )}
                      onChange={(value) => { const values = value.selectedItems.map((ele) => { return ele.value; }); setOfferData({ ...offerData, notSupportedIndustries: values }); }}
                    />
                  )}
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  {Object.keys(productsStore.data).length && (
                    <MultiSelect
                      label={phrases.select}
                      placeholder={phrases.enter_value}
                      titleText={phrases.not_supported_cities}
                      items={productsStore.data[1].sections.property_information[2].enumerationLabels.map(
                        (ele, index) => {
                          return { id: index, label: labelSearcher(ele), value: ele };
                        },
                      )}
                      onChange={(value) => { const values = value.selectedItems.map((ele) => { return ele.value; }); setOfferData({ ...offerData, notSupportedCities: values }); }}
                    />
                  )}
                </Column>
              </Grid>
              {!offerType
              && (
              <Button renderIcon={Add} onClick={() => submitCashOffer()}>
                {phrases.create_offer}
              </Button>
              )}
              {offerType === 'cash'
              && (
              <Button renderIcon={Add} onClick={() => editCashOffer()}>
                {phrases.create_offer}
              </Button>
              )}
            </TabPanel>
            <TabPanel>
              <strong>{phrases.offer_information}</strong>
              <Grid style={{ padding: 0 }}>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <TextInput
                    type="string"
                    labelText={phrases.offer_name}
                    placeholder={phrases.enter_name}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, offerName: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.offerName : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.interest_rate}
                    placeholder={phrases.enter_interest}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, interestRate: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.interestRate : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  {(offerType === 'mortgage' ? Object.keys(mortgageOffersStore.dataById).length : true)
                  && (
                  <Dropdown
                    label={phrases.select}
                    titleText="3M Belibor"
                    items={[
                      { id: 'one', label: 'TRUE', name: 'TRUE' },
                      { id: 'two', label: 'FALSE', name: 'FALSE' },
                    ]}
                    onChange={({ selectedItem: { label } }) => {
                      setMortgageOfferData({ ...mortgageOfferData, with3MBelibor: label });
                    }}
                    initialSelectedItem={offerType === 'mortgage' ? mortgageOffersStore.dataById.with3MBelibor.toString().toUpperCase() : null}
                  />
                  )}
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  {(offerType === 'mortgage' ? Object.keys(mortgageOffersStore.dataById).length : true)
                   && (
                   <Dropdown
                     label={phrases.select}
                     titleText="6M Belibor"
                     items={[
                       { id: 'one', label: 'TRUE', name: 'TRUE' },
                       { id: 'two', label: 'FALSE', name: 'FALSE' },
                     ]}
                     onChange={({ selectedItem: { label } }) => {
                       setMortgageOfferData({ ...mortgageOfferData, with6MBelibor: label });
                     }}
                     initialSelectedItem={offerType === 'mortgage' ? mortgageOffersStore.dataById.with6MBelibor.toString().toUpperCase() : null}
                   />
                   )}
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.additional_costs}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, additionalCosts: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.additionalCosts : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  {(offerType === 'mortgage' ? Object.keys(mortgageOffersStore.dataById).length : true)
                   && (
                   <Dropdown
                     label={phrases.select}
                     titleText={phrases.loan_processing_costs_included}
                     items={[
                       { id: 'one', label: 'TRUE', name: 'TRUE' },
                       { id: 'two', label: 'FALSE', name: 'FALSE' },
                     ]}
                     onChange={({ selectedItem: { label } }) => {
                       setMortgageOfferData({ ...mortgageOfferData, includesLoanProcessingCosts: label });
                     }}
                     initialSelectedItem={offerType === 'mortgage' ? mortgageOffersStore.dataById.includesLoanProcessingCosts.toString().toUpperCase() : null}
                   />
                   )}
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.costs}
                    placeholder={phrases.costs}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, costs: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.costs : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <TextInput
                    type="string"
                    labelText={phrases.life_insurance_information}
                    placeholder={phrases.enter_information}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, lifeInsuranceInformation: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.lifeInsuranceInformation : null}
                  />
                </Column>
              </Grid>
              <strong>{phrases.offer_criteria}</strong>
              <Grid>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  {(offerType === 'mortgage' ? Object.keys(mortgageOffersStore.dataById).length : true)
                   && (
                   <Dropdown
                     label={phrases.select}
                     titleText={phrases.is_acitve}
                     items={[
                       { id: 'one', label: 'TRUE', name: 'TRUE' },
                       { id: 'two', label: 'FALSE', name: 'FALSE' },
                     ]}
                     onChange={({ selectedItem: { label } }) => {
                       setMortgageOfferData({ ...mortgageOfferData, isActive: label });
                     }}
                     initialSelectedItem={offerType === 'mortgage' ? mortgageOffersStore.dataById.isActive.toString().toUpperCase() : null}
                   />
                   )}
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.min_credit_amount}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, minCreditAmount: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.minCreditAmount : null}
                  />
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  <NumberInput
                    type="number"
                    label={phrases.max_credit_amount}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, maxCreditAmount: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.maxCreditAmount : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.min_credit_period}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, minCreditPeriod: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.minCreditPeriod : null}
                  />
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  <NumberInput
                    type="number"
                    label={phrases.max_credit_period}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, maxCreditPeriod: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.maxCreditPeriod : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.min_credit_period_window}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, minCreditPeriodWindow: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.minCreditPeriodWindow : null}
                  />
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  <NumberInput
                    type="number"
                    label={phrases.max_credit_period}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, maxCreditPeriod: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.maxCreditPeriod : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  <NumberInput
                    type="number"
                    label={phrases.min_age}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, minAge: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.minAge : null}
                  />
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  <NumberInput
                    type="number"
                    label={phrases.max_age}
                    placeholder={phrases.enter_value}
                    onChange={({ target: { value } }) => setMortgageOfferData({ ...mortgageOfferData, maxAge: value })}
                    defaultValue={offerType === 'mortgage' ? mortgageOffersStore.dataById.maxAge : null}
                  />
                </Column>
                <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
                  {Object.keys(productsStore.data).length && (
                    <MultiSelect
                      label={phrases.select}
                      placeholder={phrases.enter_value}
                      titleText={phrases.not_supported_industries}
                      items={productsStore.data[0].sections.employment_information[6].enumerationLabels.map(
                        (ele, index) => {
                          return { id: index, label: labelSearcher(ele), value: ele };
                        },
                      )}
                      onChange={(value) => { const values = value.selectedItems.map((ele) => { return ele.value; }); setMortgageOfferData({ ...mortgageOfferData, notSupportedIndustries: values }); }}
                    />
                  )}
                </Column>
                <Column
                  lg={8}
                  md={4}
                  sm={2}
                  style={{ marginLeft: 0, marginBottom: 24, marginRight: 0 }}
                >
                  {Object.keys(productsStore.data).length && (
                    <MultiSelect
                      label={phrases.select}
                      placeholder={phrases.enter_value}
                      titleText={phrases.not_supported_cities}
                      items={productsStore.data[1].sections.property_information[2].enumerationLabels.map(
                        (ele, index) => {
                          return { id: index, label: labelSearcher(ele), value: ele };
                        },
                      )}
                      onChange={(value) => { const values = value.selectedItems.map((ele) => { return ele.value; }); setMortgageOfferData({ ...mortgageOfferData, notSupportedCities: values }); }}
                    />
                  )}
                </Column>
              </Grid>
              {!offerType
              && (
              <Button renderIcon={Add} onClick={() => submitMortgageOffer()}>
                {phrases.create_offer}
              </Button>
              )}
              {offerType === 'mortgage'
              && (
              <Button renderIcon={Add} onClick={() => editMortgageOffer()}>
                {phrases.create_offer}
              </Button>
              )}
            </TabPanel>
          </TabPanels>
        </StyledContainer>
      </Tabs>
    </div>
  );
});

export default CreateNewOffer;
