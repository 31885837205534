import axiosInstance from '.';

export interface PatchPhrasesParams {
  id: number;
  phrases: [{ label: string; translation: string }];
}
export interface PostLanguageParams {
  iso3: string;
  phrases: [];
}

export const getAllTranslations = async (): Promise<any> => {
  return axiosInstance.get('/language/withPhrases').then((res) => res.data);
};
export const getDefaultLanguage = async (): Promise<any> => {
  return axiosInstance.get('/language/default').then((res) => res.data);
};
export const getISO = async (): Promise<any> => {
  return axiosInstance.get('/language').then((res) => res.data);
};
export const getAllCountries = async (): Promise<any> => {
  return axiosInstance.get('/language/countries').then((res) => res.data);
};
export const getLanguage = async (id: string): Promise<any> => {
  return axiosInstance.get(`/language/iso/${id}`).then((res) => res.data);
};

export const patchPhrases = async (params: Partial<PatchPhrasesParams>): Promise<any> => {
  return axiosInstance.patch('/language/', params);
};
export const postLanguage = async (params: PostLanguageParams): Promise<any> => {
  return axiosInstance.post('/language', params);
};
export const deleteLanguage = async (id): Promise<any> => {
  return axiosInstance.delete(`/language/${id}`);
};
export const exportLanguage = async (iso): Promise<any> => {
  return axiosInstance
    .get(`/language/export/${iso}.csv`, {
      headers: {
        accept: 'text/csv',
      },
      responseType: 'blob',
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${iso}.csv`);
      document.body.appendChild(link);
      link.click();
    });
};
export const importLanguage = async (iso, file): Promise<any> => {
  const reader = new FormData();
  reader.append('file', file);

  return axiosInstance.post(`/language/import/${iso}`, reader, {
    headers: {
      accept: '*',
      'Content-Type': 'multipart/form-data',
    },
  });
};
