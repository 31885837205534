import React from 'react';
import { Modal } from '@carbon/react';

const DeleteModal = ({ isOpen, setIsOpen, phrases }) => (
  <Modal
    open={isOpen}
    size="sm"
    danger
    modalHeading={phrases.are_you_sure}
    primaryButtonText={phrases.delete}
    secondaryButtonText={phrases.cancel}
    hasScrollingContent
    onRequestClose={() => setIsOpen(false)}
  >
    <p>{phrases.check_if_you_want_to_continue}</p>
  </Modal>
);

export default DeleteModal;
