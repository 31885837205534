import React from 'react';
import styled from 'styled-components';
import { Modal,
  Dropdown,
  DatePicker,
  DatePickerInput,
  FileUploaderDropContainer,
  Grid,
  Column } from '@carbon/react';

const StyledText = styled.div`
  margin: 16px 0;

  > span {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
  }
`;

const CreateNewOfferModal = ({ isOpen, setIsOpen, phrases }) => (
  <Modal
    open={isOpen}
    size="sm"
    modalHeading={phrases.create_new_special_offer}
    primaryButtonText={phrases.add_product}
    secondaryButtonText={phrases.cancel}
    hasScrollingContent
    onRequestClose={() => setIsOpen(false)}
  >
    <Grid style={{ padding: 0 }}>
      <Column lg={16} md={8} sm={4} style={{ margin: '0 0 24px 0' }}>
        <Dropdown
          label="Select"
          titleText="Partner"
          items={[
            { id: 'one', label: 'one', name: 'one' },
            { id: 'two', label: 'two', name: 'two' },
          ]}
        />
      </Column>
      <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
        <DatePicker dateFormat="m/d/Y" datePickerType="single">
          <DatePickerInput
            id="date-picker-default-2"
            placeholder="mm/dd/yyyy"
            labelText="Date"
          />
        </DatePicker>
      </Column>
      <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
        <DatePicker dateFormat="m/d/Y" datePickerType="single">
          <DatePickerInput
            id="date-picker-default-2"
            placeholder="mm/dd/yyyy"
            labelText="Date"
          />
        </DatePicker>
      </Column>
    </Grid>
    <StyledText>
      <span>{phrases.upload_document}</span>
      <small>{phrases.pdf_files}</small>
    </StyledText>
    <FileUploaderDropContainer labelText={phrases.drag_and_drop_files} />
  </Modal>
);

export default CreateNewOfferModal;
