import { makeObservable, observable, action } from 'mobx';
import {
  getAllInvoices,
  createInvoice,
  deleteInvoice,
  sendForPayment,
  createInvoiceAdmin,
} from 'src/endpoints/invoices';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class InvoicesStore {
  data = [];

  isLoading = false;

  constructor() {
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      getAll: action,
      create: action,
      delete: action,
    });
  }

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus) => {
    this.isLoading = true;

    const content = await getAllInvoices(pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
    this.data = content;

    this.isLoading = false;
  };

  create = async (file, date: string, fileName) => {
    try {
      this.isLoading = true;
      await createInvoice(file, date, fileName);
      genericSuccessHandler('Invoice created successfully!');
      await this.getAll(0, 10, '', '', '', null, null, null, null);
      this.isLoading = false;
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  createAsAdmin = async (partnerId, file, date: string, fileName) => {
    try {
      await createInvoiceAdmin(partnerId, file, date, fileName);
      genericSuccessHandler('Invoice created successfully!');
      await this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  delete = async (id: number) => {
    try {
      await deleteInvoice(id);
      genericSuccessHandler('Invoice deleted successfully!');
      await this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  send = async (ids: Array<number>) => {
    try {
      await sendForPayment(ids);
      await this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}

export default new InvoicesStore();
