import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'react-grid-system';
import { TextInput, Button } from '@carbon/react';
import { ArrowRight } from '@carbon/react/icons';
import { useStores } from 'src/stores';
import Header from 'src/components/layout/Header';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;

  > div {
    width: 530px;
    margin: auto;
    background-color: white;

    @media screen and (max-width: 672px) {
      width: 100%;
    }
  }
`;

const StyledBody = styled.div`
  padding: 16px;
  h3 {
    margin-bottom: 48px;
  }
  button {
    margin: 52px 0 24px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 24px;
`;

const PasswordChange = ({ phrases }) => {
  const { authStore } = useStores();
  const [newPassword, setNewPassword] = useState('');
  const { token } = useParams();

  const onSubmit = (event) => {
    event.preventDefault();
    authStore.passwordChangeVerification(newPassword, token);
  };

  return (
    <>
      <Header />
      <StyledContainer>
        <div>
          <StyledBody>
            <h3>Reset your password</h3>
            <form onSubmit={onSubmit}>
              <Container>
                <StyledRow>
                  <TextInput
                    helperText="Minimum 6 characters."
                    id="Password input"
                    type="password"
                    labelText="New Password"
                    placeholder="Password"
                    onChange={({ target }) => setNewPassword(target.value)}
                  />
                </StyledRow>
                <Row>
                  <Button type="submit" renderIcon={ArrowRight} onClick={onSubmit}>
                    Reset Password
                  </Button>
                </Row>
              </Container>
            </form>
          </StyledBody>
        </div>
      </StyledContainer>
    </>
  );
};

export default PasswordChange;
