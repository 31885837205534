import { makeObservable, observable, action } from 'mobx';
import { RoutesEnum } from 'src/routes';
import {
  getAllCashLoans,
  LoanParams,
  createCashLoanPipeline,
  getLoanById,
  deleteLoan,
  editCashLoan,
  getLoansByClient,
  StatusParams,
  updateStatuses,
  getAllMortgages,
} from 'src/endpoints/loans';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class LoansStore {
  data = [];

  dataById = [];

  isLoading = false;

  loansByClientId = [];

  constructor() {
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      getAll: action,
      delete: action,
      loansByClientId: observable,
      getDataByClientId: action,
      getDataById: action,
    });
  }

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus) => {
    this.isLoading = true;

    const content = await getAllCashLoans(pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus);
    this.data = content;

    this.isLoading = false;
  };

  getDataById = async (clientId) => {
    this.isLoading = true;

    const content = await getLoansByClient(clientId);
    this.loansByClientId = content;

    this.isLoading = false;
  };

  getDataByClientId = async (id:string) => {
    this.isLoading = true;

    const content = await getLoanById(id);
    this.dataById = content;

    this.isLoading = false;
  };

  create = async (data: LoanParams) => {
    try {
      await createCashLoanPipeline(data);
      genericSuccessHandler('Successfully created a loan!');
    } catch (error:any) {
      if (error.request.status === 400) {
        return;
      }
      genericErrorHandler(error);
    }
  };

  delete = async (id) => {
    try {
      await deleteLoan(id);
      this.getAll(0, 10, '', '', '', null, null, null, null);
      genericSuccessHandler('Successfully deleted!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  edit = async (data: LoanParams) => {
    try {
      await editCashLoan(data);
      genericSuccessHandler('Successfully edited!');
      window.location.replace(RoutesEnum.CASH_AND_LOAN_APPLICATION);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  editStatus = async (data: StatusParams) => {
    try {
      await updateStatuses(data);
      this.getAll(0, 10, '', '', '', null, null, null, null);
      genericSuccessHandler('Successfully updated!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}
export default new LoansStore();
