import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useStores } from 'src/stores';
import { Row, Col } from 'react-grid-system';
import {
  Modal,
  DatePicker,
  DatePickerInput,
  FileUploaderDropContainer,
  Dropdown,
  FileUploaderItem,
} from '@carbon/react';

const StyledText = styled.div`
  margin: 16px 0;

  > span {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
  }
`;

const CreateNewInvoice = observer(({ isOpen, setIsOpen, phrases }:any) => {
  const [file, setFile]: any = useState();
  const [fileName, setFileName] = useState('');
  const [date, setDate]: any = useState('');
  const [partnerId, setPartnerId] = useState();
  const { invoicesStore, authStore, partnersStore }: any = useStores();

  const onSubmit = async () => {
    authStore.userInfo.roles[0] === 'ROLE_SUPER_ADMIN'
    || authStore.userInfo.roles[0] === 'ROLE_ACCOUNT_EXECUTIVE'
      ? await invoicesStore.createAsAdmin(partnerId, file, date, fileName)
      : await invoicesStore.create(file, date, fileName);
    setIsOpen(false);
  };

  useEffect(() => {
    if (
      isOpen
      && (authStore.userInfo.roles[0] === 'ROLE_SUPER_ADMIN'
       || authStore.userInfo.roles[0] === 'ROLE_ACCOUNT_EXECUTIVE')
    ) {
      partnersStore.getAll();
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.create_new_invoice}
      primaryButtonText={phrases.create_invoice}
      secondaryButtonText={phrases.cancel}
      primaryButtonDisabled={invoicesStore.isLoading}
      hasScrollingContent
      onRequestSubmit={onSubmit}
      onRequestClose={() => { setIsOpen(false); setFile(); setFileName(''); setDate(); }}
    >
      <Row>
        <Col>
          <DatePicker
            dateFormat="m/Y"
            datePickerType="single"
            onChange={(value) => {
              const data = new Date(value);
              const format = data.toISOString().slice(0, -14);
              const format2 = format.split('-').reverse().join('-');
              setDate(format2);
            }}
          >
            <DatePickerInput
              datePickerType="range"
              type="string"
              id="date-picker-default-2"
              placeholder="mm-yyyy"
              labelText={phrases.set_invoice_date}
            />
          </DatePicker>
        </Col>
        {Object.keys(partnersStore.data).length
          && (authStore.userInfo.roles[0] === 'ROLE_SUPER_ADMIN'
            || authStore.userInfo.roles[0] === 'ROLE_ACCOUNT_EXECUTIVE') && (
            <Col>
              <Dropdown
                label={phrases.select}
                titleText={phrases.select_a_partner}
                items={partnersStore.data.content.map((ele, index) => {
                  return { id: ele.id, label: `${ele.firstName} ${ele.surname}`, name: ele.id };
                })}
                onChange={({ selectedItem: { id } }) => setPartnerId(id)}
              />
            </Col>
        )}
      </Row>

      <StyledText>
        <span>{phrases.upload_document}</span>
        <small>{phrases.pdf_files}</small>
      </StyledText>
      <FileUploaderDropContainer
        buttonKind="primary"
        accept={['.pdf']}
        labelText={phrases.drag_and_drop_files}
        // filenameStatus="uploading"
        onAddFiles={(value, { addedFiles }) => {
          const contentType = 'pdf';
          const csvFile = new Blob([addedFiles[0]], { type: contentType });
          setFile(csvFile);
          setFileName(addedFiles[0].name);
        }}
        innerRef={{
          current: '[Circular]',
        }}
      />
      {file ? <FileUploaderItem status="edit" name={fileName} errorBody="Invalid file size" onDelete={() => setFile(null)} /> : null}
    </Modal>
  );
});

export default CreateNewInvoice;
