import React, { useState, useEffect } from 'react';
import _, { update } from 'lodash';
import { toJS } from 'mobx';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { Modal, TextInput, DatePicker, DatePickerInput } from '@carbon/react';
import { CreateClientParams } from 'src/endpoints/clients';
import { useStores } from 'src/stores';

const StyledRow = styled(Row)`
  margin-bottom: 32px;
`;

const UpdateClientModal = ({ isOpen, setIsOpen, clientAccountData, phrases }: any) => {
  const { clientsStore }: any = useStores();
  const [data, setData]: any = useState<Partial<CreateClientParams>>({});
  const [updateData, setUpdateData]: any = useState<Partial<CreateClientParams>>({});

  const onSubmit = async () => {
    await clientsStore.update(data.id, updateData as CreateClientParams);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!_.isEmpty(clientAccountData)) {
      setData(clientAccountData);
    }
  }, [clientAccountData]);

  const formatter = (value) => {
    const [year, month, day] = value.split('-');
    const result = `${month}-${day}-${year}`;
    return result;
  };

  const todaysDate = new Date();
  const day = todaysDate.getDate();
  const month = todaysDate.getMonth() + 1;
  const year = todaysDate.getFullYear();
  const maxDate = `${day}/${month}/${year}`;

  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.update_client_info}
      primaryButtonText={phrases.save_client_info}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => {
        setIsOpen(false);
        setData('');
      }}
    >
      <StyledRow>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.name}
            value={updateData.firstName !== undefined ? updateData.firstName : data.firstName}
            placeholder={phrases.enter_name}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, firstName: value })}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.surname}
            value={updateData.surname !== undefined ? updateData.surname : data.surname}
            placeholder={phrases.enter_surname}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, surname: value })}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <TextInput
            type="email"
            labelText={phrases.email_address}
            value={updateData.email !== undefined ? updateData.email : data.email}
            placeholder={phrases.enter_email}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, email: value })}
          />
        </Col>
        <Col>
          <TextInput
            type="number"
            labelText={phrases.phone_number}
            value={updateData.phone !== undefined ? updateData.phone : data.phone}
            placeholder={phrases.phone_number}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, phone: value })}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        {Object.keys(data).length && (
          <Col>
            <DatePicker
              dateFormat="d/m/Y"
              datePickerType="single"
              onChange={(value) => {
                const date = new Date(value);
                const format = date.toLocaleDateString('en-GB');
                const format2 = format.split('/').join('-');
                setUpdateData({ ...updateData, dateOfBirth: format2 });
              }}
              value={clientAccountData.dateOfBirth}
            >
              <DatePickerInput
                id="date-picker-default-1"
                placeholder="dd/mm/yyyy"
                labelText={phrases.date_of_birth}
              />
            </DatePicker>
          </Col>
        )}
      </StyledRow>
    </Modal>
  );
};

export default UpdateClientModal;
