import axiosInstance from '.';

export const getNotifications = async (): Promise<any> => {
  return axiosInstance.get('account/getNotificationManager').then((res) => res.data);
};

export interface NotificationsParams {
  accountOpeningEmail: boolean;
  accountOpeningPush: boolean;
  loanApplicationsEmail: boolean;
  loanApplicationsPush: boolean;
  meetingRequestsEmail: boolean;
  meetingRequestsPush: boolean;
  loanStatusChangeEmail: boolean;
  loanStatusChangePush: boolean;
  specialOffersEmail: boolean;
  specialOffersPush: boolean;
  // personalizedMortgageOfferEmail: boolean;
  // personalizedMortgageOfferPush: boolean;
}

export const updateNotifications = async (params: Partial<NotificationsParams>): Promise<any> => {
  return axiosInstance.put('account/updateNotificationManager', params);
};
