import { makeObservable, runInAction, toJS, action, observable } from 'mobx';
import {
  PatchPhrasesParams,
  patchPhrases,
  getAllTranslations,
  getISO,
  getLanguage,
  getDefaultLanguage,
  getAllCountries,
  postLanguage,
  deleteLanguage,
  PostLanguageParams,
  importLanguage,
} from 'src/endpoints/languages';
import genericErrorHandler from 'src/utils/genericErrorHandler';

export class LanguagesStore {
  languages = [];

  allLanguages = [];

  data = [];

  allIso = [];

  countryCodes = [];

  allTranslations = [];

  defaultLanguage = [];

  isLoading = false;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      getDefault: action,
      languages: observable,
      getLanguage: action,
      getCountryCodes: action,
      getAllISO: action,
      getAllPhrases: action,
      countryCodes: observable,
      allTranslations: observable,
      allIso: observable,
      postLanguage: action,
    });
  }

  update = async (data: PatchPhrasesParams) => {
    try {
      await patchPhrases(data);
      this.getAllPhrases();
      this.getDefault();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  getAllISO = async () => {
    this.isLoading = true;
    const content = await getISO();
    this.getAllPhrases();
    this.allIso = content;
  };

  getAllPhrases = async () => {
    this.isLoading = true;
    const content = await getAllTranslations();
    this.allTranslations = content;
    this.isLoading = false;
  };

  postLanguage = async (data: PostLanguageParams) => {
    try {
      await postLanguage(data);
      this.getAllISO();
      this.getAllPhrases();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  importLanguageFile = async (iso, file) => {
    try {
      await importLanguage(iso, file);
      this.getAllISO();
      this.getAllPhrases();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  deleteLanguage = async (id) => {
    try {
      await deleteLanguage(id);
      this.getAllISO();
      this.getAllPhrases();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  getDefault = async () => {
    this.isLoading = true;
    const content = await getDefaultLanguage();
    const format = content.phrases.map((ele, index) => {
      return { id: index, label: ele.label, translation: ele.translation };
    });
    this.languages = format;
  };

  getLanguage = async (id) => {
    this.isLoading = true;
    const content = await getLanguage(id);
    this.languages = content.phrases;
    this.isLoading = false;
  };

  getCountryCodes = async () => {
    this.isLoading = true;
    const content = await getAllCountries();
    this.countryCodes = content;
  };
}

export default new LanguagesStore();
