import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Loading } from '@carbon/react';
import Routes, { RoutesEnum } from './routes';
import { StoresContext, stores, useStores } from './stores';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const { authStore, languagesStore } = useStores();

  useEffect(() => {
    if (
      ![
        '/',
        RoutesEnum.LOGIN,
        RoutesEnum.FREE_FORM,
        RoutesEnum.FORGOT_PASSWORD,
        RoutesEnum.PASSWORD_CHANGE,
        RoutesEnum.THANK_YOU,
      ].includes(window.location.pathname) && !window.location.pathname.includes('/password-confirmation/')
    ) {
      authStore.getUserInfo();
    }
  }, []);

  useEffect(() => {
    languagesStore.getDefault();
    languagesStore.getAllISO();
  }, [languagesStore]);

  if (
    (![
      '/',
      RoutesEnum.LOGIN,
      RoutesEnum.FREE_FORM,
      RoutesEnum.FORGOT_PASSWORD,
      RoutesEnum.PASSWORD_CHANGE,
      RoutesEnum.THANK_YOU,
    ].includes(window.location.pathname)
    && !authStore.userInfo.privileges?.length) && !window.location.pathname.includes('/password-confirmation/')
  ) {
    if (!authStore.isAuthenticated) {
      window.location.replace(RoutesEnum.LOGIN);
    }
    return <Loading />;
  }

  return (
    <>
      <StoresContext.Provider value={stores}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </StoresContext.Provider>
      <ToastContainer position="top-right" autoClose={5000} theme="dark" />
    </>
  );
};

export default observer(App);
