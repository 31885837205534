import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row } from 'react-grid-system';
import { TextInput, Button } from '@carbon/react';
import { ArrowRight } from '@carbon/react/icons';
import { useStores } from 'src/stores';
import Header from 'src/components/layout/Header';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  padding: 24px;

  > div {
    width: 530px;
    margin: auto;
    background-color: white;

    @media screen and (max-width: 672px) {
      width: 100%;
    }
  }
`;

const StyledBody = styled.div`
  padding: 24px;
  h3 {
    margin-bottom: 48px;
  }
  button {
    margin: 52px 0 24px 14px;
  }
`;

const StyledH1 = styled.h1`
  font-weight: 300;
  font-size: 54px;
  line-height: 64px;
  padding-bottom: 10px;
`;
const StyledParagraph = styled.p`
  font-weight: 300;
  font-size: 14px;
`;

const StyledFooter = styled.footer`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

const ThankYouPage = ({ phrases }) => {
  const navigate = useNavigate();
  const onSubmit = (event) => {
    event.preventDefault();
    navigate('/login', { replace: true });
  };

  return (
    <>
      <Header />
      <StyledContainer>
        <StyledBody>
          <Container>
            <StyledH1>Thank you!</StyledH1>
            <StyledParagraph>
              Please check your email for further instructions on how to complete your account.
            </StyledParagraph>
            <Row>
              <Button type="submit" renderIcon={ArrowRight} onClick={onSubmit}>
                Go to login page
              </Button>
            </Row>
            <StyledFooter>
              Thank you,
              <br /> Team
              <a href="/"> Finbox</a>
            </StyledFooter>
          </Container>
        </StyledBody>
      </StyledContainer>
    </>
  );
};

export default ThankYouPage;
