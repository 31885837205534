import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { Modal, Dropdown, TextInput } from '@carbon/react';
import { InternalAccountParams } from 'src/endpoints/internals';
import { useStores } from 'src/stores';

const StyledRow = styled(Row)`
  margin-bottom: 32px;
`;

const UpdateModal = ({ isOpen, setIsOpen, internalAccountData, phrases }) => {
  const { internalsStore } = useStores();
  const [data, setData] = useState<Partial<InternalAccountParams>>({});
  const [updateData, setUpdateData] = useState<Partial<InternalAccountParams>>({});

  const onSubmit = async () => {
    await internalsStore.update(data.id, updateData as InternalAccountParams);
    setIsOpen(false);
  };
  useEffect(() => {
    if (!_.isEmpty(internalAccountData)) {
      setData(internalAccountData);
    }
  }, [internalAccountData]);

  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.update_internal_account}
      primaryButtonText={phrases.update_account}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      <StyledRow>
        <Col>
          <Dropdown
            label={phrases.select}
            titleText="Type"
            items={[
              { id: 'ADMIN', label: 'Admin' },
              { id: 'ACCOUNT_EXECUTIVE', label: 'Account executive' },
              { id: 'BRANCH', label: 'Branch' },
            ]}
            onChange={({ selectedItem: { id } }) => setUpdateData({ ...updateData, accountType: id })}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.name}
            placeholder={phrases.enter_name}
            value={updateData.firstName !== undefined ? updateData.firstName : data.firstName}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, firstName: value })}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.surname}
            placeholder={phrases.enter_surname}
            value={updateData.surname !== undefined ? updateData.surname : data.surname}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, surname: value })}
          />
        </Col>
        <Col>
          <TextInput
            type="email"
            labelText={phrases.email_address}
            placeholder={phrases.enter_email}
            value={updateData.email !== undefined ? updateData.email : data.email}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, email: value })}
          />
        </Col>
      </StyledRow>
    </Modal>
  );
};

export default UpdateModal;
