import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Link, Route, useLocation } from 'react-router-dom';
import { useStores } from 'src/stores';
import {
  SideNav as CarbonSideNav,
  SideNavItems as CarbonSideNavItems,
  SideNavLink,
  SideNavMenu,
  SideNavMenuItem,
  SideNavDivider,
} from '@carbon/react';
import { RoutesEnum } from 'src/routes';
import { PrivilegesEnum, RolesEnum } from 'src/privileges';
import AccessControlHOC from '../AccessControlHOC';

export const SideNavItems = () => {
  const { pathname } = useLocation();
  // const [phrases, setPhrases] = useState(Object);
  const { languagesStore } = useStores();
  const result: any = languagesStore.languages;
  const phrases = result.reduce((map, obj) => {
    map[obj.label] = obj.translation;
    return map;
  }, {});

  // TODO: If you want to add new <SideNavMenu /> component, you must to provide reference and click function

  const sideNavMenu1 = useRef<HTMLButtonElement>(null);
  const sideNavMenu2 = useRef<HTMLButtonElement>(null);
  const sideNavMenu3 = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickSideNav1 = (event: any) => {
      event.stopPropagation();
      sideNavMenu2.current && sideNavMenu2.current.setAttribute('aria-expanded', 'false');
      sideNavMenu3.current && sideNavMenu3.current.setAttribute('aria-expanded', 'false');
      sideNavMenu1.current && sideNavMenu1.current.setAttribute('aria-expanded', 'true');
    };

    const handleClickSideNav2 = (event: any) => {
      event.stopPropagation();
      sideNavMenu1.current && sideNavMenu1.current.setAttribute('aria-expanded', 'false');
      sideNavMenu3.current && sideNavMenu3.current.setAttribute('aria-expanded', 'false');
      sideNavMenu2.current && sideNavMenu2.current.setAttribute('aria-expanded', 'true');
    };

    const handleClickSideNav3 = (event: any) => {
      event.stopPropagation();
      sideNavMenu1.current && sideNavMenu1.current.setAttribute('aria-expanded', 'false');
      sideNavMenu2.current && sideNavMenu2.current.setAttribute('aria-expanded', 'false');
      sideNavMenu3.current && sideNavMenu3.current.setAttribute('aria-expanded', 'true');
    };

    const sideButton1 = sideNavMenu1.current;
    const sideButton2 = sideNavMenu2.current;
    const sideButton3 = sideNavMenu3.current;

    sideButton1?.addEventListener('click', handleClickSideNav1);
    sideButton2?.addEventListener('click', handleClickSideNav2);
    sideButton3?.addEventListener('click', handleClickSideNav3);

    return () => {
      sideButton1?.removeEventListener('click', handleClickSideNav1);
      sideButton2?.removeEventListener('click', handleClickSideNav2);
      sideButton3?.removeEventListener('click', handleClickSideNav3);
    };
  }, []);

  return (
    <CarbonSideNavItems>
      <SideNavDivider />

      <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_LOANS}>
        <SideNavMenu title={phrases.leads} ref={sideNavMenu1}>
          <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_CLIENTS}>
            <SideNavMenuItem
              element={Link}
              to={RoutesEnum.ALL_CLIENTS}
              isActive={pathname === RoutesEnum.ALL_CLIENTS || pathname === RoutesEnum.ADD_NEW_LEAD}
            >
              {phrases.all_leads}
            </SideNavMenuItem>
          </AccessControlHOC>
          <SideNavMenuItem
            element={Link}
            to={RoutesEnum.MORTGAGE_APPLICATION}
            isActive={pathname === RoutesEnum.MORTGAGE_APPLICATION}
          >
            {phrases.mortgage_leads}
          </SideNavMenuItem>
          <SideNavMenuItem
            element={Link}
            to={RoutesEnum.CASH_AND_LOAN_APPLICATION}
            isActive={pathname === RoutesEnum.CASH_AND_LOAN_APPLICATION}
          >
            {phrases.cash_loan_leads}
          </SideNavMenuItem>
        </SideNavMenu>
      </AccessControlHOC>
      <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_MEETINGS}>
        <SideNavLink
          element={Link}
          to={RoutesEnum.MEETING_REQUESTS}
          isActive={pathname === RoutesEnum.MEETING_REQUESTS}
        >
          {phrases.meeting_requests}
        </SideNavLink>
      </AccessControlHOC>
      <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_REPORTS}>
        <SideNavLink
          element={Link}
          to={RoutesEnum.MONTHLY_REPORTS}
          isActive={pathname === RoutesEnum.MONTHLY_REPORTS}
        >
          {phrases.monthly_reports}
        </SideNavLink>
      </AccessControlHOC>
      <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_INVOICES}>
        <SideNavLink
          element={Link}
          to={RoutesEnum.MONTHLY_INVOICES}
          isActive={pathname === RoutesEnum.MONTHLY_INVOICES}
        >
          {phrases.monthly_invoices}
        </SideNavLink>
      </AccessControlHOC>
      <AccessControlHOC
        requiredPrivileges={[
          PrivilegesEnum.READ_INTERNALS,
          PrivilegesEnum.READ_PARTNERS,
          PrivilegesEnum.READ_AGENTS,
        ]}
      >
        <SideNavMenu title={phrases.account_management} ref={sideNavMenu2}>
          <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_INTERNALS}>
            <SideNavMenuItem
              element={Link}
              to={RoutesEnum.INTERNAL_ACCOUNTS}
              isActive={pathname === RoutesEnum.INTERNAL_ACCOUNTS}
            >
              {phrases.internal_accounts}
            </SideNavMenuItem>
          </AccessControlHOC>
          <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_PARTNERS}>
            <SideNavMenuItem
              element={Link}
              to={RoutesEnum.PARTNERS_ACCOUNTS}
              isActive={pathname === RoutesEnum.PARTNERS_ACCOUNTS}
            >
              {phrases.partners_accounts}
            </SideNavMenuItem>
          </AccessControlHOC>
          <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_AGENTS}>
            <SideNavMenuItem
              element={Link}
              to={RoutesEnum.AGENTS_ACCOUNTS}
              isActive={pathname === RoutesEnum.AGENTS_ACCOUNTS}
            >
              {phrases.agents_accounts}
            </SideNavMenuItem>
          </AccessControlHOC>
        </SideNavMenu>
      </AccessControlHOC>
      {/* <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_PRODUCTS}>
        <SideNavLink
          element={Link}
          to={RoutesEnum.PRODUCT_MANAGEMENT}
          isActive={pathname === RoutesEnum.PRODUCT_MANAGEMENT || pathname === RoutesEnum.ADD_PRODUCT}
        >
          {phrases.product_management}
        </SideNavLink>
      </AccessControlHOC> */}
      {/* <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_PARTNERS}>
        <SideNavLink
          element={Link}
          to={RoutesEnum.PARTNERS_MANAGEMENT}
          isActive={
            pathname === RoutesEnum.PARTNERS_MANAGEMENT || pathname === RoutesEnum.PARTNERS_MANAGEMENT_ADD_PARTNER
          }
        >
          {phrases.partners_management}
        </SideNavLink>
      </AccessControlHOC> */}
      <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_OFFERS}>
        <SideNavMenu title={phrases.offers_management} ref={sideNavMenu3}>
          <SideNavMenuItem
            element={Link}
            to={RoutesEnum.ELIGIBILITY_AND_STANDARD_PRICING}
            isActive={pathname === RoutesEnum.ELIGIBILITY_AND_STANDARD_PRICING}
          >
            {phrases.eligibility_and_standard_pricing}
          </SideNavMenuItem>
          <SideNavMenuItem
            element={Link}
            to={RoutesEnum.FEATURED_OFFERS}
            isActive={pathname === RoutesEnum.FEATURED_OFFERS}
          >
            {phrases.featured_offers}
          </SideNavMenuItem>
        </SideNavMenu>
      </AccessControlHOC>
      <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_LANGUAGES}>
        <SideNavLink
          element={Link}
          to={RoutesEnum.LANGUAGE_MANAGEMENT}
          isActive={pathname === RoutesEnum.LANGUAGE_MANAGEMENT}
        >
          {phrases.language_management}
        </SideNavLink>
      </AccessControlHOC>
      <SideNavDivider />
      <AccessControlHOC requiredPrivileges={PrivilegesEnum.READ_NOTIFICATIONS}>
        <SideNavLink
          element={Link}
          to={RoutesEnum.NOTIFICATIONS_MANAGEMENT}
          isActive={pathname === RoutesEnum.NOTIFICATIONS_MANAGEMENT}
        >
          {phrases.notifications_management}
        </SideNavLink>
      </AccessControlHOC>
    </CarbonSideNavItems>
  );
};

const SideNav = () => (
  <CarbonSideNav
    isFixedNav
    isChildOfHeader
    expanded
    isPersistent={false}
    aria-label="Side navigation"
    className="sidenav"
  >
    <SideNavItems />
  </CarbonSideNav>
);

export default SideNav;
