import _ from 'lodash';
import { PrivilegesEnum } from 'src/privileges';
import hasPrivileges from 'src/utils/hasPrivileges';

interface Props {
  requiredPrivileges: PrivilegesEnum | PrivilegesEnum[];
  children: JSX.Element;
}

const AccessControlHOC = ({ requiredPrivileges, children }: Props) => {
  return hasPrivileges(requiredPrivileges) ? children : null;
};

export default AccessControlHOC;
