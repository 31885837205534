import { paginationHelperMethod } from 'src/utils/paginationRoute';
import axiosInstance from '.';

export interface CreateClientParams {
  agentId: number | string;
  firstName: string;
  surname: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  productIds: number[],
  mortgage: {
    id: number;
    ProductId: number;
    placeholder: string;
    currency: string;
    rate_id: number;
    loan_amount: number;
    clientId: string;
    birth_date: string;
    birth_place: string;
    marital_status: string;
    household_members: number;
    education_level: string;
    street: string;
    address: string;
    zip_code: string;
    city: string;
    country: string;
    credit_period: number;
    participation: number;
    property_street: string;
    property_address: string;
    property_city: string;
    property_zip_code: string;
    property_muncipality: string;
    property_size: number;
    price_per_square: number;
    price_without_parking: number;
    parking_price: number;
    property_is_new: boolean;
    property_is_registered: boolean;
    property_seller_information: string;
    additional_notes: string;
    job_title: string;
    job_type: string;
    industry: string;
    employer_name: string;
    employer_address: string;
    employment_start_date: string;
    average_salary: number;
    id_card: string;
    profession: string;
    nationality: string;
    credit_type: string;
    credit_amount: number;
    job_sector: string;
    company_existence: string;
    employment_duration: string;
  };
  cashLoan: {
    id: number;
    productId: number;
    placeholder: string;
    currency: string;
    rateId: number;
    loanAmount: number;
    clientId: string;
    birthPlace: string;
    marital_status: string;
    educationLevel: string;
    street: string;
    address: string;
    zipCode: string;
    city: string;
    country: string;
    creditPeriod: number;
    participation: number;
    property_street: string;
    property_address: string;
    property_city: string;
    property_zip_code: string;
    property_muncipality: string;
    property_size: number;
    price_per_square: number;
    price_without_parking: number;
    parking_price: number;
    property_is_new: boolean;
    property_is_registered: boolean;
    property_seller_information: string;
    additional_notes: string;
    job_title: string;
    job_type: string;
    industry: string;
    employer_name: string;
    employer_address: string;
    employment_start_date: string;
    average_salary: number;
    id_card: string;
    profession: string;
    nationality: string;
    credit_type: string;
    credit_amount: number;
    job_sector: string;
    company_existence: string;
    employment_duration: string;
  };
}

export const createClient = async (params: Partial<CreateClientParams>): Promise<any> => {
  return axiosInstance.post('/client/saveClient/', params).then((res) => res.data);
};

export const getAllClients = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'client', filter || '', filterFrom || '', filterTo || '', leadsValidity, approvalStatus, paymentStatus, meetingStatus);
};

export const deleteClient = async (id: number): Promise<any> => {
  return axiosInstance.delete(`/client/${id}`);
};

export const updateClient = async (id, params: Partial<CreateClientParams>): Promise<any> => {
  return axiosInstance.put(`/client/${id}`, params);
};

export const createFreeFormClientWithMortgage = async (params: Partial<CreateClientParams>): Promise<any> => {
  return axiosInstance.post('/client/saveClientFreeFormWithMortgage/', params);
};

export const createFreeFormClientWithCashLoan = async (params: Partial<CreateClientParams>): Promise<any> => {
  return axiosInstance.post('/client/saveClientFreeFormWithCashLoan/', params);
};

export const createFreeForm = async (params: Partial<CreateClientParams>): Promise<any> => {
  return axiosInstance.post('/client/saveClientFreeForm/', params);
};

export const createMortgageAsSuperAdmin = async (
  agentId,
  params: Partial<CreateClientParams>,
): Promise<any> => {
  return axiosInstance.post(`/client/saveClientWithMortgage/${agentId}`, params).then((res) => res.data);
};

export const createCashLoanAsSuperAdmin = async (
  agentId,
  params: Partial<CreateClientParams>,
): Promise<any> => {
  return axiosInstance.post(`/client/saveClientWithCashLoan/${agentId}`, params).then((res) => res.data);
};

export const createClientWithMortgage = async (
  params: Partial<CreateClientParams>,
): Promise<any> => {
  return axiosInstance.post('/client/saveClientWithMortgage/', params).then((res) => res.data);
};

export const createClientWithCashLoan = async (
  params: Partial<CreateClientParams>,
): Promise<any> => {
  return axiosInstance.post('/client/saveClientWithCashLoan/', params).then((res) => res.data);
};

export const getClientById = async (id: number): Promise<any> => {
  return axiosInstance.get(`/client/getById/${id}`).then((res) => res.data);
};

export const exportClients = async (fileName: string, searchString): Promise<any> => {
  return axiosInstance
    .get(`/client/exportClients/${fileName}?searchString=${searchString}`, {
      headers: {
        accept: 'text/csv',
      },
      responseType: 'blob',
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
    });
};
