import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Col } from 'react-grid-system';
import styled from 'styled-components';
import { Modal, Dropdown } from '@carbon/react';
import { useStores } from 'src/stores';
import { StatusParams } from 'src/endpoints/loans';
import { MeetingParams } from 'src/endpoints/meetings';

const StyledDropdown = styled.div`
  margin: 16px 0;
  height: 300px;
`;

const StatusChangeModal = observer(({ isOpen, setIsOpen, phrases, statusType, setStatusType }: any) => {
  const { loansStore, mortgagesStore, meetingsStore }: any = useStores();
  const [updateData, setUpdateData] = useState<Partial<StatusParams>>({});
  const [meetUpdateData, setMeetUpdateData] = useState<Partial<MeetingParams>>({});

  const onSubmit = async () => {
    updateData.id = statusType.id;
    statusType.type === 'meetingRequests' ? meetUpdateData.id = statusType.id : null;
    statusType.productType === 2 ? await mortgagesStore.editStatus(updateData as StatusParams)
      : statusType.type === 'meeting' ? await loansStore.editStatus(updateData as StatusParams)
        : statusType.productType === 1 ? await loansStore.editStatus(updateData as StatusParams)
          : statusType.type === 'meetingRequests' ? await meetingsStore.updateStatus(statusType.id, meetUpdateData) : null;
    setIsOpen(false);
    statusType.type === 'meetingRequests' ? (mortgagesStore.getAll(0, 10, '')) : statusType.type === 'meeting' ? (mortgagesStore.getAll(0, 10, ''), loansStore.getAll(0, 10, '')) : null;
  };

  const statuses = (statusType.type === 'meeting' || statusType.type === 'meetingRequests')
    ? [
      { label: 'Scheduled', value: 'scheduled', para: 'meetingStatus' },
      { label: 'Not attended', value: 'not_attended', para: 'meetingStatus' },
      { label: 'Completed', value: 'completed', para: 'meetingStatus' },
    ]
    : statusType.type === 'leadsValidity'
      ? [
        { label: 'Branch', value: 'branch', para: 'leadsValidity' },
        { label: 'Other agency', value: 'other_agency', para: 'leadsValidity' },
        { label: 'Valid', value: 'valid', para: 'leadsValidity' },
      ]
      : [
        { label: 'Rejected', value: 'rejected', para: 'approvalStatus' },
        { label: 'Loan approved', value: 'loan_approved', para: 'approvalStatus' },
        {
          label: 'Application completed',
          value: 'application_completed',
          para: 'approvalStatus',
        },
        { label: 'Documents collection', value: 'documents_collection', para: 'approvalStatus' },
        { label: 'Loan disbursed', value: 'loan_disbursed', para: 'approvalStatus' },
        { label: 'In progress', value: 'in_progress', para: 'approvalStatus' },
      ];

  return (
    isOpen && (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading="Status change"
      primaryButtonText={phrases.confirm}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => {
        setIsOpen(false);
        setStatusType({ type: '', id: '', productType: '' });
      }}
    >
      <StyledDropdown>
        <Col>
          <Dropdown
            placeholder="Select"
            titleText="Edit status"
            items={statuses.map((ele, index) => {
              return { id: index, label: ele.label, value: ele.value, para: ele.para };
            })}
            onChange={(value) => statusType.type === 'meeting'
              ? setUpdateData({ ...updateData, meetingStatus: value.selectedItem.value })
              : statusType.type === 'leadsValidity'
                ? setUpdateData({ ...updateData, leadsValidity: value.selectedItem.value })
                : statusType.type === 'meetingRequests' ? setMeetUpdateData({ status: value.selectedItem.value })
                  : setUpdateData({ ...updateData, approvalStatus: value.selectedItem.value })}
            initialSelectedItem="Select"
          />
        </Col>
      </StyledDropdown>
    </Modal>
    )
  );
});

export default StatusChangeModal;
