import { makeObservable, observable, computed, action } from 'mobx';
import {
  login,
  LoginParams,
  getUserDetails,
  forgotPassword,
  passwordChange,
} from 'src/endpoints/auth';
import { RoutesEnum } from 'src/routes';
import { RolesEnum, PrivilegesEnum } from 'src/privileges';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  roles: RolesEnum[];
  privileges: PrivilegesEnum[];
}

class AuthStore {
  userInfo: Partial<UserInfo> = {};

  constructor() {
    makeObservable(this, {
      userInfo: observable,
      isAuthenticated: computed,
      login: action,
      logout: action,
      getUserInfo: action,
    });
  }

  login = async (params: LoginParams) => {
    try {
      const { accessToken, refreshToken, roles } = await login(params);

      roles && roles[0] !== 'ROLE_BRANCH' ? await window.location.replace(RoutesEnum.ALL_CLIENTS) : await window.location.replace(RoutesEnum.MORTGAGE_APPLICATION);
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  forgotPassword = async (mail) => {
    try {
      await forgotPassword(mail);
      genericSuccessHandler('Confirmation e-mail sent.');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  passwordChangeVerification = async (newPassword, token) => {
    try {
      await passwordChange(newPassword, token);
      genericSuccessHandler('Password changed successfully!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('auth');
    localStorage.removeItem('refresh_token');
    window.location.replace(RoutesEnum.LOGIN);
  };

  getUserInfo = async () => {
    const data = await getUserDetails();
    this.userInfo = data;
  };

  get isAuthenticated() {
    return !!localStorage.getItem('access_token');
  }
}

export default new AuthStore();
