export enum RolesEnum {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ACCOUNT_EXECUTIVE = 'ROLE_ACCOUNT_EXECUTIVE',
  ROLE_BRANCH = 'ROLE_BRANCH',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_AGENT = 'ROLE_AGENT',
}

export enum PrivilegesEnum {
  READ_AGENTS = 'READ_AGENTS',
  WRITE_AGENT = 'WRITE_AGENT',
  UPDATE_AGENT = 'UPDATE_AGENT',
  DELETE_AGENT = 'DELETE_AGENT',
  READ_INTERNALS = 'READ_INTERNALS',
  WRITE_INTERNAL = 'WRITE_INTERNAL',
  UPDATE_INTERNAL = 'UPDATE_INTERNAL',
  DELETE_INTERNAL = 'DELETE_INTERNAL',
  READ_PARTNERS = 'READ_PARTNERS',
  WRITE_PARTNER = 'WRITE_PARTNER',
  UPDATE_PARTNER = 'UPDATE_PARTNER',
  DELETE_PARTNER = 'DELETE_PARTNER',
  READ_LANGUAGES = 'READ_LANGUAGES',
  WRITE_LANGUAGE = 'WRITE_LANGUAGE',
  UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
  DELETE_LANGUAGE = 'DELETE_LANGUAGE',
  READ_LEADS = 'READ_LEADS',
  WRITE_LEAD = 'WRITE_LEAD',
  UPDATE_LEAD = 'UPDATE_LEAD',
  DELETE_LEAD = 'DELETE_LEAD',
  READ_CLIENTS = 'READ_CLIENTS',
  WRITE_CLIENT = 'WRITE_CLIENT',
  UPDATE_CLIENT = 'UPDATE_CLIENT',
  DELETE_CLIENT = 'DELETE_CLIENT',
  READ_LOANS = 'READ_LOANS',
  WRITE_LOAN = 'WRITE_LOAN',
  UPDATE_LOAN = 'UPDATE_LOAN',
  DELETE_LOAN = 'DELETE_LOAN',
  READ_MEETINGS = 'READ_MEETINGS',
  WRITE_MEETING = 'WRITE_MEETING',
  UPDATE_MEETING = 'UPDATE_MEETING',
  DELETE_MEETING = 'DELETE_MEETING',
  READ_REPORTS = 'READ_REPORTS',
  WRITE_REPORT = 'WRITE_REPORT',
  UPDATE_REPORT = 'UPDATE_REPORT',
  DELETE_REPORT = 'DELETE_REPORT',
  READ_INVOICES = 'READ_INVOICES',
  WRITE_INVOICE = 'WRITE_INVOICE',
  UPDATE_INVOICE = 'UPDATE_INVOICE',
  DELETE_INVOICE = 'DELETE_INVOICE',
  READ_PRODUCTS = 'READ_PRODUCTS',
  WRITE_PRODUCT = 'WRITE_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  READ_OFFERS = 'READ_OFFERS',
  WRITE_OFFER = 'WRITE_OFFER',
  UPDATE_OFFER = 'UPDATE_OFFER',
  DELETE_OFFER = 'DELETE_OFFER',
  READ_NOTIFICATIONS = 'READ_NOTIFICATIONS',
  UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION',
}
