import React, { useEffect, useState } from 'react';
import { useStores } from 'src/stores';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import FilterMenu from 'src/utils/FilterMenu';
import usePagination from 'src/utils/pagination';
import styled from 'styled-components';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSelectAll,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableBatchActions,
  TableBatchAction,
  Button,
  Loading,
  DataTableSkeleton,
} from '@carbon/react';
import { Upload, Download, Add, Edit, TrashCan } from '@carbon/react/icons';
import { RoutesEnum } from 'src/routes';
import DeleteOffer from './DeleteOffer';

const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;

const EligibilityAndStandardPricing = observer(({ phrases }: any) => {
  const { cashOffersStore }: any = useStores();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState('');
  const [filterFrom, setFilterFrom]:any = useState();
  const [filterTo, setFilterTo]:any = useState();
  const [leadsValidity, setLeadsValidity]:any = useState();
  const [approvalStatus, setApprovalStatus]:any = useState();
  const [paymentStatus, setPaymentStatus]:any = useState();
  const [meetingStatus, setMeetingStatus]:any = useState();

  const headersData = [
    { key: 'productType', header: phrases.product_group },
    { key: 'offerName', header: phrases.offer_name }, //  TODOLABEL
    { key: 'interestRate', header: phrases.interest_rate },
    { key: 'isActive', header: phrases.is_acitve },
    // { key: 'salaryTresholdMin', header: phrases.Salary_threshold_min },
    { key: 'minAge', header: phrases.age_min },
    { key: 'maxAge', header: phrases.age_max },
  ];

  useEffect(() => {
    cashOffersStore.getAll(0, 10);
  }, []);

  const capitalizeWords = (string) => {
    if (typeof string === 'string') {
      string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
      string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
      string = string.replace('_', ' ');
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  };

  const isAnyModalOpen = isDeleteModalOpen;

  return (
    <div className="data-table">
      <DeleteOffer
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        phrases={phrases}
        deleteModalData={deleteModalData}
      />
      {cashOffersStore.isLoading && <DataTableSkeleton columnCount={4} rowCount={pageSize} />}
      {Object.keys(cashOffersStore.data).length && (
        <DataTable
          headers={headersData}
          rows={cashOffersStore.data.content}
          isSortable
          isSelectable
        >
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getSelectionProps,
            getToolbarProps,
            getBatchActionProps,
          }) => {
            const batchActionProps = getBatchActionProps();

            return (
              <TableContainer title={phrases.eligibility_and_standard_pricing}>
                <TableToolbar {...getToolbarProps()}>
                  <TableBatchActions {...batchActionProps}>
                    <TableBatchAction
                      tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                      renderIcon={Download}
                      onClick={(selectedRows) => console.log(selectedRows)}
                    >
                      {phrases.download}
                    </TableBatchAction>
                  </TableBatchActions>
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      onChange={(v) => {
                        setTimeout(() => {
                          setFilter(v.target.value);
                        }, 800);
                      }}
                      onClear={() => setFilter('')}
                    />
                    {/* <Button
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    renderIcon={Download}
                  >
                    {phrases.download}
                  </Button> */}
                    <Button
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      renderIcon={Add}
                      onClick={() => navigate(RoutesEnum.CREATE_NEW_OFFER, { replace: true })}
                    >
                      {phrases.add_new_special_offer}
                    </Button>
                  </TableToolbarContent>
                </TableToolbar>
                {/* <FilterMenu setFilterFrom={setFilterFrom} setFilterTo={setFilterTo} comingFrom="eligibility" setApprovalStatus={setApprovalStatus} setLeadsValidity={setLeadsValidity} /> */}
                {!cashOffersStore.isLoading && (
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        <TableSelectAll {...getSelectionProps()} />
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableSelectRow {...getSelectionProps({ row })} />
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>{capitalizeWords(cell.value)}</TableCell>
                          ))}
                          <StyledTableCell>
                            <TrashCan
                              onClick={() => {
                                setIsDeleteModalOpen(true);
                                setDeleteModalData(row);
                              }}
                            />
                            <Edit
                              onClick={() => row.cells[0].value === 'mortgage'
                                ? navigate(
                                  `/eligibility-and-standard-pricing/mortgage-offer/${row.id}`,
                                  { replace: true },
                                )
                                : navigate(
                                  `/eligibility-and-standard-pricing/cash-offer/${row.id}`,
                                  { replace: false },
                                )}
                            />
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            );
          }}
        </DataTable>
      )}
      {usePagination(phrases, cashOffersStore, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus, isAnyModalOpen)}
    </div>
  );
});

export default EligibilityAndStandardPricing;
