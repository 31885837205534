import { toast } from 'react-toastify';

const genericErrorHandler = (error) => {
  if (error.response) {
    if (error.response.data.message) {
      toast.error(error.response.data.message);
    } else if (error.response.data.data?.message) {
      toast.error(error.response.data.data.message);
    } else if (error.response.data.error) {
      toast.error(error.response.data.error);
    }
  } else {
    console.error(error);
  }
};

export default genericErrorHandler;
