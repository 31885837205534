import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row } from 'react-grid-system';
import { TextInput, Button } from '@carbon/react';
import { ArrowRight } from '@carbon/react/icons';
import { useStores } from 'src/stores';
import Header from 'src/components/layout/Header';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;

  > div {
    width: 530px;
    margin: auto;
    background-color: white;

    @media screen and (max-width: 672px) {
      width: 100%;
    }
  }
`;

const StyledBody = styled.div`
  padding: 16px;
  button {
    margin: 52px 0 24px;
  }
  h3 {
    margin-bottom: 50px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 24px;
`;

const ForgotPassword = ({ phrases }) => {
  const { authStore } = useStores();
  const [mail, setMail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    authStore.forgotPassword(mail);
  };

  return (
    <>
      <Header />
      <StyledContainer>
        <div>
          <StyledBody>
            <h3>{phrases.forgot_password}</h3>
            <p style={{ marginBottom: 24 }}>{phrases.enter_the_email_address}</p>
            <form onSubmit={onSubmit}>
              <Container>
                <StyledRow>
                  <TextInput
                    id="Email input"
                    type="email"
                    labelText="Your email"
                    placeholder="Email"
                    onChange={({ target }) => setMail(target.value)}
                  />
                </StyledRow>
                <Row>
                  <Button type="submit" renderIcon={ArrowRight} onClick={onSubmit}>
                    {phrases.send_reset_instructions}
                  </Button>
                </Row>
              </Container>
            </form>
          </StyledBody>
        </div>
      </StyledContainer>
    </>
  );
};

export default ForgotPassword;
