import React from 'react';
import { Modal, Checkbox, Column } from '@carbon/react';

const DownloadModal = ({ isOpen, setIsOpen, phrases }) => {
  //   const [isChecked, setIsChecked] = useState(false);
  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.download}
      primaryButtonText={phrases.download}
      secondaryButtonText={phrases.cancel}
      hasScrollingContent
      onRequestClose={() => setIsOpen(false)}
    >
      <legend style={{ marginBottom: 10 }}>Chose a download format</legend>
      <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
        <Checkbox labelText="PDF" id="checkbox-1" />
      </Column>
      <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
        <Checkbox labelText="Excel" id="checkbox-2" />
      </Column>
    </Modal>
  );
};

export default DownloadModal;
