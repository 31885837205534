import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Modal, Dropdown, TextInput, NumberInput } from '@carbon/react';
import { PartnerAccountParams, AddProductParams } from 'src/endpoints/partners';
import { useStores } from 'src/stores';
import products from 'src/stores/products';

const StyledRow = styled(Row)`
  margin-bottom: 32px;
`;

interface Errors {
  partnerType?: string;
  companyName?: string;
  companyAddress?: string;
  taxNumber?: string;
  companyNumber?: string;
  accountExecutiveId?: string;
  firstName?: string;
  surname?: string;
  email?: string;
  products?: any;
}

const CreateModal = observer(({ isOpen, setIsOpen, phrases }: any) => {
  const { partnersStore, internalsStore, productsStore }: any = useStores();
  const [productData, setProductData]: any = useState<Partial<AddProductParams>>({});
  const [data, setData]: any = useState<Partial<PartnerAccountParams>>({});
  const [errors, setErrors] = useState<Errors>({});

  const onSubmit = async () => {
    const isValid = validateFields();
    if (isValid) {
      await partnersStore.create(data as PartnerAccountParams);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    internalsStore.getAll();
    productsStore.getAll();
  }, []);

  const labelSearcher = (value) => {
    const find: any = Object.entries(phrases).find((ele) => ele[0] === value);
    if (!find) {
      return '';
    }
    return find[1];
  };

  const dataSetter = (id, index) => {
    const object = data;
    !object.products ? (object.products = []) : object.products;
    id === 1
      ? (object.products[index] = { productId: index + 1, commission: 0 })
      : (object.products[index] = null);
    setData(object);
  };
  const commisstionSetter = (value, index) => {
    const object = data;
    !object.products ? (object.products = []) : object.products;
    value ? (object.products[index].commission = value) : null;
    setData(object);
  };
  console.log(data);

  const validateFields = () => {
    const newErrors = {
      partnerType: '',
      companyName: '',
      companyAddress: '',
      taxNumber: '',
      companyNumber: '',
      accountExecutiveId: '',
      firstName: '',
      surname: '',
      email: '',
      products: '',
    };
    let hasErrors = false;
    if (!data.partnerType) {
      newErrors.partnerType = 'Partner type type is required';
      hasErrors = true;
    }
    if (!data.companyName) {
      newErrors.companyName = 'First name is required';
      hasErrors = true;
    }
    if (!data.companyAddress) {
      newErrors.companyAddress = 'Company address is required';
      hasErrors = true;
    }
    if (!data.taxNumber) {
      newErrors.taxNumber = 'Tax number is required';
      hasErrors = true;
    }
    if (!data.companyNumber) {
      newErrors.companyNumber = 'Company number is required';
      hasErrors = true;
    }
    if (!data.accountExecutiveId) {
      newErrors.accountExecutiveId = 'Account executive id is required';
      hasErrors = true;
    }
    if (!data.firstName) {
      newErrors.firstName = 'First name is required';
      hasErrors = true;
    }
    if (!data.surname) {
      newErrors.surname = 'Surname is required';
      hasErrors = true;
    }
    if (!data.email) {
      newErrors.email = 'Email is required';
      hasErrors = true;
    }
    if (!data.products) {
      newErrors.products = 'Products are required';
      hasErrors = true;
    }
    setErrors(newErrors);
    if (!hasErrors) {
      return true;
    }
    setTimeout(() => {
      setErrors({});
      hasErrors = false;
    }, 3000);
    return false;
  };

  return (
    <Modal
      open={isOpen}
      size="md"
      modalHeading={phrases.create_new_partners_accounts}
      primaryButtonText={phrases.create_new_partner}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => {
        setIsOpen(false);
        setProductData({});
        setData({});
      }}
    >
      <StyledRow>
        <Col>
          <Dropdown
            label={phrases.select}
            titleText={phrases.type_of_partner}
            items={[
              { id: 'REAL_ESTATE_AGENCY', label: 'Real estate agency' },
              { id: 'DIGITAL_MORTGAGE_BROKER', label: 'Digital mortgage broker' },
              { id: 'LEAD_GEN_PLATFORM', label: 'Lead gen platform' },
            ]}
            onChange={({ selectedItem: { id } }) => setData({ ...data, partnerType: id })}
            invalid={!!errors.partnerType}
            invalidText={errors.partnerType}
          />
        </Col>
        <Col style={{ marginTop: '2px' }}>
          <TextInput
            type="text"
            labelText={phrases.company_name}
            placeholder={phrases.enter_company_name}
            onChange={({ target: { value } }) => setData({ ...data, companyName: value })}
            invalid={!!errors.companyName}
            invalidText={errors.companyName}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.company_address}
            placeholder={phrases.enter_company_address}
            onChange={({ target: { value } }) => setData({ ...data, companyAddress: value })}
            invalid={!!errors.companyAddress}
            invalidText={errors.companyAddress}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.tax_number}
            placeholder={phrases.enter_tax}
            onChange={({ target: { value } }) => setData({ ...data, taxNumber: value })}
            invalid={!!errors.taxNumber}
            invalidText={errors.taxNumber}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col style={{ marginTop: '3px' }}>
          <TextInput
            type="text"
            labelText={phrases.company_number}
            placeholder={phrases.enter_number}
            onChange={({ target: { value } }) => setData({ ...data, companyNumber: value })}
            invalid={!!errors.companyNumber}
            invalidText={errors.companyNumber}
          />
        </Col>
        {Object.keys(internalsStore.data).length && (
          <Col>
            <Dropdown
              label={phrases.select}
              titleText="Account executive" /// TODO LABEL
              items={internalsStore.data.content
                .filter((element) => element.accountType === 'ACCOUNT_EXECUTIVE')
                .map((ele) => {
                  return { id: ele.id, label: `${ele.firstName} ${ele.surname}` };
                })}
              onChange={({ selectedItem: { id } }) => setData({ ...data, accountExecutiveId: id })}
              invalid={!!errors.accountExecutiveId}
              invalidText={errors.accountExecutiveId}
            />
          </Col>
        )}
      </StyledRow>
      <StyledRow>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.name}
            placeholder={phrases.enter_name}
            onChange={({ target: { value } }) => setData({ ...data, firstName: value })}
            invalid={!!errors.firstName}
            invalidText={errors.firstName}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.surname}
            placeholder={phrases.enter_surname}
            onChange={({ target: { value } }) => setData({ ...data, surname: value })}
            invalid={!!errors.surname}
            invalidText={errors.surname}
          />
        </Col>
      </StyledRow>
      {productsStore.data.map((ele, index: number) => {
        return (
          <StyledRow>
            <Col>
              <Dropdown
                label={phrases.select}
                titleText={labelSearcher(ele.productType)}
                initialSelectedItem={{ id: 1, label: 'No' }}
                items={[
                  { id: 1, label: 'Yes' },
                  { id: 2, label: 'No' },
                ]}
                onChange={({ selectedItem: { id } }) => {
                  dataSetter(id, index);
                }}
                invalid={!!errors.products}
                invalidText={errors.products}
              />
            </Col>
            <Col style={{ marginTop: '2px' }}>
              <NumberInput
                type="number"
                label={`${phrases.commission} %`}
                defaultValue={0}
                placeholder={phrases.enter_commission}
                onChange={({ target: { value } }) => {
                  commisstionSetter(value, index);
                }}
                invalid={!!errors.products}
                invalidText={errors.products}
              />
            </Col>
          </StyledRow>
        );
      })}
      <Row>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.email_address}
            placeholder={phrases.enter_email}
            onChange={({ target: { value } }) => setData({ ...data, email: value })}
            invalid={!!errors.email}
            invalidText={errors.email}
          />
        </Col>
      </Row>
    </Modal>
  );
});

export default CreateModal;
