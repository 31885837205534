import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import usePagination from 'src/utils/pagination';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  DataTableSkeleton,
} from '@carbon/react';
import { Add, Edit, TrashCan } from '@carbon/react/icons';
import { useStores } from 'src/stores';
import { InternalAccountParams } from 'src/endpoints/internals';
import CreateModal from './CreateModal';
import UpdateModal from './UpdateModal';
import DeleteModal from './DeleteModal';

const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;

const InternalAccounts = observer(({ phrases }: any) => {
  const { internalsStore, authStore }: any = useStores();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedInternalAccount, setSelectedInternalAccount] = useState<
    Partial<InternalAccountParams>
  >({});
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState('');
  const [filterFrom, setFilterFrom]:any = useState();
  const [filterTo, setFilterTo]:any = useState();
  const [leadsValidity, setLeadsValidity]:any = useState();
  const [approvalStatus, setApprovalStatus]:any = useState();
  const [paymentStatus, setPaymentStatus]:any = useState();
  const [meetingStatus, setMeetingStatus]:any = useState();

  const headersData = [
    { key: 'accountType', header: phrases.type },
    { key: 'firstName', header: phrases.name },
    { key: 'surname', header: phrases.surname },
    { key: 'jobTitle', header: phrases.role },
    { key: 'email', header: phrases.email_address },
  ];

  useEffect(() => {
    internalsStore.getAll(10, 0);
  }, []);

  const capitalizeWords = (string) => {
    if (typeof string === 'string') {
      string = string.split('_').join(' ');
      string = string.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  };

  const isAnyModalOpen = isCreateModalOpen || isDeleteModalOpen || isUpdateModalOpen;

  return (
    <div className="data-table">
      <CreateModal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} phrases={phrases} />
      <UpdateModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        internalAccountData={selectedInternalAccount}
        phrases={phrases}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        internalId={selectedInternalAccount.id}
        phrases={phrases}
      />
      {internalsStore.isLoading && (
        <DataTableSkeleton columnCount={headersData.length} rowCount={pageSize} />
      )}
      {Object.keys(internalsStore.data).length && (
        <DataTable headers={headersData} rows={internalsStore.data.content} isSortable isSelectable>
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getBatchActionProps,
          }) => {
            const batchActionProps = getBatchActionProps();

            return (
              <TableContainer title={phrases.internal_accounts}>
                <TableToolbar {...getToolbarProps()}>
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      onChange={(v) => {
                        setTimeout(() => {
                          setFilter(v.target.value);
                        }, 800);
                      }}
                      onClear={() => setFilter('')}
                    />
                    {authStore.userInfo.roles[0] === 'ROLE_SUPER_ADMIN' && (
                    <Button
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      renderIcon={Add}
                      onClick={() => setIsCreateModalOpen(true)}
                    >
                      {phrases.create_internal_accounts}
                    </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>
                {/* <FilterMenu setFilterFrom={setFilterFrom} setFilterTo={setFilterTo} comingFrom="internals" setApprovalStatus={setApprovalStatus} setLeadsValidity={setLeadsValidity} /> */}
                {!internalsStore.isLoading && (
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                        ))}
                        <TableHeader />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.cells.map((cell) => {
                            switch (cell.info.header) {
                              case 'accountType':
                                return (
                                  <TableCell key={cell.id}>{capitalizeWords(cell.value)}</TableCell>
                                );
                              default:
                                return <TableCell key={cell.id}>{cell.value}</TableCell>;
                            }
                          })}
                          <StyledTableCell>
                            <TrashCan
                              onClick={() => {
                                setSelectedInternalAccount(row);
                                setIsDeleteModalOpen(true);
                              }}
                            />
                            <Edit
                              onClick={() => {
                                const data = internalsStore.data.content.find(({ id }) => row.id === id) || {};
                                setSelectedInternalAccount(data);
                                setIsUpdateModalOpen(true);
                              }}
                            />
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            );
          }}
        </DataTable>
      )}
      {usePagination(phrases, internalsStore, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus, isAnyModalOpen)}
    </div>
  );
});

export default InternalAccounts;
