import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import usePagination from 'src/utils/pagination';
import styled from 'styled-components';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  DataTableSkeleton,
} from '@carbon/react';
import { Add, Edit, TrashCan } from '@carbon/react/icons';
import { useStores } from 'src/stores';
import { PartnerAccountParams } from 'src/endpoints/partners';
import CreateModal from './CreateModal';
import UpdateModal from './UpdateModal';
import DeleteModal from './DeleteModal';

const StyledTableCell = styled(TableCell)`
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;

const PartnerAccounts = observer(({ phrases }: any) => {
  const { partnersStore, authStore }: any = useStores();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPartnerAccount, setSelectedPartnerAccount] = useState<
    Partial<PartnerAccountParams>
  >({});
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState('');
  const [filterFrom, setFilterFrom]:any = useState();
  const [filterTo, setFilterTo]:any = useState();
  const [leadsValidity, setLeadsValidity]:any = useState();
  const [approvalStatus, setApprovalStatus]:any = useState();
  const [paymentStatus, setPaymentStatus]:any = useState();
  const [meetingStatus, setMeetingStatus]:any = useState();

  const headersData = [
    { key: 'partnerType', header: phrases.type_of_partner },
    { key: 'companyName', header: phrases.company_name },
    { key: 'companyAddress', header: phrases.company_address },
    { key: 'taxNumber', header: phrases.tax_number },
    { key: 'companyNumber', header: phrases.company_number },
    { key: 'accountExecutive', header: phrases.account_executive },
    { key: 'email', header: phrases.email_address },
    { key: 'firstName', header: phrases.name },
    { key: 'surname', header: phrases.surname },
  ];

  useEffect(() => {
    partnersStore.getAll();
  }, []);

  const capitalizeWords = (string) => {
    if (typeof string === 'string') {
      string = string.split('_').join(' ');
      string = string.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  };

  const isAnyModalOpen = isCreateModalOpen || isUpdateModalOpen || isDeleteModalOpen;

  return (
    <div className="data-table">
      <CreateModal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} phrases={phrases} />
      <UpdateModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        partnerAccountData={selectedPartnerAccount}
        phrases={phrases}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        partnerId={selectedPartnerAccount.id}
        phrases={phrases}
      />
      {partnersStore.isLoading && (
        <DataTableSkeleton columnCount={headersData.length} rowCount={pageSize} />
      )}
      {Object.keys(partnersStore.data).length && (
        <DataTable headers={headersData} rows={partnersStore.data.content} isSortable isSelectable>
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getBatchActionProps,
          }) => {
            const batchActionProps = getBatchActionProps();

            return (
              <TableContainer title={phrases.partners_accounts}>
                <TableToolbar {...getToolbarProps()}>
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      onChange={(v) => {
                        setTimeout(() => {
                          setFilter(v.target.value);
                        }, 800);
                      }}
                      onClear={() => setFilter('')}
                    />
                    {/* <FilterMenu setFilterFrom={setFilterFrom} setFilterTo={setFilterTo} comingFrom="partners" setApprovalStatus={setApprovalStatus} setLeadsValidity={setLeadsValidity} /> */}
                    {authStore.userInfo.roles[0] !== 'ROLE_ACCOUNT_EXECUTIVE' && (
                    <Button
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      renderIcon={Add}
                      onClick={() => setIsCreateModalOpen(true)}
                    >
                      {phrases.create_new_partner}
                    </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>
                {!partnersStore.isLoading && (
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                        ))}
                        <TableHeader />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.cells.map((cell) => {
                            switch (cell.info.header) {
                              case 'partnerType':
                                return <TableCell key={cell.id}>{capitalizeWords(cell.value)}</TableCell>;
                              default:
                                return (
                                  <TableCell key={cell.id}>{cell.value}</TableCell>
                                );
                            }
                          })}
                          <StyledTableCell>
                            <TrashCan
                              onClick={() => {
                                setSelectedPartnerAccount(row);
                                setIsDeleteModalOpen(true);
                              }}
                            />
                            <Edit
                              onClick={() => {
                                const data = partnersStore.data.content.find(({ id }) => row.id === id) || {};
                                setSelectedPartnerAccount(data);
                                setIsUpdateModalOpen(true);
                              }}
                            />
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            );
          }}
        </DataTable>
      )}
      {usePagination(phrases, partnersStore, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus, isAnyModalOpen)}
    </div>
  );
});

export default PartnerAccounts;
