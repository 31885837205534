import { paginationHelperMethod } from 'src/utils/paginationRoute';
import axiosInstance from '.';

export interface LoanParams {
  ProductId: number;
  placeholder: string;
  currency: string;
  rate_id: number;
  loan_amount: number;
  clientId: string;
  birth_date: string;
  birth_place: string;
  marital_status: string;
  household_members: number;
  education_level: string;
  street: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  phone: string;
  email: string;
  credit_period: number;
  participation: number;
  property_street: string;
  property_address: string;
  property_city: string;
  property_zip_code: string;
  property_muncipality: string;
  property_size: number;
  price_per_square: number;
  price_without_parking: number;
  parking_price: number;
  property_is_new: boolean;
  property_is_registered: boolean;
  property_seller_information: string;
  additional_notes: string;
  job_title: string;
  job_type: string;
  industry: string;
  employer_name: string;
  employer_address: string;
  employment_start_date: string;
  average_salary: number;
  id_card: string;
  profession: string;
  nationality: string;
  credit_type: string;
  credit_amount: number;
  job_sector: string;
  company_existence: string;
  employment_duration: string;
}

export interface StatusParams {
  id: number;
  leadsValidity: string;
  approvalStatus: string;
  meetingStatus: string;
  status: string;
  clientId: string,
  branchId: number,
  pipelineId: number,
}

export const getAllMortgages = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'product-pipeline/mortgage/withClient', filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
};

export const getAllCashLoans = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'product-pipeline/cash-loan/withClient', filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
};

export const createMortgagePipeline = async (params: Partial<LoanParams>): Promise<any> => {
  return axiosInstance.post('/product-pipeline/mortgage', params);
};

export const createCashLoanPipeline = async (params: Partial<LoanParams>): Promise<any> => {
  return axiosInstance.post('/product-pipeline/cash-loan', params);
};
export const getLoanById = async (id:string): Promise<any> => {
  return axiosInstance.get(`/product-pipeline/${id}`).then((res) => res.data);
};

export const deleteLoan = async (id: string): Promise<any> => {
  return axiosInstance.delete(`/product-pipeline/${id}`);
};
export const editMortgage = async (params: Partial<LoanParams>): Promise<any> => {
  return axiosInstance.patch('/product-pipeline/mortgage', params);
};
export const editCashLoan = async (params: Partial<LoanParams>): Promise<any> => {
  return axiosInstance.patch('product-pipeline/cash-loan', params);
};

export const getLoansByClient = async (clientId:string): Promise<any> => {
  return axiosInstance.get(`/product-pipeline/client/${clientId}`).then((res) => res.data);
};

export const updateStatuses = async (params: Partial<StatusParams>): Promise<any> => {
  return axiosInstance.patch('product-pipeline/status', params);
};
