import React from 'react';
import { Navigate } from 'react-router-dom';
import { PrivilegesEnum } from 'src/privileges';
import hasPrivileges from 'src/utils/hasPrivileges';
import { RoutesEnum } from '..';

interface Props {
  requiredPrivileges: PrivilegesEnum | PrivilegesEnum[];
  children: JSX.Element;
}

const ProtectedRoute = ({ requiredPrivileges, children }: Props): JSX.Element => {
  if (!hasPrivileges(requiredPrivileges)) {
    return <Navigate to={RoutesEnum.ALL_CLIENTS} replace />;
  }

  return children;
};

export default ProtectedRoute;
