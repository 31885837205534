import React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from '@carbon/react';
import { useStores } from 'src/stores';

const DeleteInvoiceModal = observer(({ isOpen, setIsOpen, phrases, invoiceData }:any) => {
  const { invoicesStore } = useStores();

  const onSubmit = async () => {
    await invoicesStore.delete(invoiceData);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      size="sm"
      danger
      modalHeading="Are you sure?"
      primaryButtonText={phrases.delete}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      <p>Check if you want to continue with this action.</p>
    </Modal>
  );
});

export default DeleteInvoiceModal;
