import { toast } from 'react-toastify';

const genericSuccessHandler = (success) => {
  if (success.response) {
    if (success.response.data.message) {
      toast.success(success.response.data.message);
    } else if (success.response.data.data?.message) {
      toast.success(success.response.data.data.message);
    } else if (success.response.data.success) {
      toast.success(success.response.data.success);
    }
  } else {
    toast.success(success);
  }
};

export default genericSuccessHandler;
