import React, { useEffect } from 'react';
import { useStores } from 'src/stores';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Loading,
} from '@carbon/react';
import { useParams } from 'react-router-dom';

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;
const StyledBoldCell = styled(TableCell)`
  font-weight: 900;
`;

const DeepDiveClient = observer(({ phrases }: any) => {
  const { clientsStore }: any = useStores();
  const { id }: any = useParams();

  useEffect(() => {
    clientsStore.getById(id);
  }, []);

  const formatDate = (date) => {
    const format = date.substring(0, 10).split('-').reverse().join('-');
    const event = new Date(format);
    return event.toLocaleString('en-GB', { year: 'numeric', day: 'numeric', month: 'long' });
  };

  const rowData = Object.keys(clientsStore.dataById)
    .map((ele, index) => {
      return {
        id: index,
        first: ele,
        second: (ele === 'creationDate' || ele === 'dateOfBirth') ? formatDate(Object.values(clientsStore.dataById)[index]) : Object.values(clientsStore.dataById)[index],
      };
    })
    .slice(1);

  const headersData = [
    { key: 'first', header: phrases.id },
    { key: 'second', header: clientsStore.dataById.id },
  ];

  const capitalizeWords = (string) => {
    if (typeof string === 'string') {
      string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
      string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    if (typeof string === 'boolean') {
      return string ? 'True' : 'False';
    }
    return string;
  };

  if (clientsStore.isLoading) {
    return (
      <StyledLoading>
        <Loading withOverlay={false} />
      </StyledLoading>
    );
  }
  return (
    <div className="data-table">
      <DataTable headers={headersData} rows={rowData} isSortable isSelectable>
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getBatchActionProps,
        }) => {
          const batchActionProps = getBatchActionProps();

          return (
            <TableContainer title={phrases.clients}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => {
                        return <StyledBoldCell key={cell.id}>{capitalizeWords(cell.value)}</StyledBoldCell>;
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
    </div>
  );
});

export default DeepDiveClient;
