import React, { useState, useEffect } from 'react';
import usePagination from 'src/utils/pagination';
import FilterMenu from 'src/utils/FilterMenu';
import { CreateClientParams, exportClients } from 'src/endpoints/clients';
import { useStores } from 'src/stores';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { RoutesEnum } from 'src/routes';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  DataTableSkeleton,
  TableToolbarMenu,
  TableToolbarAction,
} from '@carbon/react';
import { Add, Edit, TrashCan, DocumentExport } from '@carbon/react/icons';
import DeleteClientModal from './DeleteClientModal';
import UpdateClientModal from './UpdateClientModal';

const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;

const AllClients = observer(({ phrases }: any) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedClientParams, setSelectedClientParams] = useState<Partial<CreateClientParams>>({});
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState(null);
  const [filterFrom, setFilterFrom]:any = useState();
  const [filterTo, setFilterTo]:any = useState();
  const [leadsValidity, setLeadsValidity]:any = useState();
  const [approvalStatus, setApprovalStatus]:any = useState();
  const [paymentStatus, setPaymentStatus]:any = useState();
  const [meetingStatus, setMeetingStatus]:any = useState();
  const { clientsStore, authStore }: any = useStores();
  const navigate = useNavigate();
  const headersData = [
    { key: 'id', header: phrases.id },
    { key: 'partnerEmail', header: phrases.partner },
    { key: 'creationDate', header: phrases.date },
    { key: 'firstName', header: phrases.name },
    { key: 'surname', header: phrases.surname },
    { key: 'email', header: phrases.email_address },
    { key: 'phone', header: phrases.phone_number },
    { key: 'dateOfBirth', header: phrases.date_of_birth },
  ];
  useEffect(() => {
    clientsStore.getAll(0, 10);
  }, []);

  const formatDate = (date) => {
    const format = date.substring(0, 10).split('-').reverse().join('-');
    const event = new Date(format);
    return event.toLocaleString('en-GB', { year: 'numeric', day: 'numeric', month: 'long' });
  };

  const isAnyModalOpen = isDeleteModalOpen || isUpdateModalOpen;

  return (
    <div className="data-table">
      <DeleteClientModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        clientId={selectedClientParams}
        phrases={phrases}
      />
      <UpdateClientModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        clientAccountData={selectedClientParams}
        phrases={phrases}
      />
      {clientsStore.isLoading && <DataTableSkeleton columnCount={8} rowCount={pageSize} />}
      {Object.keys(clientsStore.data).length && (
        <DataTable headers={headersData} rows={clientsStore.data.content} isSortable isSelectable>
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getBatchActionProps,
          }) => {
            const batchActionProps = getBatchActionProps();

            return (
              <TableContainer title={phrases.all_clients}>
                <TableToolbar {...getToolbarProps()}>
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      onChange={(v) => {
                        setTimeout(() => {
                          setFilter(v.target.value);
                        }, 800);
                      }}
                      onClear={() => setFilter(null)}
                    />
                    <TableToolbarMenu tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0} renderIcon={DocumentExport}>
                      <TableToolbarAction onClick={() => exportClients('clients', filter)}>
                        Export CSV
                      </TableToolbarAction>
                    </TableToolbarMenu>
                    { authStore.userInfo.roles[0] !== 'ROLE_PARTNER' && authStore.userInfo.roles[0] !== 'ROLE_AGENT' && <FilterMenu setFilterFrom={setFilterFrom} setFilterTo={setFilterTo} setApprovalStatus={setApprovalStatus} setLeadsValidity={setLeadsValidity} comingFrom="clients" setPaymentStatus={setPaymentStatus} setMeetingStatus={setMeetingStatus} /> }
                    <Button
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      icon={Add}
                      onClick={() => navigate(RoutesEnum.ADD_NEW_LEAD, { replace: false })}
                    >
                      {phrases.add_a_client}
                    </Button>
                  </TableToolbarContent>
                </TableToolbar>
                {Object.keys(clientsStore.data).length && !clientsStore.isLoading && (
                  <Table {...getTableProps()}>
                    <TableHead style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                        ))}
                        <TableHeader />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.cells.map((cell) => {
                            switch (cell.info.header) {
                              case 'firstName':
                              case 'surname':
                                return (
                                  <TableCell
                                    onClick={() => navigate(`/all-clients/${row.id}`, { replace: false })}
                                    style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                                  >
                                    {cell.value}
                                  </TableCell>
                                );
                              case 'creationDate':
                              case 'dateOfBirth':
                                return (
                                  <TableCell key={cell.id} style={{ whiteSpace: 'nowrap' }}>{formatDate(cell.value)}</TableCell>
                                );
                              case 'id':
                                return (
                                  <TableCell key={cell.id} style={{ whiteSpace: 'nowrap' }}>{cell.value}</TableCell>
                                );
                              default:
                                return <TableCell key={cell.id} style={{ whiteSpace: 'nowrap' }}>{cell.value}</TableCell>;
                            }
                          })}
                          <StyledTableCell>
                            <TrashCan
                              onClick={() => {
                                setSelectedClientParams(clientsStore.data.content.find(({ id }) => row.id === id) || {});
                                setIsDeleteModalOpen(true);
                              }}
                            />
                            <Edit
                              onClick={() => {
                                const data = clientsStore.data.content.find(({ id }) => row.id === id) || {};
                                setSelectedClientParams(data);
                                setIsUpdateModalOpen(true);
                              }}
                            />
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            );
          }}
        </DataTable>
      )}
      {usePagination(phrases, clientsStore, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus, isAnyModalOpen)}
    </div>
  );
});

export default AllClients;
