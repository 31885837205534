import { createContext, useContext } from 'react';

import auth from './auth';
import internals from './internals';
import partners from './partners';
import agents from './agents';
import languages from './languages';
import clients from './clients';
import meetings from './meetings';
import products from './products';
import invoices from './invoices';
import mortgages from './mortgages';
import cashLoans from './cashLoans';
import reports from './reports';
import notifications from './notifications';
import cashOffers from './cashOffers';
import mortgageOffers from './mortgageOffers';

export const stores = {
  authStore: auth,
  internalsStore: internals,
  partnersStore: partners,
  agentsStore: agents,
  languagesStore: languages,
  clientsStore: clients,
  meetingsStore: meetings,
  productsStore: products,
  invoicesStore: invoices,
  mortgagesStore: mortgages,
  loansStore: cashLoans,
  reportsStore: reports,
  notificationsStore: notifications,
  cashOffersStore: cashOffers,
  mortgageOffersStore: mortgageOffers,
};

export const StoresContext = createContext(stores);

export const useStores = () => useContext(StoresContext);
