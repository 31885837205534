import { paginationHelperMethod } from 'src/utils/paginationRoute';
import axiosInstance from '.';

export const getAllInternals = async (pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'internalAccount', filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus);
};

export const getInternal = async (id: number): Promise<any> => {
  return axiosInstance.get(`/internalAccount/getById/${id}`).then((res) => res.data);
};

export const getBranchAccounts = async (): Promise<any> => {
  return axiosInstance.get('/internalAccount/getBranchAccounts').then((res) => res.data);
};

export interface InternalAccountParams {
  id: number;
  email: string;
  firstName: string;
  surname: string;
  accountType: string;
  jobTitle: string;
}

export const createInternal = async (params: Partial<InternalAccountParams>): Promise<any> => {
  return axiosInstance.post('/internalAccount', params);
};

export const updateInternal = async (id, params: Partial<InternalAccountParams>): Promise<any> => {
  return axiosInstance.put(`/internalAccount/${id}`, params);
};

export const deleteInternal = async (id: number): Promise<any> => {
  return axiosInstance.delete(`/internalAccount/${id}`);
};
