import React from 'react';
import { Modal } from '@carbon/react';
import { useStores } from 'src/stores';

const DeleteClientModal = ({ isOpen, setIsOpen, phrases, clientId }) => {
  const { clientsStore } = useStores();

  const onSubmit = async () => {
    await clientsStore.delete(clientId.id);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      size="sm"
      danger
      modalHeading={phrases.delete}
      primaryButtonText={phrases.delete}
      secondaryButtonText={phrases.cancel}
      hasScrollingContent
      onRequestClose={() => setIsOpen(false)}
      onRequestSubmit={onSubmit}
    >
      {(clientId.hasMeetings || clientId.hasPipelines) && (
        <p style={{ marginBottom: '10px' }}>This client has pipelines/meetings attached.</p>
      )}
      <p>{phrases.check_if_you_want_to_continue}</p>
    </Modal>
  );
};

export default DeleteClientModal;
