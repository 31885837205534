import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from '@carbon/react';
import { useStores } from 'src/stores';
import _ from 'lodash';

const SendForPaymentModal = observer(({ isOpen, setIsOpen, phrases, invoiceData }: any) => {
  const { invoicesStore } = useStores();
  const [data, setData]: any = useState();

  const onSubmit = async () => {
    await invoicesStore.send([data.id]);
    setIsOpen(false);
  };
  useEffect(() => {
    if (!_.isEmpty(invoiceData)) {
      setData(invoiceData);
    }
  }, [invoiceData]);
  const month = (date) => {
    const iso = new Date(date);
    return iso.toLocaleString('en-us', { month: 'long' });
  };
  const year = (date) => {
    const iso = new Date(date);
    return iso.toLocaleString('en-us', { year: 'numeric' });
  };
  return (
    <Modal
      open={isOpen}
      size="sm"
      //   modalHeading="Invoice data"
      primaryButtonText={phrases.send_for_payment}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      <span style={{ fontSize: '12px', lineHeight: '16px', letterSpacing: '0.32px' }}>Month</span>
      <h1 style={{ marginBottom: 16 }}>{data !== undefined && month(data.cells[2].value)}</h1>
      <div style={{ marginBottom: 16, letterSpacing: '0.16px' }}>
        <strong>Partner:</strong>
        <span style={{ marginBottom: 16 }}>{data !== undefined && data.cells[1].value}</span>
      </div>
      <div style={{ marginBottom: 16 }}>
        <strong>{phrases.year}:</strong>
        <span style={{ marginBottom: 16 }}>{data !== undefined && year(data.cells[2].value)}</span>
      </div>
      <strong>{phrases.document}:</strong>
      <span>{data !== undefined && data.cells[3].value}</span>
    </Modal>
  );
});

export default SendForPaymentModal;
