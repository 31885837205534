import React, { useState } from 'react';
import styled from 'styled-components';
import { useStores } from 'src/stores';
import { updateReportParams, CreateReportParams } from 'src/endpoints/reports';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Pagination,
  Dropdown,
} from '@carbon/react';
import { toJS } from 'mobx';

const meetingStatus = ['completed', 'not_attended', 'na', 'scheduled'];
const leadsValidityStatus = ['branch', 'other_agency', 'na', 'valid'];
const approvalStatus = [
  'application_completed',
  'documents_collection',
  'loan_approved',
  'loan_disbursed',
  'na',
];

const CustomModal = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: white;
  overflow-y: scroll;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const DropDownChanged = styled(Dropdown)`
  &.green {
    button {
      background-color: #defbe6;
      span {
        color: #24a148;
      }
    }
  }
  &.red {
    button {
      background-color: #fff1f1;
      span {
        color: #da1e28;
      }
    }
  }
  &.pink {
    button {
      background-color: #ffd6e8;
      span {
        color: #9f1853;
      }
    }
  }
  &.blue {
    button {
      background-color: #d0e2ff;
      span {
        color: #0043ce;
      }
    }
  }
  &.light-blue {
    button {
      background-color: #bae6ff;
      span {
        color: #00539a;
      }
    }
  }
  &.strong-green {
    button {
      background-color: #9ef0f0;
      span {
        color: #005d5d;
      }
    }
  }
`;

const StatusIndicator = styled.div`
  width: max-content;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 24px;
  color: ${({ color }) => color};
  background-color: ${({ bg }) => bg};
`;

export interface ChangedItems {
  id?: number;
  meetingStatus?: string;
  approvalStatus?: string;
  leadsValidity?: string;
}

const CreateFinallReportModal = ({
  setIsOpen,
  controlCreateReport,
  phrases,
  previewReport,
  createReport,
}) => {
  const { reportsStore } = useStores();
  const [changedItems, setChangedItems] = useState<Partial<Array<ChangedItems>>>([]);

  const headersData = [
    { key: 'id', header: 'ID' },
    { key: 'firstName', header: phrases.partner },
    { key: 'date', header: phrases.date },
    { key: 'status', header: phrases.status },
    { key: 'firstName', header: phrases.name },
    { key: 'surname', header: phrases.surname },
    { key: 'email', header: phrases.email_address },
    { key: 'phone', header: phrases.phone_number },
    { key: 'dateOfBirth', header: phrases.date_of_birth },
    { key: 'productType', header: phrases.loan_type },
    { key: 'amount', header: phrases.loan_amount },
    { key: 'meetingStatus', header: phrases.meeting_status },
    { key: 'approvalStatus', header: phrases.approval_status },
    { key: 'leadsValidity', header: phrases.leads_validity },
  ];

  const formatDate = (date) => {
    const format = date.substring(0, 10).split('-').reverse().join('-');
    const event = new Date(format);
    return event.toLocaleString('en-us', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const updateReport = (params) => {
    reportsStore.updateReport(params as updateReportParams, () => {
      checkedSelectedItem(params);
    });
  };

  const onSubmit = () => {
    reportsStore.createReport(createReport as CreateReportParams, () => {
      setIsOpen(false);
      controlCreateReport(false);
    });
  };

  const checkedSelectedItem = (params) => {
    if (changedItems.length > 0) {
      let isExist = false;

      const updatedList = changedItems.map((item) => {
        if (item && item.id === params.id) {
          isExist = true;
          if (params.meetingStatus) {
            return { ...item, meetingStatus: params.meetingStatus };
          }
          if (params.approvalStatus) {
            return { ...item, approvalStatus: params.approvalStatus };
          }
          if (params.leadsValidity) {
            return { ...item, leadsValidity: params.leadsValidity };
          }
        }

        return item;
      });

      if (!isExist) {
        updatedList.push(params);
      }

      setChangedItems(updatedList as Array<ChangedItems>);
    } else {
      setChangedItems((items) => [...items, { ...params }]);
    }
  };

  const capitalizeWords = (string) => {
    if (typeof string === 'string') {
      string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
      string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
      string = string.replace(/_/g, ' ');
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  };

  return (
    <CustomModal>
      <DataTable headers={headersData} rows={previewReport} isSortable isSelectable>
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getToolbarProps,
          getBatchActionProps,
        }) => {
          const batchActionProps = getBatchActionProps();

          return (
            <TableContainer title={phrases.monthly_reports}>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                  <TableToolbarSearch
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    onChange={(v) => console.log(v)}
                  />

                  <Button
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    // onClick={() => setIsOpen(false)}
                    onClick={() => onSubmit()}
                  >
                    {phrases.complete}
                  </Button>
                  {/* <ExitButton
                    kind="danger"
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    onClick={() => setIsOpen(false)}
                  >
                    Exit
                  </ExitButton> */}
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => {
                        switch (cell.info.header) {
                          case 'date':
                            return <TableCell key={cell.id}>{formatDate(cell.value)}</TableCell>;
                          case 'dateOfBirth':
                            return <TableCell key={cell.id}>{formatDate(cell.value)}</TableCell>;
                          case 'meetingStatus':
                            return (
                              <TableCell key={cell.id} style={{ whiteSpace: 'nowrap', minWidth: '150px' }}>
                                <DropDownChanged
                                  label=""
                                  style={{ minWidth: '150px' }}
                                  className={changedItems.map((item) => item && item.id === row.id
                                    ? item.meetingStatus === 'completed'
                                      ? 'green'
                                      : item?.meetingStatus === 'not_attended'
                                          || item?.meetingStatus === 'na'
                                        ? 'red'
                                        : item?.meetingStatus === 'scheduled'
                                          ? 'blue'
                                          : ''
                                    : '')}
                                  initialSelectedItem={capitalizeWords(meetingStatus.find(
                                    (item) => item === cell.value,
                                  ))}
                                  items={meetingStatus.map((item) => { return { value: item, label: capitalizeWords(item) }; })}
                                  onChange={({ selectedItem }) => {
                                    updateReport({
                                      id: row.id,
                                      meetingStatus: selectedItem.value,
                                    });
                                  }}
                                />
                              </TableCell>
                            );
                          case 'approvalStatus':
                            return (
                              <TableCell key={cell.id}>
                                <DropDownChanged
                                  label=""
                                  className={changedItems.map((item) => item && item.id === row.id
                                    ? item.approvalStatus === 'documents_collection'
                                        || item.approvalStatus === 'application_completed'
                                        || item.approvalStatus === 'loan_approved'
                                        || item.approvalStatus === 'loan_disbursed'
                                        || item.approvalStatus === 'in_progress'
                                      ? 'green'
                                      : item?.approvalStatus === 'not_attended'
                                         || item?.approvalStatus === 'na'
                                        ? 'red'
                                        : ''
                                    : '')}
                                  initialSelectedItem={capitalizeWords(approvalStatus.find(
                                    (item) => item === cell.value,
                                  ))}
                                  items={approvalStatus.map((item) => { return { value: item, label: capitalizeWords(item) }; })}
                                  onChange={({ selectedItem }) => {
                                    updateReport({
                                      id: row.id,
                                      approvalStatus: selectedItem.value,
                                    });
                                  }}
                                />
                              </TableCell>
                            );
                          case 'leadsValidity':
                            return (
                              <TableCell key={cell.id}>
                                <DropDownChanged
                                  label=""
                                  className={changedItems.map((item) => item && item.id === row.id
                                    ? item.leadsValidity === 'valid'
                                      ? 'green'
                                      : item?.leadsValidity === 'other_agency'
                                          || item?.leadsValidity === 'branch'
                                        ? ' blue'
                                        : item?.leadsValidity === 'not_attended'
                                         || item?.leadsValidity === 'na'
                                          ? 'red'
                                          : ''
                                    : '')}
                                  initialSelectedItem={capitalizeWords(leadsValidityStatus.find(
                                    (item) => item === cell.value,
                                  ))}
                                  items={leadsValidityStatus.map((item) => { return { value: item, label: capitalizeWords(item) }; })}
                                  onChange={({ selectedItem }) => {
                                    updateReport({
                                      id: row.id,
                                      leadsValidity: selectedItem.value,
                                    });
                                  }}
                                />
                              </TableCell>
                            );
                          case 'status':
                            return (
                              <TableCell>
                                {cell.value === 'unchanged' ? (
                                  <StatusIndicator color="#DA1E28" bg="#FFF1F1">
                                    Unchanged
                                  </StatusIndicator>
                                ) : (
                                  <StatusIndicator color="#0E6027" bg="#A7F0BA">
                                    Changed
                                  </StatusIndicator>
                                )}
                              </TableCell>
                            );
                          default:
                            return <TableCell key={cell.id}>{capitalizeWords(cell.value)}</TableCell>;
                        }
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      <Pagination
        className="data-table__pagination"
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText={phrases.items_per_page}
        page={1}
        pageNumberText="Page Number"
        pageSize={10}
        pageSizes={[10, 20, 30, 40, 50]}
        totalItems={103}
      />
    </CustomModal>
  );
};

export default CreateFinallReportModal;
