import axiosInstance from 'src/endpoints';

export const paginationHelperMethod = (pageNo, pageSize, route, searchString, from, to, leadsValidity, approvalStatus, paymentStatus, meetingStatus) => {
  const params = {
    pageNo, pageSize, from, to, searchString, leadsValidity, approvalStatus, paymentStatus, meetingStatus,
  };
  if (!searchString) {
    params.searchString = null;
  }
  return axiosInstance.get(`/${route}`, { params }).then((res) => res.data);
};
