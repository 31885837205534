import React, { useState } from 'react';
import { useStores } from 'src/stores';
import { OverflowMenu, Button, DatePicker, DatePickerInput, Dropdown } from '@carbon/react';
import { Filter } from '@carbon/react/icons';

const FilterMenu = ({
  setFilterFrom,
  setFilterTo,
  comingFrom,
  setLeadsValidity,
  setApprovalStatus,
  setPaymentStatus,
  setMeetingStatus,
}) => {
  const { authStore }:any = useStores();
  const [internalFilterFrom, setInternalFilterFrom]: any = useState(null);
  const [internalFilterTo, setInternalFilterTo]: any = useState(null);
  const [internalLeadsValidity, setInternalLeadsValidity]: any = useState(null);
  const [internalApprovalStatus, setInternalApprovalStatus]: any = useState(null);
  const [internalPaymentStatus, setInternalPaymentStatus]: any = useState(null);
  const [internalMeetingStatus, setInternalMeetingStatus]: any = useState(null);
  return (
    <OverflowMenu
      className="bx-filter-menu"
      flipped
      renderIcon={Filter}
      ariaLabel="filter-menu"
    >
      <div className="overflow-menu-sections">
        {(comingFrom === 'clients'
           || comingFrom === 'loans'
           || comingFrom === 'meetings'
           || comingFrom === 'reports'
           || comingFrom === 'invoices') && (
           <div className="overflow-menu-section">
             <DatePicker dateFormat="d/m/Y" datePickerType="simple">
               <DatePickerInput
                 datePickerType="range"
                 type="string"
                 id="date-picker-default-2"
                 placeholder="dd/mm/yyyy"
                 labelText="From"
                 onChange={(value) => {
                   const data = new Date(value.target.value.split('/').reverse().join(','));
                   const format = data.toLocaleDateString('en-GB').split('/').join('-');
                   comingFrom === 'loans' || comingFrom === 'invoices'
                     ? setInternalFilterFrom(format)
                     : setInternalFilterFrom(`${format}T00:00:00`);
                 }}
               />
             </DatePicker>
           </div>
        )}
        {(comingFrom === 'clients'
           || comingFrom === 'loans'
           || comingFrom === 'meetings'
           || comingFrom === 'reports'
           || comingFrom === 'invoices') && (
           <div className="overflow-menu-section">
             <DatePicker dateFormat="d/m/Y" datePickerType="simple" short>
               <DatePickerInput
                 datePickerType="range"
                 type="string"
                 id="date-picker-default-2"
                 placeholder="dd/mm/yyyy"
                 labelText="To"
                 onChange={(value) => {
                   const data = new Date(value.target.value.split('/').reverse().join(','));
                   const format = data.toLocaleDateString('en-GB').split('/').join('-');
                   comingFrom === 'loans' || comingFrom === 'invoices'
                     ? setInternalFilterTo(format)
                     : setInternalFilterTo(`${format}T23:59:59`);
                 }}
               />
             </DatePicker>
           </div>
        )}
        {comingFrom === 'loans' && authStore.userInfo.roles[0] !== 'ROLE_BRANCH' && (
          <div className="overflow-menu-section">
            <Dropdown
              label="Select"
              titleText="Leads validity"
              items={[
                { id: 'NA', label: 'Na' },
                { id: 'VALID', label: 'Valid' },
                { id: 'OTHER_AGENCY', label: 'Other agency' },
                { id: 'BRANCH', label: 'Branch' },
              ]}
              onChange={({ selectedItem: { id } }) => setInternalLeadsValidity(id)}
            />
          </div>
        )}
        {comingFrom === 'loans' && (
          <div className="overflow-menu-section">
            <Dropdown
              label="Select"
              titleText="Approval Status"
              items={[
                { id: 'NA', label: 'Na' },
                { id: 'DOCUMENTS_COLLECTION', label: 'Documents collection' },
                { id: 'IN_PROGRESS', label: 'In progress' },
                { id: 'APPLICATION_COMPLETED', label: 'Application completed' },
                { id: 'LOAN_APPROVED', label: 'Loan approved' },
                { id: 'LOAN_DISBURSED', label: 'Loan disbursed' },
                { id: 'REJECTED', label: 'Rejected' },
              ]}
              onChange={({ selectedItem: { id } }) => setInternalApprovalStatus(id)}
            />
          </div>
        )}
        {comingFrom === 'invoices' && (
          <div className="overflow-menu-section">
            <Dropdown
              label="Select"
              titleText="Payment Status"
              items={[
                { id: 'UPLOADED', label: 'Uploaded' },
                { id: 'WAITING', label: 'Waiting' },
                { id: 'PAID', label: 'Paid' },
              ]}
              onChange={({ selectedItem: { id } }) => setInternalPaymentStatus(id)}
            />
          </div>
        )}
        {comingFrom === 'meetings' && (
          <div className="overflow-menu-section">
            <Dropdown
              label="Select"
              titleText="Meeting status"
              items={[
                { id: 'NA', label: 'Na' },
                { id: 'SCHEDULED', label: 'Scheduled' },
                { id: 'NOT_ATTENDED', label: 'Not attended' },
                { id: 'COMPLETED', label: 'Completed' },
              ]}
              onChange={({ selectedItem: { id } }) => setInternalMeetingStatus(id)}
            />
          </div>
        )}
      </div>
      <footer className="bx-overflow-menu-cta">
        <Button
          className="bx-btn"
          onClick={() => {
            setFilterFrom(null);
            setFilterTo(null);
            comingFrom === 'loans' && setApprovalStatus(null);
            comingFrom === 'loans' && setLeadsValidity(null);
            comingFrom === 'meetings' && setMeetingStatus(null);
            comingFrom === 'invoices' && setPaymentStatus(null);
          }}
        >
          Reset Filters
        </Button>
        <Button
          className="bx-btn"
          onClick={() => {
            setFilterFrom(internalFilterFrom);
            setFilterTo(internalFilterTo);
            comingFrom === 'loans' && setApprovalStatus(internalApprovalStatus);
            comingFrom === 'loans' && setLeadsValidity(internalLeadsValidity);
            comingFrom === 'meetings' && setMeetingStatus(internalMeetingStatus);
            comingFrom === 'invoices' && setPaymentStatus(internalPaymentStatus);
          }}
        >
          Apply filters
        </Button>
      </footer>
    </OverflowMenu>
  );
};

export default FilterMenu;
