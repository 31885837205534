import { makeObservable, observable, action } from 'mobx';
import { getAllMeetings, createMeeting, getMeetingById, updateMeeting, deleteMeeting, updateMeetingStatus } from 'src/endpoints/meetings';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class MeetingsStore {
  data = [];

  isLoading = false;

  dataById = [];

  constructor() {
    makeObservable(this, { data: observable, isLoading: observable, getAll: action, dataById: observable });
  }

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus) => {
    this.isLoading = true;

    const content = await getAllMeetings(pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus);
    this.data = content;

    this.isLoading = false;
  };

  getById = async (id) => {
    this.isLoading = true;

    const content = await getMeetingById(id);
    this.dataById = content;

    this.isLoading = false;
  };

  create = async (data) => {
    this.isLoading = true;
    try {
      await createMeeting(data);
      genericSuccessHandler('Meeting added successfully!');
      this.getAll(0, 10, '', '', '', null, null, null, null);
      this.isLoading = false;
    } catch (error) {
      genericErrorHandler(error);
      this.isLoading = false;
    }
  };

  update = async (id, updateData) => {
    try {
      await updateMeeting(id, updateData);
      genericSuccessHandler('Meeting updated successfully!');
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  updateStatus = async (id, meetUpdateData) => {
    try {
      await updateMeetingStatus(id, meetUpdateData);
      genericSuccessHandler('Meeting updated successfully!');
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  delete = async (id: number) => {
    try {
      await deleteMeeting(id);
      genericSuccessHandler('Meeting deleted successfully!');
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}

export default new MeetingsStore();
