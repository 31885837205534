import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import {
  Header as CarbonHeader,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  Switcher,
  SwitcherItem,
  SkipToContent,
  SideNav,
  Dropdown,
  Theme,
} from '@carbon/react';
import { User, Close } from '@carbon/react/icons';
import { useStores } from 'src/stores';
import { SideNavItems } from '../SideNav';

type ActiveRightPanelTab = 'search' | 'notifications' | 'profile';

const StyledDropdown = styled.div`
  @media screen and (min-width: 600px) {
    width: 200px;
  }
`;

const Header = observer(() => {
  const { authStore } = useStores();
  const { languagesStore } = useStores();
  const [activeRightPanelTab, setActiveRightPanelTab] = useState<ActiveRightPanelTab | null>(null);
  const [languageData, setLanguageData] = useState('');
  const [languageIso, setLanguageIso] = useState([]);

  useEffect(() => {
    setLanguageIso(languagesStore.allIso);
  }, [languagesStore.allIso]);

  useEffect(() => {
    languageData ? languagesStore.getLanguage(languageData) : null;
  }, [languageData]);

  interface iso3 {
    id: number;
    iso3: string;
  }

  return (
    <Theme theme="g100">
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <CarbonHeader aria-label="Finbox">
            <SkipToContent />
            <HeaderMenuButton
              aria-label="Open menu"
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
            />
            <HeaderName element={Link} to="/all-clients">
              Bank logo
            </HeaderName>
            <SideNav aria-label="Side navigation" expanded={isSideNavExpanded} isPersistent={false}>
              <SideNavItems />
            </SideNav>
            <HeaderGlobalBar>
              <StyledDropdown>
                <Dropdown
                  id="dropdown"
                  style={{ marginTop: 5 }}
                  label={languageData || 'EN'}
                  items={languageIso.map((iso: iso3, index: number) => {
                    return { id: index, label: iso.iso3 };
                  })}
                  value={languageData || 'GBR'}
                  onChange={({ selectedItem: { label } }) => setLanguageData(label)}
                />
              </StyledDropdown>
              {/* <HeaderGlobalAction
                aria-label="Action"
                isActive={activeRightPanelTab === 'search'}
                // onClick={() => setActiveRightPanelTab(activeRightPanelTab === 'search' ? null : 'search')}
              >
                {activeRightPanelTab === 'search' ? <Close /> : <Search />}
              </HeaderGlobalAction> */}
              {/* <HeaderGlobalAction
                aria-label="Action"
                isActive={activeRightPanelTab === 'notifications'}
                // onClick={() => setActiveRightPanelTab(
                //   activeRightPanelTab === 'notifications' ? null : 'notifications',
                // )}
              >
                {activeRightPanelTab === 'notifications' ? <Close /> : <Notification />}
              </HeaderGlobalAction> */}
              {authStore.isAuthenticated && (
                <HeaderGlobalAction
                  aria-label="Profile"
                  isActive={activeRightPanelTab === 'profile'}
                  onClick={() => setActiveRightPanelTab(activeRightPanelTab === 'profile' ? null : 'profile')}
                >
                  {activeRightPanelTab === 'profile' ? <Close /> : <User />}
                </HeaderGlobalAction>
              )}
            </HeaderGlobalBar>
            <HeaderPanel expanded={!!activeRightPanelTab} aria-label="Header-panel">
              <Switcher aria-label="Logout-wrapper">
                <SwitcherItem onClick={authStore.logout} aria-label="Icon-logout">Logout</SwitcherItem>
              </Switcher>
            </HeaderPanel>
          </CarbonHeader>
        )}
      />
    </Theme>
  );
});

export default Header;
