import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { Modal, Dropdown, TextInput } from '@carbon/react';
import { InternalAccountParams } from 'src/endpoints/internals';
import { useStores } from 'src/stores';

const StyledRow = styled(Row)`
  margin-bottom: 32px;
`;

interface Errors {
  accountType?: string;
  firstName?: string;
  surname?: string;
  jobTitle?: string;
  email?: string;
  invalid?: boolean;
  invalidText?: string;
}

const CreateModal = ({ isOpen, setIsOpen, phrases }) => {
  const { internalsStore } = useStores();
  const [data, setData] = useState<Partial<InternalAccountParams>>({
    accountType: '',
    firstName: '',
    surname: '',
    jobTitle: '',
    email: '',
  });
  const [errors, setErrors] = useState<Errors>({});

  const onSubmit = async () => {
    const isValid = validateFields();
    if (isValid) {
      await internalsStore.create(data as InternalAccountParams);
      setIsOpen(false);
    }
  };

  const validateFields = () => {
    const newErrors = { accountType: '', firstName: '', surname: '', jobTitle: '', email: '' };
    let hasErrors = false;
    if (!data.accountType) {
      newErrors.accountType = 'Account type is required';
      hasErrors = true;
    }
    if (!data.firstName) {
      newErrors.firstName = 'First name is required';
      hasErrors = true;
    }
    if (!data.surname) {
      newErrors.surname = 'Surname is required';
      hasErrors = true;
    }
    if (!data.jobTitle) {
      newErrors.jobTitle = 'Job title is required';
      hasErrors = true;
    }
    if (!data.email) {
      newErrors.email = 'Email is required';
      hasErrors = true;
    }
    setErrors(newErrors);
    if (!hasErrors) {
      return true;
    }
    setTimeout(() => {
      setErrors({});
      hasErrors = false;
    }, 3000);
    return false;
  };

  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.create_internal_accounts}
      primaryButtonText={phrases.create_accounts}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      <StyledRow>
        <Col>
          <Dropdown
            label={phrases.select}
            titleText={phrases.type}
            items={[
              { id: 'ADMIN', label: 'Admin' },
              { id: 'ACCOUNT_EXECUTIVE', label: 'Account executive' },
              { id: 'BRANCH', label: 'Branch' },
            ]}
            onChange={({ selectedItem: { id } }) => setData({ ...data, accountType: id })}
            invalid={!!errors.accountType}
            invalidText={errors.accountType}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.name}
            placeholder={phrases.enter_name}
            onChange={({ target: { value } }) => setData({ ...data, firstName: value })}
            invalid={!!errors.firstName}
            invalidText={errors.firstName}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.surname}
            placeholder={phrases.enter_surname}
            onChange={({ target: { value } }) => setData({ ...data, surname: value })}
            invalid={!!errors.surname}
            invalidText={errors.surname}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.official_job_title}
            placeholder={phrases.enter_role}
            onChange={({ target: { value } }) => setData({ ...data, jobTitle: value })}
            invalid={!!errors.jobTitle}
            invalidText={errors.jobTitle}
          />
        </Col>
      </StyledRow>
      <Row>
        <Col>
          <TextInput
            type="email"
            labelText={phrases.email_address}
            placeholder={phrases.enter_email}
            onChange={({ target: { value } }) => setData({ ...data, email: value })}
            invalid={!!errors.email}
            invalidText={errors.email}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateModal;
