import { makeObservable, observable, action } from 'mobx';
import {
  getAllPartners,
  createPartner,
  updatePartner,
  deletePartner,
  PartnerAccountParams,
  AddProductParams,
  addProduct,
} from 'src/endpoints/partners';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class PartnersStore {
  data = [];

  creationData = [];

  isLoading = false;

  constructor() {
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      creationData: observable,
      getAll: action,
      create: action,
      delete: action,
    });
  }

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus) => {
    this.isLoading = true;

    const content = await getAllPartners(pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus);
    this.data = content;

    this.isLoading = false;
  };

  addProducts = async (partnerId: number, data: AddProductParams) => {
    try {
      await addProduct(partnerId, data);
      // this.getAll();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  create = async (data: PartnerAccountParams) => {
    try {
      const content = await createPartner(data);
      this.creationData = content;
      genericSuccessHandler('Partner created successfully!');
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  update = async (id, data: PartnerAccountParams) => {
    try {
      await updatePartner(id, data);
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  delete = async (id: number) => {
    try {
      await deletePartner(id);
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}

export default new PartnersStore();
