import axios from 'axios';
import { getRefreshToken } from './auth';

const apiUrl = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({ baseURL: apiUrl });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');

    if (token && !config.headers!.Authorization) {
      config.headers!.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => Promise.reject(err),
);

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalReq = err.config;

    if (err.response.status === 403 && !originalReq._retry) {
      originalReq._retry = true;

      const refreshToken = localStorage.getItem('refresh_token') || '';
      const newAccessToken = await getRefreshToken({ refreshToken });

      originalReq.headers.Authorization = `Bearer ${newAccessToken}`;

      return axiosInstance(originalReq);
    }

    if (err.response?.status === 401) {
      window.location.replace('/login');
    }
    // TODO: handle errors
    console.error(err?.response?.data?.message);
    return Promise.reject(err);
  },
);

export default axiosInstance;
