import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useStores } from 'src/stores';
import { CreateReportParams } from 'src/endpoints/reports';
import {
  Modal,
  Dropdown,
  Grid,
  Column,
  DatePicker,
  DatePickerInput,
  TextInput,
} from '@carbon/react';
import CreateFinallReportModal from './CreateFinallReportModal';

const CreateReportModal = observer(({ isOpen, setIsOpen, phrases, partners }: any) => {
  const { reportsStore, partnersStore } = useStores();
  const [createReport, setCreateReport] = useState<Partial<CreateReportParams>>({});
  const [isFinallStepOpen, setIsFinallStepOpen] = useState<Partial<boolean>>(false);

  const onSubmit = () => {
    // reportsStore.createReport(createReport as CreateReportParams, () => setIsOpen(false));
    reportsStore.allPreviewReports(createReport as CreateReportParams, () => {
      setIsFinallStepOpen(true);
    });
  };

  return (
    <>
      {isFinallStepOpen && (
        <CreateFinallReportModal
          setIsOpen={setIsFinallStepOpen}
          controlCreateReport={setIsOpen}
          phrases={phrases}
          previewReport={reportsStore.previewReport}
          createReport={createReport}
        />
      )}
      <Modal
        open={isOpen}
        size="md"
        modalHeading={phrases.create_monthly}
        primaryButtonText={phrases.create}
        secondaryButtonText={phrases.cancel}
        hasScrollingContent
        onRequestSubmit={onSubmit}
        onRequestClose={() => setIsOpen(false)}
      >
        <Grid style={{ padding: 0 }}>
          <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
            <DatePicker
              dateFormat="d/m/Y"
              datePickerType="single"
              onChange={(dates: Array<string>) => {
                const date = new Date(dates[0]);
                const format = date.toISOString().slice(0, -14);
                const format2 = format.split('-').reverse().join('-');
                setCreateReport({ ...createReport, timeFrom: format2 });
              }}
            >
              <DatePickerInput
                datePickerType="range"
                type="string"
                id="date-picker-default-2"
                placeholder="dd-mm-yyyy"
                labelText="Date from"
                onChange={(dates: Array<string>) => {
                  const date = new Date(dates[0]);
                  const format = date.toISOString().slice(0, -14);
                  const format2 = format.split('-').reverse().join('-');
                  setCreateReport({ ...createReport, timeFrom: format2 });
                }}
              />
            </DatePicker>
          </Column>
          <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
            <DatePicker
              dateFormat="d/m/Y"
              datePickerType="single"
              onChange={(dates: Array<string>) => {
                const date = new Date(dates[0]);
                const format = date.toISOString().slice(0, -14);
                const format2 = format.split('-').reverse().join('-');
                setCreateReport({ ...createReport, timeTo: format2 });
              }}
            >
              <DatePickerInput
                datePickerType="range"
                type="string"
                id="date-picker-default-2"
                placeholder="dd-mm-yyyy"
                labelText="Date to"
                onChange={(dates: Array<string>) => {
                  const date = new Date(dates[0]);
                  const format = date.toISOString().slice(0, -14);
                  const format2 = format.split('-').reverse().join('-');
                  setCreateReport({ ...createReport, timeTo: format2 });
                }}
              />
            </DatePicker>
          </Column>
          <Column lg={8} md={4} sm={2} style={{ marginLeft: 0, marginBottom: 24 }}>
            <TextInput
              type="text"
              labelText={phrases.report_name}
              placeholder={phrases.report_name}
              onChange={({ target: { value } }) => setCreateReport({ ...createReport, reportName: value })}
            />
          </Column>
          <Column lg={8} md={4} sm={2} style={{ marginRight: 0, marginBottom: 24 }}>
            <Dropdown
              label={phrases.select}
              titleText={phrases.product}
              items={[
                { id: '1', label: phrases.cash_loan, name: 'cash_loan', value: 1 },
                { id: '2', label: phrases.mortgage, name: 'mortgage', value: 2 },
              ]}
              onChange={({ selectedItem: { id } }) => setCreateReport({ ...createReport, products: [Number(...id)] })}
            />
          </Column>
          {Object.keys(partnersStore.data).length && (
            <Column lg={16} md={8} sm={4} style={{ margin: 0, height: '150px' }}>
              <Dropdown
                label={phrases.select}
                titleText={phrases.partner}
                items={partners.map((item) => {
                  return { id: item.id, label: item.firstName, name: item.firstName };
                })}
                onChange={({ selectedItem: { id } }) => setCreateReport({ ...createReport, partnerId: Number(id) })}
              />
            </Column>
          )}
        </Grid>
      </Modal>
    </>
  );
});

export default CreateReportModal;
