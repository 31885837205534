import { paginationHelperMethod } from 'src/utils/paginationRoute';
import axiosInstance from '.';

export const getAllMeetings = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'meeting', filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
};

export interface MeetingParams {
  id: number;
  clientId: string;
  branchId: number;
  meetingId: number;
  meetingDate1: string;
  meetingDate2: string;
  meetingDate3: string;
  meetingTime1: string;
  meetingTime2: string;
  meetingTime3: string;
  meetingOption1: string;
  meetingOption2: string;
  meetingOption3: string;
  email: string;
  firstName: string;
  surname: string;
  password: string;
  jobTitle: string;
  accountType: string;
  pipelineId: number;
  status: string;
}

export const createMeeting = async (data): Promise<any> => {
  const meetingOption1 = (data.meetingDate1 && data.meetingTime1) && `${data.meetingDate1}T${data.meetingTime1}:00`;
  const meetingOption2 = (data.meetingDate2 && data.meetingTime2) && `${data.meetingDate2}T${data.meetingTime2}:00`;
  const meetingOption3 = (data.meetingDate3 && data.meetingTime3) && `${data.meetingDate3}T${data.meetingTime3}:00`;
  return axiosInstance.post('/meeting/saveMeeting/', {
    clientId: data.clientId,
    branchId: data.branchId,
    meetingOption1,
    meetingOption2,
    meetingOption3,
    pipelineId: data.pipelineId,
  });
};
export const updateMeeting = async (meetingId, updateData): Promise<any> => {
  const meetingOption1 = (updateData.meetingDate1 && updateData.meetingTime1) && `${updateData.meetingDate1}T${updateData.meetingTime1}:00`;
  const meetingOption2 = (updateData.meetingDate2 && updateData.meetingTime2) && `${updateData.meetingDate2}T${updateData.meetingTime2}:00`;
  const meetingOption3 = (updateData.meetingDate3 && updateData.meetingTime3) && `${updateData.meetingDate3}T${updateData.meetingTime3}:00`;
  return axiosInstance.put(`/meeting/${meetingId}`, {
    status: updateData.status,
    meetingOption1,
    meetingOption2,
    meetingOption3,
  });
};
export const updateMeetingStatus = async (id, status): Promise<any> => {
  return axiosInstance.put(`/meeting/${id}`, {
    status: status.status,
  });
};
export const deleteMeeting = async (id: number): Promise<any> => {
  return axiosInstance.delete(`/meeting/${id}`);
};

export const getMeetingById = async (id: number): Promise<any> => {
  return axiosInstance.get(`/meeting/getById/${id}`).then((res) => res.data);
};
