import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStores } from 'src/stores';
import usePagination from 'src/utils/pagination';
import FilterMenu from 'src/utils/FilterMenu';
import { downloadFile } from 'src/endpoints/invoices';
import { observer } from 'mobx-react-lite';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  DataTableSkeleton,
} from '@carbon/react';
import { DocumentPdf, Add, TrashCan } from '@carbon/react/icons';
import CreateNewOfferModal from './CreateNewOfferModal';
import CreateNewInvoice from './CreateNewInvoice';
import DeleteInvoiceModal from './DeleteInvoiceModal';
import SendForPaymentModal from './SendForPaymentModal';

const headersData = [
  { key: 'id', header: 'ID' },
  { key: 'partnerEmail', header: 'Partner' },
  { key: 'month', header: 'Year' },
  { key: 'document', header: 'Document' },
  { key: 'status', header: 'Payment status' },
];

const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > span {
    color: #0062fe;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const StyledButton = styled.div`
  left: 0;
  button {
    border: none;
    background-color: inherit;
    outline: none;
    cursor: pointer;
    color: #0f62fe;
    text-decoration: underline;
  }
`;
const StyledEditCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;

const StatusIndicator = styled.div`
  width: max-content;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 24px;
  color: ${({ color }) => color};
  background-color: ${({ bg }) => bg};
`;

const MonthlyInvoices = observer(({ phrases }: any) => {
  const { invoicesStore, authStore }: any = useStores();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedInvoiceParam, setSelectedInvoiceParam] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState('');
  const [filterFrom, setFilterFrom]:any = useState();
  const [filterTo, setFilterTo]:any = useState();
  const [leadsValidity, setLeadsValidity]:any = useState();
  const [approvalStatus, setApprovalStatus]:any = useState();
  const [paymentStatus, setPaymentStatus]:any = useState();
  const [meetingStatus, setMeetingStatus]:any = useState();

  useEffect(() => {
    invoicesStore.getAll();
  }, [invoicesStore]);

  const formatDate = (date) => {
    const format = date.substring(0, 10).split('-').reverse().join('-');
    const event = new Date(format);
    return event.toLocaleString('en-us', { year: 'numeric', month: 'long' });
  };

  const isAnyModalOpen = isPaymentModalOpen || isDeleteModalOpen || isInvoiceModalOpen || isModalOpen;

  return (
    <div className="data-table">
      <SendForPaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        phrases={phrases}
        invoiceData={selectedInvoiceParam}
      />
      <CreateNewOfferModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} phrases={phrases} />
      <DeleteInvoiceModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        phrases={phrases}
        invoiceData={selectedInvoiceParam}
      />
      <CreateNewInvoice
        isOpen={isInvoiceModalOpen}
        setIsOpen={setIsInvoiceModalOpen}
        phrases={phrases}
      />
      {invoicesStore.isLoading && <DataTableSkeleton columnCount={4} rowCount={pageSize} />}
      {Object.keys(invoicesStore.data).length && (
        <DataTable headers={headersData} rows={invoicesStore.data.content} isSortable>
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getBatchActionProps,
          }) => {
            const batchActionProps = getBatchActionProps();

            return (
              <TableContainer title={phrases.monthly_invoices}>
                <TableToolbar {...getToolbarProps()}>
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      onChange={(v) => {
                        setTimeout(() => {
                          setFilter(v.target.value);
                        }, 800);
                      }}
                      onClear={() => setFilter('')}
                    />
                    <FilterMenu setFilterFrom={setFilterFrom} setFilterTo={setFilterTo} comingFrom="invoices" setApprovalStatus={setApprovalStatus} setLeadsValidity={setLeadsValidity} setPaymentStatus={setPaymentStatus} setMeetingStatus={setMeetingStatus} />
                    {(authStore.userInfo.roles[0] === 'ROLE_PARTNER' || authStore.userInfo.roles[0] === 'ROLE_SUPER_ADMIN' || authStore.userInfo.roles[0] === 'ROLE_ACCOUNT_EXECUTIVE') && (
                      <Button
                        tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                        renderIcon={Add}
                        onClick={() => setIsInvoiceModalOpen(true)}
                      >
                        {phrases.add_new_invoice}
                      </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>
                {!invoicesStore.isLoading && (
                  <Table {...getTableProps()}>
                    <TableHead style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                        ))}
                        <TableHeader />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.cells.map((cell) => {
                            switch (cell.info.header) {
                              case 'document':
                                return (
                                  <TableCell key={cell.id}>
                                    {cell.value && (
                                      <>
                                        <DocumentPdf />
                                        <span onClick={() => downloadFile(row.id)} style={{ paddingLeft: '10px' }}>
                                          {cell.value}
                                        </span>
                                      </>
                                    )}
                                  </TableCell>
                                );
                              case 'status':
                                return (
                                  <TableCell key={cell.id}>
                                    {(() => {
                                      switch (cell.value) {
                                        case 'UPLOADED':
                                          return (
                                            <StyledButton>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  (authStore.userInfo.roles[0]
                                                     === 'ROLE_SUPER_ADMIN'
                                                     || authStore.userInfo.roles[0]
                                                      === 'ROLE_ACCOUNT_EXECUTIVE' || authStore.userInfo.roles[0]
                                                      === 'ROLE_PARTNER')
                                                       && setIsPaymentModalOpen(true);
                                                  setSelectedInvoiceParam(row);
                                                }}
                                              >
                                                Send for payment
                                              </button>
                                            </StyledButton>
                                          );
                                        case 'WAITING':
                                          return (
                                            <StatusIndicator color="#DA1E28" bg="#f5dcd7">
                                              Waiting...
                                            </StatusIndicator>
                                          );
                                        default:
                                          return (
                                            <StatusIndicator color="#24A148" bg="#DEFBE6">
                                              Paid
                                            </StatusIndicator>
                                          );
                                      }
                                    })()}
                                  </TableCell>
                                );
                              case 'month':
                                return (
                                  <TableCell key={cell.id}>{formatDate(cell.value)}</TableCell>
                                );
                              default:
                                return <TableCell key={cell.id}>{cell.value}</TableCell>;
                            }
                          })}
                          {authStore.userInfo.roles[0] === 'ROLE_PARTNER' && (
                          <StyledEditCell>
                            <TrashCan
                              onClick={() => {
                                setIsDeleteModalOpen(true);
                                setSelectedInvoiceParam(row.id);
                              }}
                            />
                          </StyledEditCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            );
          }}
        </DataTable>
      )}
      {usePagination(phrases, invoicesStore, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus, isAnyModalOpen)}
    </div>
  );
});

export default MonthlyInvoices;
