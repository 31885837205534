import { makeObservable, observable, action } from 'mobx';
import { RoutesEnum } from 'src/routes';
import {
  createClient,
  CreateClientParams,
  deleteClient,
  getAllClients,
  updateClient,
  createFreeFormClientWithCashLoan,
  createFreeFormClientWithMortgage,
  getClientById,
  createMortgageAsSuperAdmin,
  createCashLoanAsSuperAdmin,
  createClientWithMortgage,
  createClientWithCashLoan,
  createFreeForm,
} from 'src/endpoints/clients';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class ClientsStore {
  data = [];

  dataById = [];

  isLoading = false;

  clientId = null;

  clientById = [];

  constructor() {
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      getAll: action,
      // createClientWithMortgage: action,
      // createClientWithCashLoan: action,
      clientId: observable,
      clientById: observable,
      dataById: observable,
      getById: action,
    });
  }

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus) => {
    this.isLoading = true;

    const content = await getAllClients(pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
    this.data = content;

    this.isLoading = false;
  };

  createWithMortgage = async (data: CreateClientParams) => {
    try {
      const res = await createClientWithMortgage(data);
      genericSuccessHandler('Successfully created the client');
      window.location.replace(RoutesEnum.ALL_CLIENTS);
      return res;
    } catch (error) {
      genericErrorHandler(error);
      return error;
    }
  };

  createWithCashLoan = async (data: CreateClientParams) => {
    try {
      const res = await createClientWithCashLoan(data);
      genericSuccessHandler('Successfully created the client');
      window.location.replace(RoutesEnum.ALL_CLIENTS);
      return res;
    } catch (error) {
      genericErrorHandler(error);
      return error;
    }
  };

  createAsAdminMortgage = async (agentId, data: CreateClientParams) => {
    try {
      const res = await createMortgageAsSuperAdmin(agentId, data);
      genericSuccessHandler('Successfully created the client');
      window.location.replace(RoutesEnum.ALL_CLIENTS);
      return res;
    } catch (error) {
      genericErrorHandler(error);
      return error;
    }
  };

  createAsAdminCashLoan = async (agentId, data: CreateClientParams) => {
    try {
      const res = await createCashLoanAsSuperAdmin(agentId, data);
      genericSuccessHandler('Successfully created the client');
      window.location.replace(RoutesEnum.ALL_CLIENTS);
      return res;
    } catch (error) {
      genericErrorHandler(error);
      return error;
    }
  };

  getById = async (id: number) => {
    this.isLoading = true;

    const content = await getClientById(id);
    this.dataById = content;
    this.isLoading = false;
  };

  createFreeFormWithMortgage = async (data: CreateClientParams, successFnCallback) => {
    try {
      await createFreeFormClientWithMortgage(data);
      genericSuccessHandler('Successfully created the client');
      successFnCallback();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  createFreeFormWithCashLoan = async (data: CreateClientParams, successFnCallback) => {
    try {
      await createFreeFormClientWithCashLoan(data);
      genericSuccessHandler('Successfully created the client');
      successFnCallback();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  createFreeForm = async (data: CreateClientParams, successFnCallback) => {
    try {
      await createFreeForm(data);
      genericSuccessHandler('Successfully created the client');
      successFnCallback();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  update = async (id, data: CreateClientParams) => {
    try {
      await updateClient(id, data);
      genericSuccessHandler('Successfully updated the client');
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  delete = async (id: number) => {
    try {
      await deleteClient(id);
      genericSuccessHandler('Successfully deleted the client');
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}

export default new ClientsStore();
