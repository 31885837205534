import { paginationHelperMethod } from 'src/utils/paginationRoute';
import axiosInstance from '.';

export const getAllAggregation = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'predefined-offer-aggregation/all', filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
};

export interface CashOfferParams {
  offerName: string;
  interestRate: number;
  with3MBelibor: boolean;
  with6MBelibor: boolean;
  additionalCosts: number;
  includesLoanProcessingCosts: boolean;
  costs: number;
  lifeInsuranceInformation: string;
  isActive: boolean;
  minCreditAmount: number;
  maxCreditAmount: number;
  minCreditPeriod: number;
  maxCreditPeriod: number;
  minCreditPeriodWindow: number;
  maxCreditPeriodWindow: number;
  minAge: number;
  maxAge: number;
  allowedEmploymentDurations: Array<string>;
  allowedCompanyExistence: Array<string>;
  notSupportedIndustries: Array<string>;
  notSupportedCities: Array<string>;
}

export const postCashOffer = async (params: Partial<CashOfferParams>): Promise<any> => {
  return axiosInstance.post('cash-product-pipeline/predefined-offers/store', params);
};

export const postMortgageOffer = async (params: Partial<CashOfferParams>): Promise<any> => {
  return axiosInstance.post('mortgage-product-pipeline/predefined-offers/store', params);
};

export const deleteCashOffer = async (id: number): Promise<any> => {
  return axiosInstance.delete(`cash-product-pipeline/predefined-offers/delete/${id}`);
};

export const deleteMortgageOffer = async (id: number): Promise<any> => {
  return axiosInstance.delete(`mortgage-product-pipeline/predefined-offers/delete/${id}`);
};

export const getMortgageOfferById = async (id:number): Promise<any> => {
  return axiosInstance.get(`/mortgage-product-pipeline/predefined-offers/${id}`).then((res) => res.data);
};

export const getCashOfferById = async (id:number): Promise<any> => {
  return axiosInstance.get(`cash-product-pipeline/predefined-offers/${id}`).then((res) => res.data);
};

export const editMortgageOffer = async (id, params: Partial<CashOfferParams>): Promise<any> => {
  return axiosInstance.put(`mortgage-product-pipeline/predefined-offers/update/${id}`, params);
};

export const editCashOffer = async (id, params: Partial<CashOfferParams>): Promise<any> => {
  return axiosInstance.put(`cash-product-pipeline/predefined-offers/update/${id}`, params);
};
