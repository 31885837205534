import React, { useState, useEffect } from 'react';
import { toJS } from 'mobx';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { Modal, Dropdown, TextInput } from '@carbon/react';
import { AgentAccountParams } from 'src/endpoints/agents';
import { useStores } from 'src/stores';

const StyledRow = styled(Row)`
  margin-bottom: 32px;
`;

interface Errors {
  partnerId?: string;
  firstName?: string;
  surname?: string;
  email?: string;
}

const CreateModal = ({ isOpen, setIsOpen, phrases }) => {
  const { agentsStore, partnersStore }: any = useStores();
  const [data, setData] = useState<Partial<AgentAccountParams>>({});
  const [partnerId, setPartnerId]: any = useState();
  const [errors, setErrors] = useState<Errors>({});

  const onSubmit = async () => {
    const isValid = validateFields();
    if (isValid) {
      await agentsStore.create(partnerId, data as AgentAccountParams);
      setIsOpen(false);
    }
  };
  useEffect(() => {
    partnersStore.getAll();
  }, []);

  const validateFields = () => {
    const newErrors = { partnerId: '', firstName: '', surname: '', email: '' };
    let hasErrors = false;
    if (!partnerId) {
      newErrors.partnerId = 'Partner ID is required';
      hasErrors = true;
    }
    if (!data.firstName) {
      newErrors.firstName = 'First name is required';
      hasErrors = true;
    }
    if (!data.surname) {
      newErrors.surname = 'Surname is required';
      hasErrors = true;
    }
    if (!data.email) {
      newErrors.email = 'Email is required';
      hasErrors = true;
    }
    setErrors(newErrors);
    if (!hasErrors) {
      return true;
    }
    setTimeout(() => {
      setErrors({});
      hasErrors = false;
    }, 3000);
    return false;
  };

  return (
    isOpen && (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.create_internal_accounts}
      primaryButtonText={phrases.create_accounts}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      <StyledRow>
        {Object.keys(partnersStore.data).length && (
          <Col>
            <Dropdown
              label={phrases.select}
              titleText={phrases.partner}
              items={partnersStore.data.content.map((ele) => {
                return { id: ele.id, label: `${ele.firstName} ${ele.surname}` };
              })}
              onChange={({ selectedItem: { id } }) => setPartnerId(id)}
              invalid={!!errors.partnerId}
              invalidText={errors.partnerId}
            />
          </Col>
        )}
        <Col>
          <TextInput
            type="text"
            labelText={phrases.name}
            placeholder={phrases.enter_name}
            onChange={({ target: { value } }) => setData({ ...data, firstName: value })}
            invalid={!!errors.firstName}
            invalidText={errors.firstName}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.surname}
            placeholder={phrases.enter_surname}
            onChange={({ target: { value } }) => setData({ ...data, surname: value })}
            invalid={!!errors.surname}
            invalidText={errors.surname}
          />
        </Col>
        <Col>
          <TextInput
            type="email"
            labelText={phrases.email_address}
            placeholder={phrases.enter_email}
            onChange={({ target: { value } }) => setData({ ...data, email: value })}
            invalid={!!errors.email}
            invalidText={errors.email}
          />
        </Col>
      </StyledRow>
    </Modal>
    )
  );
};

export default CreateModal;
