import React, { useEffect, useState } from 'react';
import { useStores } from 'src/stores';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { PatchPhrasesParams } from 'src/endpoints/languages';
import _ from 'lodash';
import { Row, Col } from 'react-grid-system';
import { Modal, TextInput } from '@carbon/react';

const StyledRow = styled(Row)`
  margin-bottom: 32px;
`;
const EditPhrasesModal = ({ isOpen, setIsOpen, phrases, phrasesData, languageId }:any) => {
  const { languagesStore } = useStores();
  const [data, setData]:any = useState<Partial<PatchPhrasesParams>>({});
  const [updateData, setUpdateData]:any = useState<Partial<PatchPhrasesParams>>({});

  const onSubmit = async () => {
    await languagesStore.update(updateData as PatchPhrasesParams);
    setIsOpen(false);
    languagesStore.isLoading = false;
  };

  useEffect(() => {
    if (!_.isEmpty(phrasesData)) {
      setData(phrasesData);
    }
  }, [phrasesData]);

  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={`Label: ${isOpen ? phrasesData.cells[0].value : null}`}
      primaryButtonText={phrases.update_translation}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      <StyledRow>
        <Col>
          <TextInput
            id="translation"
            type="text"
            labelText={phrases.translation}
            placeholder={phrases.enter_a_new_translation}
            value={updateData.phrases ? updateData.phrases[0].translation : isOpen && phrasesData.cells[1].value}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, id: languageId, phrases: [{ label: phrasesData.cells[0].value, translation: value }] })}
          />
        </Col>
      </StyledRow>
    </Modal>
  );
};

export default EditPhrasesModal;
