import React, { useState, useEffect } from 'react';
import { useStores } from 'src/stores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Loading,
  TableToolbarAction,
  TableToolbarMenu,
} from '@carbon/react';
import { Add, Edit, Export, DocumentImport } from '@carbon/react/icons';
import { exportLanguage } from 'src/endpoints/languages';
import EditPhrasesModal from './EditPhrasesModal';
import AddNewLanguageModal from './AddNewLanguageModal';
import DeleteLanguageModal from './DeleteLanguageModal';
import ImportLanguageModal from './ImportLanguageModal';

const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;
const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;
const StyledButton = styled.button`
  position: absolute;
  top: 20%;
  right: 0px;
  transform: translateY(-50%);
  background-color: inherit;
  border: none;
  > button {
    font-size: 10px;
  }
`;

const LanguageManagement = observer(({ phrases }: any) => {
  const { languagesStore }: any = useStores();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [languageDelitionId, setLanguageDelitionId] = useState();
  const [selectedRowData, setSelectedRowData] = useState({});
  const [cardList, setCardList] = useState<any>([]);
  const [languageId, setLanguageId] = useState<number>();
  const [searchResult, setSearchResult]: any = useState();
  const [filter, setFilter]: any = useState();

  const headersData = [
    { key: 'label', header: 'Label' },
    { key: 'translation', header: 'Translation' },
    // { key: 'translation', header: 'Default' },
  ];

  useEffect(() => {
    (async () => {
      await languagesStore.getCountryCodes();
      await languagesStore.getDefault();
      await languagesStore.getAllISO();
      await languagesStore.getAllPhrases();
      setSearchResult(languagesStore.allTranslations);
      languagesStore.isLoading = false;
    })();
  }, []);

  if (languagesStore.isLoading) {
    return (
      <StyledLoading>
        <Loading withOverlay={false} />
      </StyledLoading>
    );
  }
  return (
    <div className="data-table">
      <Tabs line style={{ paddingLeft: 40 }}>
        <TabList aria-label="tabList" contained style={{ marginTop: 20, paddingleft: 20 }}>
          {languagesStore.allIso.map((ele, index) => (
            <div style={{ position: 'relative' }} key={ele.id}>
              <Tab style={{ width: 200 }}>{ele.iso3}</Tab>
              <StyledButton>
                {ele.iso3 !== 'GBR' && (
                  <div
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      setLanguageDelitionId(ele.id);
                    }}
                  >
                    X
                  </div>
                )}
              </StyledButton>
            </div>
          ))}
        </TabList>
        <TabPanels style={{ paddingTop: 0 }}>
          {languagesStore.allIso.map((ele, index) => (
            <TabPanel>
              {/* {setSearchFilter(
                languagesStore.allTranslations[index].phrases.map((inner, InnerIndex) => {
                  return {
                    id: InnerIndex,
                    label: inner.label,
                    translation: inner.translation,
                  };
                }),
              )} */}
              <div className="data-table">
                <AddNewLanguageModal
                  isOpen={isModalOpen}
                  setIsOpen={setIsModalOpen}
                  phrases={phrases}
                  cardList={cardList}
                  setCardList={setCardList}
                />
                <EditPhrasesModal
                  isOpen={isEditModalOpen}
                  setIsOpen={setIsEditModalOpen}
                  phrasesData={selectedRowData}
                  phrases={phrases}
                  languageId={languageId}
                />
                <ImportLanguageModal
                  isOpen={isImportModalOpen}
                  setIsOpen={setIsImportModalOpen}
                  phrases={phrases}
                />
                <DeleteLanguageModal
                  isOpen={isDeleteModalOpen}
                  setIsOpen={setIsDeleteModalOpen}
                  phrases={phrases}
                  languageDelitionId={languageDelitionId}
                />
                {languagesStore.allTranslations && (
                  <DataTable
                    headers={headersData}
                    rows={languagesStore.allTranslations[index].phrases.map((inner, InnerIndex) => {
                      return {
                        id: InnerIndex,
                        label: inner.label,
                        translation: inner.translation,
                      };
                    })}
                    isSortable
                  >
                    {({
                      rows,
                      headers,
                      getHeaderProps,
                      getTableProps,
                      getRowProps,
                      onInputChange,
                      getToolbarProps,
                      getBatchActionProps,
                    }) => {
                      const batchActionProps = getBatchActionProps();

                      return (
                        <TableContainer>
                          <TableToolbar {...getToolbarProps()}>
                            <TableToolbarContent
                              aria-hidden={batchActionProps.shouldShowBatchActions}
                            >
                              <TableToolbarSearch
                                tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                onChange={onInputChange}
                              />
                              <Button
                                tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                renderIcon={Export}
                                onClick={() => exportLanguage(ele.iso3)}
                              >
                                {phrases.export_language_file}
                              </Button>
                              <Button
                                tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                renderIcon={DocumentImport}
                                onClick={() => setIsImportModalOpen(true)}
                              >
                                {phrases.import_language_file}
                              </Button>
                              <Button
                                tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                renderIcon={Add}
                                onClick={() => setIsModalOpen(true)}
                              >
                                {phrases.add_new_language}
                              </Button>
                            </TableToolbarContent>
                          </TableToolbar>
                          <Table {...getTableProps()}>
                            <TableHead>
                              <TableRow>
                                {headers.map((header) => (
                                  <TableHeader {...getHeaderProps({ header })}>
                                    {header.header}
                                  </TableHeader>
                                ))}
                                <TableHeader />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => (
                                <TableRow key={row.id}>
                                  {row.cells.map((cell) => (
                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                  ))}
                                  <StyledTableCell>
                                    <Edit
                                      onClick={() => {
                                        setIsEditModalOpen(true);
                                        setSelectedRowData(row);
                                        setLanguageId(languagesStore.allTranslations[index].id);
                                      }}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      );
                    }}
                  </DataTable>
                )}
              </div>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
});

export default LanguageManagement;
