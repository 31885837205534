import React, { useState } from 'react';
import styled from 'styled-components';
import { DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableToolbarMenu,
  TableToolbarAction,
  Button,
  Pagination } from '@carbon/react';
import { Add, TrashCan, DocumentPdf } from '@carbon/react/icons';
import CreateSpecialOfferModal from './CreateSpecialOfferModal';
import DownloadDocumentModal from './DownloadDocumentModal';

const rowsData = [
  { id: 1,
    product: 'Mortgage',
    bindingTheOffer: 'XX-XX',
    document: 'www.document.com' },
  { id: 2,
    product: 'Cash loans',
    bindingTheOffer: 'XX-XX',
    document: 'www.document.com' },
  { id: 3,
    product: 'Mortgage',
    bindingTheOffer: 'XX-XX',
    document: 'www.document.com' },
];

const DocumentTableCell = styled(TableCell)`
  > span {
    color: #0062fe;
    text-decoration: underline;
    cursor: pointer;
  }

  > svg {
    margin-right: 16px;
  }
`;

const DeleteTableCell = styled(TableCell)`
  > svg {
    float: right;
    cursor: pointer;
  }
`;

const FeaturedOffers = ({ phrases }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const headersData = [
    { key: 'product', header: phrases.product },
    { key: 'bindingTheOffer', header: phrases.Binding_the_offer },
    { key: 'document', header: phrases.document },
  ];

  return (
    <div className="data-table">
      <CreateSpecialOfferModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        phrases={phrases}
      />
      <DownloadDocumentModal
        isOpen={isDownloadModalOpen}
        setIsOpen={setIsDownloadModalOpen}
        phrases={phrases}
      />
      <DataTable headers={headersData} rows={rowsData} isSortable isSelectable>
        {({ rows,
          headers,
          getHeaderProps,
          getTableProps,
          getSelectionProps,
          getToolbarProps,
          getBatchActionProps }) => {
          const batchActionProps = getBatchActionProps();

          return (
            <TableContainer title={phrases.featured_offers}>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                  <TableToolbarSearch
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    onChange={(v) => console.log(v)}
                  />
                  <TableToolbarMenu tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}>
                    <TableToolbarAction onClick={() => alert('Alert 1')}>
                      Action 1
                    </TableToolbarAction>
                    <TableToolbarAction onClick={() => alert('Alert 2')}>
                      Action 2
                    </TableToolbarAction>
                    <TableToolbarAction onClick={() => alert('Alert 3')}>
                      Action 3
                    </TableToolbarAction>
                  </TableToolbarMenu>
                  <Button
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    renderIcon={Add}
                    onClick={() => setIsCreateModalOpen(true)}
                  >
                    {phrases.add_new_special_offer}
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => {
                        switch (cell.info.header) {
                          case 'document':
                            return (
                              <DocumentTableCell key={cell.id}>
                                <DocumentPdf />
                                <span onClick={() => setIsDownloadModalOpen(true)}>Document</span>
                              </DocumentTableCell>
                            );
                          default:
                            return <TableCell key={cell.id}>{cell.value}</TableCell>;
                        }
                      })}
                      <DeleteTableCell>
                        <TrashCan />
                      </DeleteTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      <Pagination
        className="data-table__pagination"
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText={phrases.items_per_page}
        page={1}
        pageNumberText="Page Number"
        pageSize={10}
        pageSizes={[10, 20, 30, 40, 50]}
        totalItems={103}
      />
    </div>
  );
};

export default FeaturedOffers;
