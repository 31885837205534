import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'src/stores';
import styled from 'styled-components';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableToolbarMenu,
  TableToolbarAction,
  Pagination,
  Button,
  Loading,
} from '@carbon/react';
import { Add, Edit } from '@carbon/react/icons';
import { RoutesEnum } from 'src/routes';

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;

const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;

const ProductManagement = observer(({ phrases }: any) => {
  const navigate = useNavigate();
  const { productsStore }: any = useStores();

  const headersData = [
    { key: 'productType', header: phrases.loan_type },
    { key: 'rate', header: phrases.rate },
    { key: 'currency', header: phrases.currency },
    { key: '...', header: '...' },
  ];

  useEffect(() => {
    productsStore.getAll();
  }, []);

  if (productsStore.isLoading) {
    return (
      <StyledLoading>
        <Loading withOverlay={false} />
      </StyledLoading>
    );
  }

  return (
    <div className="data-table">
      <DataTable
        headers={headersData}
        rows={productsStore.data.map((ele, index) => {
          return {
            id: index,
            productType: ele.productType,
            rate: ele.rate.rate,
            currency: ele.currency.name,
          };
        })}
        isSortable
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getToolbarProps,
          getBatchActionProps,
        }) => {
          const batchActionProps = getBatchActionProps();

          return (
            <TableContainer title={phrases.product_management}>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                  <TableToolbarSearch
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    onChange={(v) => console.log(v)}
                  />
                  <TableToolbarMenu tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}>
                    <TableToolbarAction onClick={() => alert('Alert 1')}>
                      Action 1
                    </TableToolbarAction>
                    <TableToolbarAction onClick={() => alert('Alert 2')}>
                      Action 2
                    </TableToolbarAction>
                    <TableToolbarAction onClick={() => alert('Alert 3')}>
                      Action 3
                    </TableToolbarAction>
                  </TableToolbarMenu>
                  {/* <Button
                    tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                    renderIcon={Add}
                    onClick={() => navigate(RoutesEnum.PRODUCT_MANAGEMENT_ADD_PRODUCT, { replace: true })}
                  >
                    {phrases.add_a_product}
                  </Button> */}
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      <Pagination
        className="data-table__pagination"
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText={phrases.items_per_page}
        page={1}
        pageNumberText="Page Number"
        pageSize={10}
        pageSizes={[10, 20, 30, 40, 50]}
        totalItems={103}
      />
    </div>
  );
});

export default ProductManagement;
