import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Content, Breadcrumb, BreadcrumbItem } from '@carbon/react';
import _ from 'lodash';
import { RoutesEnum } from 'src/routes';
import Header from '../Header';
import SideNav from '../SideNav';

const PageLayout = ({ children }) => {
  const { pathname } = useLocation();

  if (
    [
      RoutesEnum.LOGIN,
      '/free-form',
      RoutesEnum.FORGOT_PASSWORD,
      RoutesEnum.THANK_YOU,
    ].includes(pathname) || window.location.pathname.includes('/password-confirmation/')
  ) {
    return children;
  }

  return (
    <div className="page-layout">
      <Header />
      <div className="page-layout__body">
        <SideNav />
        <div className="page-layout__body-content">
          <Content className="page-layout__body-content-children">{children}</Content>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
