import React, { useState, useEffect } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { Modal, Dropdown, TextInput, DatePicker, DatePickerInput, ComboBox } from '@carbon/react';
import { useStores } from 'src/stores';
import { MeetingParams } from 'src/endpoints/meetings';

const StyledRow = styled(Row)`
  margin-bottom: 32px;
`;

const EditMeetingModal = observer(({ isOpen, setIsOpen, phrases, meetingData }: any) => {
  const { meetingsStore, internalsStore, clientsStore }: any = useStores();
  const [data, setData] = useState<Partial<MeetingParams>>({});
  const [updateData, setUpdateData] = useState({});

  const meetingTimes = [
    { id: 1, value: '08:00' },
    { id: 2, value: '08:30' },
    { id: 3, value: '09:00' },
    { id: 4, value: '09:30' },
    { id: 5, value: '10:00' },
    { id: 6, value: '10:30' },
    { id: 7, value: '11:00' },
    { id: 8, value: '11:30' },
    { id: 9, value: '12:00' },
    { id: 10, value: '12:30' },
    { id: 11, value: '13:00' },
    { id: 12, value: '13:30' },
    { id: 13, value: '14:00' },
    { id: 14, value: '14:30' },
    { id: 15, value: '15:00' },
    { id: 16, value: '15:30' },
    { id: 17, value: '16:00' },
    { id: 18, value: '16:30' },
    { id: 19, value: '17:00' },
  ];

  const onSubmit = async () => {
    await meetingsStore.update(meetingData.id, updateData);
    setIsOpen(false);
  };

  const branchNameInit = () => {
    if (isOpen) {
      const branchFilter = internalsStore.data?.content.filter(
        (element) => element.accountType === 'BRANCH',
      );
      const value = branchFilter.find((element) => element.email === meetingData.branchEmail);
      const name = `${value.firstName} ${value.surname}`;
      return name;
    }
    return null;
  };

  useEffect(() => {
    if (!_.isEmpty(meetingData)) {
      setData(meetingData);
      internalsStore.getAll();
      clientsStore.getAll();
      meetingsStore.getById(meetingData.id);
    }
  }, [meetingData]);

  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.edit_a_meeting}
      primaryButtonText={phrases.confirm}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      {isOpen && meetingData && (
        <>
          <StyledRow>
            {Object.keys(clientsStore.data).length && isOpen && (
              <Col>
                <ComboBox
                  placeholder={phrases.select}
                  titleText={phrases.client_name}
                  items={clientsStore.data.content.map((ele) => {
                    return { id: ele.id, label: `${ele.firstName} ${ele.surname}` };
                  })}
                  onChange={({ selectedItem: { id } }) => setUpdateData({ ...updateData, clientId: id })}
                  initialSelectedItem={`${meetingData.clientName} ${meetingData.clientSurname}`}
                />
              </Col>
            )}
            {Object.keys(internalsStore.data).length && (
              <Col>
                <Dropdown
                  label={phrases.select}
                  titleText={phrases.branch_name}
                  items={internalsStore.data.content
                    .filter((element) => element.accountType === 'BRANCH')
                    .map((ele) => {
                      return { id: ele.id, label: `${ele.firstName} ${ele.surname}` };
                    })}
                  selectedItem={branchNameInit()}
                  onChange={({ selectedItem: { id } }) => setUpdateData({ ...updateData, branchId: id })}
                />
              </Col>
            )}
          </StyledRow>
          <StyledRow>
            <Col>
              <DatePicker
                dateFormat="d/m/Y"
                datePickerType="single"
                onChange={(value) => {
                  const date = new Date(value);
                  const format = date.toISOString().slice(0, -14);
                  const format2 = format.split('-').reverse().join('-');
                  setUpdateData({ ...updateData, meetingDate1: format2 });
                }}
                value={meetingData.meetingOption1?.substring(0, 10) || ''}
              >
                <DatePickerInput
                  datePickerType="range"
                  type="string"
                  id="date-picker-default-2"
                  placeholder="dd-mm-yyyy"
                  labelText={phrases.proposed_meeting_option_1}
                />
              </DatePicker>
            </Col>
            <Col>
              {isOpen && (
                <Dropdown
                  label="hh:mm"
                  titleText={phrases.meeting_option_1_time}
                  placeholder="hh:mm"
                  items={meetingTimes.map((ele) => {
                    return { id: ele.id, label: ele.value };
                  })}
                  onChange={({ selectedItem: { label } }) => {
                    setUpdateData({ ...updateData, meetingTime1: label });
                  }}
                  initialSelectedItem={meetingData.meetingOption1?.substring(11, 16) || ''}
                />
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <DatePicker
                dateFormat="d/m/Y"
                datePickerType="single"
                onChange={(value) => {
                  const date = new Date(value);
                  const format = date.toISOString().slice(0, -14);
                  const format2 = format.split('-').reverse().join('-');
                  setUpdateData({ ...updateData, meetingDate2: format2 });
                }}
                value={meetingData.meetingOption2?.substring(0, 10) || ''}
              >
                <DatePickerInput
                  datePickerType="range"
                  type="string"
                  id="date-picker-default-2"
                  placeholder="dd-mm-yyyy"
                  labelText={phrases.proposed_meeting_option_2}
                />
              </DatePicker>
            </Col>
            <Col>
              {isOpen && (
                <Dropdown
                  label="hh:mm"
                  titleText={phrases.meeting_option_2_time}
                  placeholder="hh:mm"
                  items={meetingTimes.map((ele) => {
                    return { id: ele.id, label: ele.value };
                  })}
                  onChange={({ selectedItem: { label } }) => {
                    setUpdateData({ ...updateData, meetingTime2: label });
                  }}
                  initialSelectedItem={meetingData.meetingOption2?.substring(11, 16) || ''}
                />
              )}
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <DatePicker
                dateFormat="d/m/Y"
                datePickerType="single"
                onChange={(value) => {
                  const date = new Date(value);
                  const format = date.toISOString().slice(0, -14);
                  const format2 = format.split('-').reverse().join('-');
                  setUpdateData({ ...updateData, meetingDate3: format2 });
                }}
                value={meetingData.meetingOption3?.substring(0, 10) || ''}
              >
                <DatePickerInput
                  datePickerType="range"
                  type="string"
                  id="date-picker-default-2"
                  placeholder="dd-mm-yyyy"
                  labelText={phrases.proposed_meeting_option_3}
                />
              </DatePicker>
            </Col>
            <Col>
              {isOpen && (
                <Dropdown
                  label="hh:mm"
                  titleText={phrases.meeting_option_3_time}
                  placeholder="hh:mm"
                  items={meetingTimes.map((ele) => {
                    return { id: ele.id, label: ele.value };
                  })}
                  onChange={({ selectedItem: { label } }) => {
                    setUpdateData({ ...updateData, meetingTime3: label });
                  }}
                  initialSelectedItem={meetingData.meetingOption3?.substring(11, 16) || ''}
                />
              )}
            </Col>
          </StyledRow>
        </>
      )}
    </Modal>
  );
});

export default EditMeetingModal;
