import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { Modal, Dropdown, TextInput } from '@carbon/react';
import { PartnerAccountParams } from 'src/endpoints/partners';
import { useStores } from 'src/stores';

const StyledRow = styled(Row)`
  margin-bottom: 32px;
`;

const UpdateModal = ({ isOpen, setIsOpen, partnerAccountData, phrases }) => {
  const { partnersStore } = useStores();
  const [data, setData] = useState<Partial<PartnerAccountParams>>({});
  const [updateData, setUpdateData] = useState<Partial<PartnerAccountParams>>({});

  const onSubmit = async () => {
    await partnersStore.update(data.id, updateData as PartnerAccountParams);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!_.isEmpty(partnerAccountData)) {
      setData(partnerAccountData);
    }
  }, [partnerAccountData]);

  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.update_partner_accounts}
      primaryButtonText={phrases.update_account}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      <StyledRow>
        <Col>
          <Dropdown
            label={phrases.select}
            titleText={phrases.partner_type}
            items={[
              { id: 'REAL_ESTATE_AGENCY', label: 'Real estate agency' },
              { id: 'DIGITAL_MORTGAGE_BROKER', label: 'Digital mortgage broker' },
              { id: 'LEAD_GEN_PLATFORM', label: 'Lead gen platform' },
            ]}
            onChange={({ selectedItem: { id } }) => setUpdateData({ ...updateData, partnerType: id })}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.company_name}
            placeholder={phrases.enter_company_name}
            value={updateData.companyName !== undefined ? updateData.companyName : data.companyName}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, companyName: value })}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.company_address}
            placeholder={phrases.enter_company_address}
            value={updateData.companyAddress !== undefined ? updateData.companyAddress : data.companyAddress}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, companyAddress: value })}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.company_number}
            placeholder={phrases.enter_company_number}
            value={updateData.companyNumber !== undefined ? updateData.companyNumber : data.companyNumber}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, companyNumber: value })}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.tax_number}
            placeholder={phrases.enter_tax_number}
            value={updateData.taxNumber !== undefined ? updateData.taxNumber : data.taxNumber}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, taxNumber: value })}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.name_and_surname_of_the_master_account_holder}
            placeholder={phrases.enter_name_and_surname}
            onChange={({ target: { value } }) => setData({ ...data, accountExecutiveId: value })}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.name}
            placeholder={phrases.enter_name}
            value={updateData.firstName !== undefined ? updateData.firstName : data.firstName}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, firstName: value })}
          />
        </Col>
        <Col>
          <TextInput
            type="text"
            labelText={phrases.surname}
            placeholder={phrases.enter_surname}
            value={updateData.surname !== undefined ? updateData.surname : data.surname}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, surname: value })}
          />
        </Col>
      </StyledRow>
      <Row>
        <Col>
          <TextInput
            type="email"
            labelText={phrases.email_address}
            placeholder={phrases.enter_email}
            value={updateData.email !== undefined ? updateData.email : data.email}
            onChange={({ target: { value } }) => setUpdateData({ ...updateData, email: value })}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default UpdateModal;
