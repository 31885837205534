import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, Navigate } from 'react-router-dom';
import { TextInput, DatePicker, DatePickerInput, Checkbox, Button } from '@carbon/react';
import { CreateClientParams } from 'src/endpoints/clients';
import { useStores } from 'src/stores';
import { ArrowRight } from '@carbon/react/icons';
import { RoutesEnum } from 'src/routes';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;

  > div {
    width: 420px;
    height: 100%;
    padding: 48px 50px;
    background-color: white;
    overflow-y: scroll;

    > span {
      display: block;
      margin: 4px 0 32px 0;
      font-size: 14px;
    }

    > strong {
      display: block;
      margin: 48px 0 16px 0;
      font-size: 14px;
    }

    > small {
      display: block;
      margin: 24px 0 8px 0;
      font-size: 12px;
    }

    .cds--text-input-wrapper {
      margin-bottom: 24px;
    }

    > button {
      margin-top: 48px;
    }
  }
`;

const FreeForm = () => {
  const [clientData, setClientData]:any = useState<Partial<CreateClientParams>>({});
  const { clientsStore } = useStores();
  const mortgageInputRef:any = useRef();
  const cashLoanInputRef:any = useRef();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const mortgage = mortgageInputRef.current.checked;
    const cash = cashLoanInputRef.current.checked;
    clientData.productIds = [];
    mortgage && clientData.productIds.push(2);
    cash && clientData.productIds.push(1);
    await clientsStore.createFreeForm(clientData as CreateClientParams, successfullyFormCallback());
    navigate(RoutesEnum.THANK_YOU, { replace: true });
  };

  const successfullyFormCallback = (): void => {
    setClientData({
      agentId: '',
      firstName: '',
      surname: '',
      email: '',
      phone: '',
      dateOfBirth: '',
      productIds: [],
    });
  };

  return (
    <StyledContainer>
      <div className="free-form-container">
        <h3>Free form</h3>
        <span>Don&apos;t have an account? Create a Finbox</span>
        <TextInput
          type="text"
          labelText="Agent ID"
          placeholder="Agent ID"
          onChange={({ target: { value } }) => setClientData({ ...clientData, agentId: value })}
        />
        <strong>Client data</strong>
        <TextInput
          type="text"
          labelText="Name"
          placeholder="Client's name"
          onChange={({ target: { value } }) => setClientData({ ...clientData, firstName: value })}
        />
        <TextInput
          type="text"
          labelText="Surname"
          placeholder="Client's surname"
          onChange={({ target: { value } }) => setClientData({ ...clientData, surname: value })}
        />
        <TextInput
          type="text"
          labelText="Email"
          placeholder="Client's e-mail"
          onChange={({ target: { value } }) => setClientData({ ...clientData, email: value })}
        />
        <TextInput
          type="text"
          labelText="Phone number"
          placeholder="Client's phone number"
          onChange={({ target: { value } }) => setClientData({ ...clientData, phone: value })}
        />
        <DatePicker
          dateFormat="d/m/Y"
          datePickerType="single"
          onChange={(value) => {
            const date = new Date(value);
            const format = date.toISOString().slice(0, -14);
            const format2 = format.split('-').reverse().join('-');
            setClientData({ ...clientData, dateOfBirth: format2 });
          }}
        >
          <DatePickerInput
            id="date-picker-default-1"
            placeholder="dd/mm/yyyy"
            labelText="Birthday"
          />
        </DatePicker>
        <small>Products</small>
        <Checkbox id="mortgage" labelText="Mortgage" ref={mortgageInputRef} />
        <Checkbox id="cash" labelText="Cash loan" ref={cashLoanInputRef} />
        {/* <Checkbox id="account" labelText="Account" /> */}
        <Button renderIcon={ArrowRight} onClick={onSubmit}>
          Submit request
        </Button>
      </div>
    </StyledContainer>
  );
};

export default FreeForm;
