import { paginationHelperMethod } from 'src/utils/paginationRoute';
import axiosInstance from '.';

export const getAllAgents = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'agent', filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
};

export const getAgent = async (id: number): Promise<any> => {
  return axiosInstance.get(`/agent/getById/${id}`).then((res) => res.data);
};

export interface AgentAccountParams {
  id: number;
  partnerId: number;
  firstName: string;
  surname: string;
  email: string;
}

export const createAgent = async (partnerId: number, params: Partial<AgentAccountParams>): Promise<any> => {
  return axiosInstance.post(`/agent/saveAgent/?partnerId=${partnerId}`, params);
};

export const updateAgent = async (id: string, params: Partial<AgentAccountParams>): Promise<any> => {
  return axiosInstance.put(`/agent/${id}`, params);
};

export const deleteAgent = async (id: number): Promise<any> => {
  return axiosInstance.delete(`/agent/${id}`);
};
