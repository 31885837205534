import { paginationHelperMethod } from 'src/utils/paginationRoute';
import axiosInstance from '.';

export interface CreateReportParams {
  reportName: string;
  partnerId: number;
  products: Array<number>;
  timeFrom: string;
  timeTo: string;
}

export interface exportReportParams {
  reportId: number;
  fileName: string;
}

export interface updateReportParams {
  id: number;
  leadsValidity?: string;
  approvalStatus?: string;
  meetingStatus?: string;
}

export const getAllReports = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'report', filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
};

export const getAllPreviewReports = async (params: CreateReportParams): Promise<any> => {
  return axiosInstance.post('/report/previewReport', params);
};

export const createReport = async (params: CreateReportParams): Promise<any> => {
  return axiosInstance.post('/report/createReport/', params);
};

export const deleteReport = async (id: number): Promise<any> => {
  return axiosInstance.delete(`/report/${id}`);
};

export const updateReport = async (params: updateReportParams): Promise<any> => {
  return axiosInstance.patch('/product-pipeline/status', params);
};

export const exportReport = async (reportId: number, fileName: string): Promise<any> => {
  return axiosInstance
    .get(`/report/exportReport/${reportId}/${fileName}`, {
      headers: {
        accept: 'text/csv',
      },
      responseType: 'blob',
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${reportId}.csv`);
      document.body.appendChild(link);
      link.click();
    });
};

export const exportMultipleReports = async (ids: number[]): Promise<any> => {
  const query = ids.map((ele) => { return `ids=${ele}&`; });
  const final = query.join('').slice(0, -1);
  return axiosInstance
    .get(`/report/exportReport?${final}`, {
      headers: {
        accept: 'text/csv',
      },
      responseType: 'blob',
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'zip.csv');
      document.body.appendChild(link);
      link.click();
    });
};
