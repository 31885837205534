import _ from 'lodash';
import { PrivilegesEnum, RolesEnum } from 'src/privileges';
import AuthStore from 'src/stores/auth';

const hasPrivileges = (requiredPrivileges: PrivilegesEnum | PrivilegesEnum[]): boolean => {
  const userPrivileges = AuthStore.userInfo.privileges;
  let res = false;

  if (_.isArray(requiredPrivileges)) {
    res = !_.isEmpty(_.intersection(userPrivileges, requiredPrivileges));
  } else {
    res = _.includes(userPrivileges, requiredPrivileges);
  }

  return !!res;
};

export default hasPrivileges;
