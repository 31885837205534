import React from 'react';
import { Modal, TextInput } from '@carbon/react';

const CreateNewOfferModal = ({ isOpen, setIsOpen, phrases }) => (
  <Modal
    open={isOpen}
    size="xs"
    modalHeading={phrases.mortgage}
    primaryButtonText={phrases.download}
    secondaryButtonText={phrases.cancel}
    onRequestClose={() => setIsOpen(false)}
  >
    <TextInput type="text" labelText={phrases.file_name} />
  </Modal>
);

export default CreateNewOfferModal;
