import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NotificationsParams } from 'src/endpoints/notifications';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useStores } from 'src/stores';
import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Toggle,
  Loading,
} from '@carbon/react';

const StyledH3 = styled.h3`
  margin: 8px 0 48px 0;
`;
const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;

const StyledStructuredListWrapper = styled(StructuredListWrapper)`
  background-color: white;

  .cds--structured-list-row > .cds--structured-list-td:nth-child(n + 2) {
    width: 75px;
  }
`;

const NotificationsManagement = observer(({ phrases }: any) => {
  const { notificationsStore }: any = useStores();
  const [toggled, setToggled]: any = useState<Partial<NotificationsParams>>({});

  const update = (para) => {
    const data = { ...toggled };
    data[para] = !toggled[para];
    setToggled(data);
    notificationsStore.update(data);
  };

  useEffect(() => {
    notificationsStore.getAll();
    setToggled(notificationsStore.data);
  }, []);

  if (notificationsStore.isLoading) {
    return (
      <StyledLoading>
        <Loading withOverlay={false} />
      </StyledLoading>
    );
  }

  return (
    <>
      <StyledH3>{phrases.notifications_management}</StyledH3>
      <StyledStructuredListWrapper>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head />
            <StructuredListCell head>Email</StructuredListCell>
            <StructuredListCell head>{phrases.push}</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        {Object.keys(notificationsStore.data).length && (
          <StructuredListBody>
            <StructuredListRow>
              <StructuredListCell>{phrases.account_opening}</StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.accountOpeningEmail}
                  id="account-opening-email"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('accountOpeningEmail')}
                />
              </StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.accountOpeningPush}
                  id="account-opening-push"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('accountOpeningPush')}
                />
              </StructuredListCell>
            </StructuredListRow>
            <StructuredListRow>
              <StructuredListCell>{phrases.loan_application}</StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.loanApplicationEmail}
                  id="loan=applications-email"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('loanApplicationEmail')}
                />
              </StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.loanApplicationPush}
                  id="loan=applications-push"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('loanApplicationPush')}
                />
              </StructuredListCell>
            </StructuredListRow>
            <StructuredListRow>
              <StructuredListCell>{phrases.meeting_requests}</StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.meetingRequestsEmail}
                  id="meeting-requests-email"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('meetingRequestsEmail')}
                />
              </StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.meetingRequestsPush}
                  id="meeting-requests-push"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('meetingRequestsPush')}
                />
              </StructuredListCell>
            </StructuredListRow>
            <StructuredListRow>
              <StructuredListCell>{phrases.status_change_for_a_loan}</StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.loanStatusChangeEmail}
                  id="status-change-loan-email"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('loanStatusChangeEmail')}
                />
              </StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.loanStatusChangePush}
                  id="status-change-loan-push"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('loanStatusChangePush')}
                />
              </StructuredListCell>
            </StructuredListRow>
            <StructuredListRow>
              <StructuredListCell>{phrases.special_offers}</StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.specialOffersEmail}
                  id="special-offer-email"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('specialOffersEmail')}
                />
              </StructuredListCell>
              <StructuredListCell>
                <Toggle
                  defaultToggled={toggled.specialOffersPush}
                  id="special-offer-push"
                  size="sm"
                  labelA=""
                  labelB=""
                  onToggle={() => update('specialOffersPush')}
                />
              </StructuredListCell>
            </StructuredListRow>
          </StructuredListBody>
        )}
      </StyledStructuredListWrapper>
    </>
  );
});

export default NotificationsManagement;
