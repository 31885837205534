import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/stores';
import { toJS } from 'mobx';

import styled from 'styled-components';
import {
  Modal,
  FileUploaderDropContainer,
  Grid,
  Column,
  ComboBox,
  FileUploaderItem,
} from '@carbon/react';

const StyledText = styled.div`
  margin: 16px 0;

  > span {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
  }
`;

const ImportLanguageModal = observer(({ isOpen, setIsOpen, phrases }: any) => {
  const [file, setFile]: any = useState();
  const [fileName, setFileName] = useState('');
  const [iso, setIso] = useState('');
  const { languagesStore }: any = useStores();

  const onSubmit = async () => {
    await languagesStore.importLanguageFile(iso, file);
    await languagesStore.getAllISO();
    await languagesStore.getDefault();
    await languagesStore.getAllPhrases();
    languagesStore.isLoading = false;
    setIsOpen(false);
  };

  const mapped = languagesStore.countryCodes.filter(
    (ele) => ele.iso3
    !== languagesStore.allIso.map((inner) => {
      return inner.iso3;
    }),
  );
  const items = mapped.map((iso3, isoIndex) => {
    return { id: isoIndex, name: iso3.iso3, label: iso3.name };
  });
  return (
    <Modal
      open={isOpen}
      size="sm"
      modalHeading={phrases.import_language}
      primaryButtonText={phrases.import_language}
      secondaryButtonText={phrases.cancel}
      hasScrollingContent
      onRequestClose={() => setIsOpen(false)}
      onRequestSubmit={onSubmit}
    >
      <Grid style={{ padding: 0 }}>
        <Column lg={16} md={8} sm={4} style={{ margin: '0 0 24px 0' }}>
          <ComboBox
            initialSelectedItem={items[0]}
            label={phrases.select}
            titleText={phrases.language}
            items={items}
            onChange={(value) => {
              if (value.selectedItem) {
                setIso(value.selectedItem.name);
              }
              if (!Object.keys(value).length) {
                setIso('');
              }
            }}
          />
        </Column>
      </Grid>
      <StyledText>
        <span>{phrases.upload_file}</span>
        <small>{phrases.csv_files}</small>
      </StyledText>
      <FileUploaderDropContainer
        buttonKind="primary"
        accept={['.csv']}
        labelText={phrases.drag_and_drop_files}
        filenameStatus="uploading"
        onAddFiles={(value, { addedFiles }) => {
          const contentType = 'text/csv';
          const csvFile = new Blob([addedFiles[0]], { type: contentType });
          setFile(csvFile);
          setFileName(addedFiles[0].name);
        }}
        innerRef={{
          current: '[Circular]',
        }}
        multiple
      />
      {file ? (
        <FileUploaderItem
          status="edit"
          name={fileName}
          errorBody="Invalid file size"
          onDelete={() => setFile(null)}
        />
      ) : null}
    </Modal>
  );
});
export default ImportLanguageModal;
