import { paginationHelperMethod } from 'src/utils/paginationRoute';
import axiosInstance from '.';

export const getAllInvoices = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus): Promise<any> => {
  return paginationHelperMethod(pageNumber, pageSize, 'invoice', filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
};

export interface InvoicesParams {
  id: number;
  ids: Array<number>;
}

export const createInvoice = async (file, date, fileName): Promise<any> => {
  const blobToFile = new File([file], fileName);

  return axiosInstance.post(
    '/invoice/saveInvoice/',
    { file: blobToFile, month: date },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*',
      },
    },
  );
};

export const createInvoiceAdmin = async (partnerId, file, date, fileName): Promise<any> => {
  const blobToFile = new File([file], fileName);

  return axiosInstance.post(
    `/invoice/saveInvoice/${partnerId}`,
    { file: blobToFile, month: date },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*',
      },
    },
  );
};

export const deleteInvoice = async (id: number): Promise<any> => {
  return axiosInstance.delete(`/invoice/${id}`);
};
export const sendForPayment = async (ids: Array<number>): Promise<any> => {
  return axiosInstance.get(`/invoice/sendForPayment/${ids}`);
};
export const downloadFile = async (id: number): Promise<any> => {
  return axiosInstance.get(`/invoice/downloadInvoice/${id}`).then((res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${id}.pdf`);
    document.body.appendChild(link);
    link.click();
  });
};
