import { makeObservable, observable, action } from 'mobx';
import {
  createReport,
  getAllReports,
  getAllPreviewReports,
  exportReport,
  deleteReport,
  CreateReportParams,
  updateReportParams,
  updateReport,
  exportMultipleReports,
} from 'src/endpoints/reports';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

export interface ReportData {
  id: number;
  partnerId: number;
  date: string;
  firstName: string;
  surname: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  productType: string;
  amount: 0;
  reportName: string;
}

export interface PreviewReportData {
  id: number;
  partnerId: number;
  date: string;
  firstName: string;
  surname: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  productType: string;
  amount: 0;
  reportName: string;
  meetingStatus: string;
  approvalStatus: string;
}

class ReportStore {
  data: Array<ReportData> = [];

  previewReport: Array<ReportData> = [];

  isLoading = false;

  constructor() {
    makeObservable(this, {
      data: observable,
      previewReport: observable,
      createReport: action,
      getAll: action,
      exportReport: action,
      deleteReport: action,
      allPreviewReports: action,
      updateReport: action,
      isLoading: observable,
    });
  }

  createReport = async (data: CreateReportParams, successCallBack) => {
    try {
      await createReport(data);
      this.getAll(0, 10, '', '', '', null, null, null, null);
      genericSuccessHandler('successfully created report');
      successCallBack();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus) => {
    try {
      this.isLoading = true;
      const response = await getAllReports(pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus);
      this.data = response;
      this.isLoading = false;
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  allPreviewReports = async (data: CreateReportParams, successCallBack) => {
    try {
      const response = await getAllPreviewReports(data);
      this.previewReport = response.data.map((item) => {
        return { ...item, id: item.productPipelineId };
      });
      successCallBack();
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  exportReport = async (reportId: number, fileName: string) => {
    try {
      await exportReport(reportId, fileName);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  exportReportsZip = async (ids: number[]) => {
    try {
      await exportMultipleReports(ids);
      genericSuccessHandler('successfully exported reports');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  deleteReport = async (id: number) => {
    try {
      await deleteReport(id);
      genericSuccessHandler('successfully deleted report');
      await this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  updateReport = async (params: updateReportParams, successCallBack) => {
    try {
      await updateReport(params);
      successCallBack();
      genericSuccessHandler('successfully updated report');
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}

export default new ReportStore();
