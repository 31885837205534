import { makeObservable, observable, action } from 'mobx';
import { getAllAgents, createAgent, updateAgent, deleteAgent, AgentAccountParams } from 'src/endpoints/agents';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class AgentsStore {
  data = [];

  isLoading = false;

  constructor() {
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      getAll: action,
      create: action,
      delete: action,
    });
  }

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus) => {
    this.isLoading = true;

    const content = await getAllAgents(pageNumber, pageSize, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus);
    this.data = content;

    this.isLoading = false;
  };

  create = async (partnerId, data: AgentAccountParams) => {
    try {
      await createAgent(partnerId, data);
      genericSuccessHandler('Successfully created an agent!');
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  update = async (id, data: AgentAccountParams) => {
    try {
      await updateAgent(id, data);
      genericSuccessHandler('Successfully updated an agent!');
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  delete = async (id: number) => {
    try {
      await deleteAgent(id);
      genericSuccessHandler('Successfully deleted an agent!');
      this.getAll(0, 10, '', '', '', null, null, null, null);
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}

export default new AgentsStore();
