import React from 'react';
import { Modal } from '@carbon/react';
import { useStores } from 'src/stores';

const DeleteLanguageModal = ({ isOpen, setIsOpen, phrases, languageDelitionId }) => {
  const { languagesStore } = useStores();

  const onSubmit = async () => {
    await languagesStore.deleteLanguage(languageDelitionId);
    languagesStore.isLoading = false;
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      size="sm"
      danger
      modalHeading={phrases.delete_internal_account}
      primaryButtonText={phrases.delete}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      <p>{phrases.check_if_you_want_to_continue}</p>
    </Modal>
  );
};

export default DeleteLanguageModal;
