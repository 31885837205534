import React, { useState, useEffect } from 'react';
import { Pagination } from '@carbon/react';

const usePagination = (
  phrases,
  store,
  filter,
  filterFrom,
  filterTo,
  approvalStatus,
  leadsValidity,
  paymentStatus,
  meetingStatus,
  isAnyModalOpen?,
) => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const getWhenValueChanges = (page, size): any => {
    store.getAll(
      page,
      size,
      filter,
      filterFrom,
      filterTo,
      approvalStatus,
      leadsValidity,
      paymentStatus,
      meetingStatus,
    );
  };
  let typingTimer;
  const doneTyping = 500;

  useEffect(() => {
    clearTimeout(typingTimer);
    if (filter) {
      typingTimer = setTimeout(getWhenValueChanges, doneTyping, pageNumber - 1, pageSize);
    }
    if (!filter) {
      getWhenValueChanges(pageNumber - 1, pageSize);
    }
  }, [filter, filterFrom && filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus]);

  return (
    !store.isLoading && (
      <Pagination
        className="data-table__pagination"
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText={phrases.items_per_page}
        itemRangeText={() => `${
          store.data.first === true ? 1 : pageNumber * pageSize - pageSize + 1
        }–${pageNumber === 1 ? pageSize : pageSize * pageNumber}
         of ${store.data.totalElements} items`}
        page={pageNumber}
        pageNumberText={phrases.page_number}
        pageSize={pageSize}
        pageSizes={[5, 10, 20, 30, 40, 50]}
        totalItems={store.data.totalElements}
        pageInputDisabled={isAnyModalOpen}
        pageSizeInputDisabled={isAnyModalOpen}
        disabled={isAnyModalOpen}
        onChange={(value) => {
          setPageSize(value.pageSize);
          setPageNumber(value.page);
          getWhenValueChanges(value.page - 1, value.pageSize);
        }}
      />
    )
  );
};

export default usePagination;
