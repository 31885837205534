import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Routes as ReactRouterRoutes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import PageLayout from 'src/components/layout/PageLayout';
import Login from 'src/pages/Login';
import ForgotPassword from 'src/pages/Login/ForgotPassword';
import PasswordChange from 'src/pages/Login/PasswordChange';
import ThankYouPage from 'src/pages/Login/ThankYouPage';
import AllClients from 'src/pages/AllClients';
import AddNewLead from 'src/pages/AllClients/AddNewLead';
import MortgageApplication from 'src/pages/LoanApplications/MortgageApplication';
import CashAndLoanApplication from 'src/pages/LoanApplications/CashAndLoanApplication';
import MeetingRequests from 'src/pages/MeetingRequests';
import MonthlyReports from 'src/pages/MonthlyReports';
import MonthlyInvoices from 'src/pages/MonthlyInvoices';
import InternalAccounts from 'src/pages/AccountsManagement/InternalAccounts';
import PartnersManagement from 'src/pages/PartnersManagement';
import PartnersAccounts from 'src/pages/AccountsManagement/PartnersAccounts';
import ProductManagementAddProduct from 'src/pages/ProductManagement/AddProduct';
import PartnersManagementAddPartner from 'src/pages/PartnersManagement/AddPartner';
import EligibilityAndStandardPricing from 'src/pages/OffersManagement/EligibilityAndStandardPricing';
import CreateNewOffer from 'src/pages/OffersManagement/EligibilityAndStandardPricing/CreateNewOffer';
import FeaturedOffers from 'src/pages/OffersManagement/FeaturedOffers';
import LanguageManagement from 'src/pages/LanguageManagement';
import DeepDiveLoan from 'src/pages/LoanApplications/CashAndLoanApplication/DeepDiveLoan';
import NotificationsManagement from 'src/pages/NotificationsManagement';
import AgentsAccount from 'src/pages/AccountsManagement/AgentsAccount';
import ProductManagement from 'src/pages/ProductManagement';
import FreeForm from 'src/pages/FreeForm';
import DeepDiveClient from 'src/pages/AllClients/DeepDiveClient';
import { useStores } from 'src/stores';
import { PrivilegesEnum } from 'src/privileges';
import ProtectedRoute from './ProtectedRoute';

export enum RoutesEnum {
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  PASSWORD_CHANGE = '/password-confirmation/:token',
  THANK_YOU = '/welcome',
  ALL_CLIENTS = '/all-clients',
  MORTGAGE_EDIT = 'mortgage-application/:id',
  CASH_LOAN_EDIT = 'cash-and-loan-application/:id',
  CLIENT = '/all-clients/:id',
  CASH_LOAN = '/cash-and-loan-application/deep-dive/:id',
  MORTGAGE_LOAN = '/mortgage-application/deep-dive/:id',
  MORTGAGE_APPLICATION = '/mortgage-application',
  CASH_AND_LOAN_APPLICATION = '/cash-and-loan-application',
  MEETING_REQUESTS = '/meeting-requests',
  MONTHLY_REPORTS = '/monthly-reports',
  MONTHLY_INVOICES = '/monthly-invoices',
  INTERNAL_ACCOUNTS = '/internal-accounts',
  PARTNERS_ACCOUNTS = '/partners-accounts',
  AGENTS_ACCOUNTS = '/agents-accounts',
  PRODUCT_MANAGEMENT = '/product-management',
  PRODUCT_MANAGEMENT_ADD_PRODUCT = '/product-management/add-product',
  PARTNERS_MANAGEMENT = '/partners-management',
  PARTNERS_MANAGEMENT_ADD_PARTNER = '/partners-management/add-partner',
  ELIGIBILITY_AND_STANDARD_PRICING = '/eligibility-and-standard-pricing',
  CREATE_NEW_OFFER = '/eligibility-and-standard-pricing/create-new-offer',
  EDIT_CASH_OFFER = '/eligibility-and-standard-pricing/cash-offer/:id',
  EDIT_MORTGAGE_OFFER = '/eligibility-and-standard-pricing/mortgage-offer/:id',
  FEATURED_OFFERS = '/featured-offers',
  LANGUAGE_MANAGEMENT = '/language-management',
  NOTIFICATIONS_MANAGEMENT = '/notifications-management',
  ADD_NEW_LEAD = '/all-clients/add-new-lead',
  ADD_PRODUCT = '/product-management/add-product',
  FREE_FORM = '/free-form',
}

const Routes = () => {
  const { authStore, languagesStore }:any = useStores();

  const location = useLocation();
  const navigate = useNavigate();
  const result:any = languagesStore.languages;
  const phrases = result.reduce((map, obj) => {
    map[obj.label] = obj.translation;
    return map;
  }, {});

  useEffect(() => {
    if (window.location.pathname !== RoutesEnum.FREE_FORM && window.location.pathname !== RoutesEnum.FORGOT_PASSWORD && window.location.pathname !== RoutesEnum.THANK_YOU && !window.location.pathname.includes('/password-confirmation/') && !authStore.isAuthenticated) {
      navigate(RoutesEnum.LOGIN);
      console.log('trigger');
    }
  }, [window.location.pathname]);

  return (
    <PageLayout>
      <ReactRouterRoutes>
        <Route path={RoutesEnum.LOGIN} element={<Login phrases={phrases} />} />
        <Route path={RoutesEnum.FORGOT_PASSWORD} element={<ForgotPassword phrases={phrases} />} />
        <Route path={RoutesEnum.PASSWORD_CHANGE} element={<PasswordChange phrases={phrases} />} />
        <Route path={RoutesEnum.THANK_YOU} element={<ThankYouPage phrases={phrases} />} />
        <Route
          path={RoutesEnum.ALL_CLIENTS}
          element={
            (
              <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_CLIENTS}>
                <AllClients phrases={phrases} />
              </ProtectedRoute>
            )
          }
        />
        <Route
          path={RoutesEnum.CLIENT}
          element={
            (
              <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_CLIENTS}>
                <DeepDiveClient phrases={phrases} />
              </ProtectedRoute>
            )
          }
        />
        <Route
          path={RoutesEnum.ADD_NEW_LEAD}
          element={
            (
              // <ProtectedRoute requiredPrivileges={PrivilegesEnum.WRITE_LEAD}>
              <AddNewLead phrases={phrases} />
              // </ProtectedRoute>
            )
          }
        />
        <Route
          path={RoutesEnum.MORTGAGE_EDIT}
          element={
            (
              // <ProtectedRoute requiredPrivileges={PrivilegesEnum.WRITE_LEAD}>
              <AddNewLead phrases={phrases} loan="mortgage" />
              // </ProtectedRoute>
            )
          }
        />
        <Route
          path={RoutesEnum.CASH_LOAN_EDIT}
          element={
            <AddNewLead phrases={phrases} loan="cash" />
          }
        />
        <Route
          path={RoutesEnum.MORTGAGE_APPLICATION}
          element={
            (
              <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_LOANS}>
                <MortgageApplication phrases={phrases} />
              </ProtectedRoute>
            )
          }
        />
        <Route
          path={RoutesEnum.CASH_AND_LOAN_APPLICATION}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_LOANS}>
              <CashAndLoanApplication phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.CASH_LOAN}
          element={
            (
              // <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_LOANS}>
              <DeepDiveLoan phrases={phrases} />
              // </ProtectedRoute>
            )
          }
        />
        <Route
          path={RoutesEnum.MORTGAGE_LOAN}
          element={
            (
              // <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_LOANS}>
              <DeepDiveLoan phrases={phrases} />
              // </ProtectedRoute>
            )
          }
        />
        <Route
          path={RoutesEnum.MEETING_REQUESTS}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_MEETINGS}>
              <MeetingRequests phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.MONTHLY_REPORTS}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_REPORTS}>
              <MonthlyReports phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.MONTHLY_INVOICES}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_INVOICES}>
              <MonthlyInvoices phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.INTERNAL_ACCOUNTS}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_INTERNALS}>
              <InternalAccounts phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.PARTNERS_ACCOUNTS}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_PARTNERS}>
              <PartnersAccounts phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.AGENTS_ACCOUNTS}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_AGENTS}>
              <AgentsAccount phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.PRODUCT_MANAGEMENT}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_PRODUCTS}>
              <ProductManagement phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.PRODUCT_MANAGEMENT_ADD_PRODUCT}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.WRITE_PRODUCT}>
              <ProductManagementAddProduct phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.PARTNERS_MANAGEMENT}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_PARTNERS}>
              <PartnersManagement phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.PARTNERS_MANAGEMENT_ADD_PARTNER}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.WRITE_PARTNER}>
              <PartnersManagementAddPartner phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.ELIGIBILITY_AND_STANDARD_PRICING}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_OFFERS}>
              <EligibilityAndStandardPricing phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.CREATE_NEW_OFFER}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.WRITE_OFFER}>
              <CreateNewOffer phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.EDIT_CASH_OFFER}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.WRITE_OFFER}>
              <CreateNewOffer phrases={phrases} offerType="cash" />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.EDIT_MORTGAGE_OFFER}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.WRITE_OFFER}>
              <CreateNewOffer phrases={phrases} offerType="mortgage" />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.FEATURED_OFFERS}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_OFFERS}>
              <FeaturedOffers phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.LANGUAGE_MANAGEMENT}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_LANGUAGES}>
              <LanguageManagement phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route
          path={RoutesEnum.NOTIFICATIONS_MANAGEMENT}
          element={(
            <ProtectedRoute requiredPrivileges={PrivilegesEnum.READ_NOTIFICATIONS}>
              <NotificationsManagement phrases={phrases} />
            </ProtectedRoute>
          )}
        />
        <Route path={RoutesEnum.FREE_FORM} element={<FreeForm />} />
        <Route
          path="*"
          element={authStore.isAuthenticated ? <Navigate to="/all-clients" replace /> : <Navigate to="/login" replace />}
        />
      </ReactRouterRoutes>
    </PageLayout>
  );
};

export default observer(Routes);
