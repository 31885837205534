import { makeObservable, observable, action } from 'mobx';
import { RoutesEnum } from 'src/routes';
import {
  getAllMortgages,
  LoanParams,
  createMortgagePipeline,
  deleteLoan,
  editMortgage,
  StatusParams,
  updateStatuses,
} from 'src/endpoints/loans';
import genericErrorHandler from 'src/utils/genericErrorHandler';
import genericSuccessHandler from 'src/utils/genericSuccessHandler';

class MortgagesStore {
  data = [];

  isLoading = false;

  dataById = [];

  constructor() {
    makeObservable(this, { data: observable, isLoading: observable, getAll: action });
  }

  getAll = async (pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus) => {
    this.isLoading = true;

    const content = await getAllMortgages(pageNumber, pageSize, filter, filterFrom, filterTo, approvalStatus, leadsValidity, paymentStatus, meetingStatus);
    this.data = content;

    this.isLoading = false;
  };

  create = async (data: LoanParams) => {
    try {
      await createMortgagePipeline(data);
      genericSuccessHandler('Successfully created loan!');
      window.history.back();
    } catch (error:any) {
      if (error.request.status === 400) {
        return;
      }
      genericErrorHandler(error);
    }
  };

  delete = async (id) => {
    try {
      await deleteLoan(id);
      this.getAll(0, 10, '', '', '', null, null, null, null);
      genericSuccessHandler('Successfully deleted!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  edit = async (data: LoanParams) => {
    try {
      await editMortgage(data);
      genericSuccessHandler('Successfully edited!');
      window.location.replace(RoutesEnum.MORTGAGE_APPLICATION);
    } catch (error) {
      genericErrorHandler(error);
    }
  };

  editStatus = async (data: StatusParams) => {
    try {
      await updateStatuses(data);
      this.getAll(0, 10, '', '', '', null, null, null, null);
      genericSuccessHandler('Successfully updated!');
    } catch (error) {
      genericErrorHandler(error);
    }
  };
}
export default new MortgagesStore();
