import React, { useEffect, useState } from 'react';
import { useStores } from 'src/stores';
import usePagination from 'src/utils/pagination';
import FilterMenu from 'src/utils/FilterMenu';
import { ReportData } from 'src/stores/reports';
import { observer } from 'mobx-react-lite';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSelectAll,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableToolbarMenu,
  TableToolbarAction,
  TableBatchActions,
  TableBatchAction,
  Button,
  DataTableSkeleton,
} from '@carbon/react';
import { Add, TrashCan, Download, DocumentExport } from '@carbon/react/icons';
import CreateReportModal from './CreateReportModal';
import DeleteModal from './DeleteModal';
import DownloadModal from './DownloadModal';

const MonthlyReports = observer(({ phrases }: any) => {
  const { reportsStore, partnersStore, authStore }: any = useStores();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<Partial<ReportData>>({});
  const [pageSize, setPageSize] = useState(10);
  const [multipleIds, setMultipleIds]:any = useState([]);
  const [filter, setFilter] = useState('');
  const [filterFrom, setFilterFrom]:any = useState();
  const [filterTo, setFilterTo]:any = useState();
  const [leadsValidity, setLeadsValidity]:any = useState();
  const [approvalStatus, setApprovalStatus]:any = useState();
  const [paymentStatus, setPaymentStatus]:any = useState();
  const [meetingStatus, setMeetingStatus]:any = useState();

  const headersData = [
    { key: 'id', header: 'ID' },
    { key: 'partnerName', header: phrases.partner },
    { key: 'reportName', header: phrases.report_name },
    { key: 'dateOfCreation', header: phrases.date_of_creation },
    { key: 'timeFrom', header: phrases.time_from },
    { key: 'timeTo', header: phrases.time_to },
    { key: 'products', header: phrases.product_type },
  ];

  useEffect(() => {
    reportsStore.getAll(0, 10);
    partnersStore.getAll();
  }, []);

  const findPartner = (id) => {
    const partner = Object.keys(reportsStore.data).length
    && reportsStore.data.content.find((item) => item.id === id);
    partner && setSelectedPartner(partner);
  };

  const downloadReport = () => {
    if (multipleIds.length > 1) {
      const ids = multipleIds.filter((item, index) => multipleIds.indexOf(item) === index);
      reportsStore.exportReportsZip(ids);
    } else {
      (selectedPartner.id
        && selectedPartner.reportName)
        && reportsStore.exportReport(selectedPartner.id, selectedPartner.reportName);
    }
  };

  const deleteReport = () => {
    selectedPartner.id
    && selectedPartner.reportName
    && reportsStore.deleteReport(selectedPartner.id);
  };

  const formatDate = (date) => {
    const format = date.substring(0, 10).split('-').reverse().join('-');
    const event = new Date(format);
    return event.toLocaleString('en-us', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const isAnyModalOpen = isModalOpen || isDeleteModalOpen || isDownloadModalOpen;

  return (
    <div className="data-table">
      <CreateReportModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        phrases={phrases}
        partners={partnersStore.data.content}
      />
      <DeleteModal isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen} phrases={phrases} />
      <DownloadModal
        isOpen={isDownloadModalOpen}
        setIsOpen={setIsDownloadModalOpen}
        phrases={phrases}
      />
      {reportsStore.isLoading && <DataTableSkeleton columnCount={4} rowCount={pageSize} />}
      {Object.keys(reportsStore.data).length && (
        <DataTable headers={headersData} rows={reportsStore.data.content} isSortable isSelectable>
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getSelectionProps,
            getToolbarProps,
            getBatchActionProps,
          }) => {
            const batchActionProps = getBatchActionProps();

            return (
              <TableContainer title={phrases.monthly_reports}>
                <TableToolbar {...getToolbarProps()}>
                  <TableBatchActions {...batchActionProps}>
                    <TableBatchAction
                      tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                      renderIcon={TrashCan}
                      // onClick={() => setIsDeleteModalOpen(true)}
                      onClick={() => deleteReport()}
                    >
                      {phrases.delete}
                    </TableBatchAction>
                    <TableBatchAction
                      tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                      renderIcon={Download}
                      // onClick={() => setIsDownloadModalOpen(true)}
                      // onClick={() => alert('true')}
                      onClick={() => downloadReport()}
                    >
                      {phrases.download}
                    </TableBatchAction>
                  </TableBatchActions>
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      onChange={(v) => {
                        setTimeout(() => {
                          setFilter(v.target.value);
                        }, 800);
                      }}
                      onClear={() => setFilter('')}
                    />
                    <TableToolbarMenu tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0} renderIcon={DocumentExport}>
                      <TableToolbarAction onClick={() => alert('Alert 1')}>
                        Download excel list of reports
                      </TableToolbarAction>
                    </TableToolbarMenu>
                    <FilterMenu setFilterFrom={setFilterFrom} setFilterTo={setFilterTo} comingFrom="reports" setApprovalStatus={setApprovalStatus} setLeadsValidity={setLeadsValidity} setPaymentStatus={setPaymentStatus} setMeetingStatus={setMeetingStatus} />
                    {(authStore.userInfo.roles[0] === 'ROLE_SUPER_ADMIN' || authStore.userInfo.roles[0] === 'ROLE_ACCOUNT_EXECUTIVE') && (
                    <Button
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      renderIcon={Add}
                      onClick={() => setIsModalOpen(true)}
                    >
                      {phrases.create_monthly}
                    </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>
                {!reportsStore.isLoading && (
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        <TableSelectAll {...getSelectionProps()} />
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableSelectRow
                            {...getSelectionProps({
                              row,
                              onClick: () => { findPartner(row.cells[0].value); setMultipleIds([...multipleIds, row.id]); },
                            })}
                          />
                          {/* onChange={({ target: { value } }) => setData({ ...data, firstName: value })} */}
                          {row.cells.map((cell) => {
                            switch (cell.info.header) {
                              case 'timeFrom':
                                return (
                                  <TableCell key={cell.id}>{formatDate(cell.value)}</TableCell>
                                );
                              case 'timeTo':
                                return (
                                  <TableCell key={cell.id}>{formatDate(cell.value)}</TableCell>
                                );
                              case 'products':
                                return (
                                  <TableCell key={cell.id}>
                                    {cell.value.map((item) => {
                                      if (item === 1) {
                                        return `cash_loan ${cell.value.length > 1 && ', '}`;
                                      }
                                      if (item === 2) {
                                        return 'mortgage';
                                      }
                                      return cell.value;
                                    })}
                                  </TableCell>
                                );
                              case 'dateOfCreation':
                                return (<TableCell key={cell.id}>{formatDate(cell.value)}</TableCell>);
                              default:
                                return <TableCell key={cell.id}>{cell.value}</TableCell>;
                            }
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            );
          }}
        </DataTable>
      )}
      {usePagination(phrases, reportsStore, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus, isAnyModalOpen)}
    </div>
  );
});

export default MonthlyReports;
