import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Container, Row } from 'react-grid-system';
import { TextInput, PasswordInput, Button } from '@carbon/react';
import { ArrowRight } from '@carbon/react/icons';
import { useStores } from 'src/stores';
import { LoginParams } from 'src/endpoints/auth';
import { RoutesEnum } from 'src/routes';
import Header from 'src/components/layout/Header';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;

  > div {
    width: 530px;
    margin: auto;
    background-color: white;

    @media screen and (max-width: 672px) {
      width: 100%;
    }
  }
`;

const StyledBody = styled.div`
  padding: 16px;
  button {
    margin: 52px 0 24px;
  }
  .cds--text-input--password__visibility__toggle {
    margin: 0;
  }
  h3 {
    margin-bottom: 50px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 24px;
`;

const Login = observer(({ phrases }) => {
  const { authStore }: any = useStores();
  const [loginParams, setLoginParams] = useState<LoginParams>({ email: '', password: '' });

  const onSubmit = () => {
    if (!loginParams.email || !loginParams.password) {
      setTimeout(authStore.login(loginParams), 1000);
    }
    authStore.login(loginParams);
  };

  const forgotPasswordHandler = () => {
    return <Navigate to="/all-clients" replace />;
  };

  return (
    <>
      <Header />
      <StyledContainer>
        <div>
          <StyledBody>
            <h3>{phrases.login_to_finbox}</h3>
            <Container>
              <StyledRow>
                <TextInput
                  type="text"
                  labelText={phrases.your_email}
                  placeholder={phrases.email}
                  value={loginParams.email}
                  onChange={({ target }) => setLoginParams({ ...loginParams, email: target.value })}
                />
              </StyledRow>
              <StyledRow>
                <PasswordInput
                  type="password"
                  labelText={phrases.password}
                  placeholder={phrases.password}
                  onChange={({ target }) => setLoginParams({ ...loginParams, password: target.value })}
                />
              </StyledRow>
              <Row>
                <Button type="button" renderIcon={ArrowRight} onClick={onSubmit}>
                  {phrases.continue}
                </Button>
              </Row>
            </Container>
            <a href={RoutesEnum.FORGOT_PASSWORD} onClick={forgotPasswordHandler}>{phrases.forgot_password}</a>
          </StyledBody>
        </div>
      </StyledContainer>
    </>
  );
});

export default Login;
