import React from 'react';
import { Modal } from '@carbon/react';
import { useStores } from 'src/stores';

const DeleteMeetingModal = ({ meetingId, isOpen, setIsOpen, phrases }) => {
  const { meetingsStore } = useStores();

  const onSubmit = async () => {
    await meetingsStore.delete(meetingId.id);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      size="sm"
      danger
      modalHeading={phrases.are_you_sure}
      primaryButtonText={phrases.delete}
      secondaryButtonText={phrases.cancel}
      onRequestSubmit={onSubmit}
      onRequestClose={() => setIsOpen(false)}
    >
      <p>{phrases.check_if_you_want_to_continue}</p>
    </Modal>
  );
};

export default DeleteMeetingModal;
