import React, { useEffect, useState } from 'react';
import { useStores } from 'src/stores';
import usePagination from 'src/utils/pagination';
import FilterMenu from 'src/utils/FilterMenu';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import {
  DataTable,
  TableContainer,
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableToolbarMenu,
  TableToolbarAction,
  DataTableSkeleton,
} from '@carbon/react';
import { Edit, TrashCan, DocumentExport } from '@carbon/react/icons';
import styled from 'styled-components';
import CreateNewMeetingModal from 'src/pages/MeetingRequests/CreateNewMeetingModal';
import StatusChangeModal from 'src/utils/StatusChangeModal';
import DeleteLoanModal from './DeleteLoanModal';

const StatusIndicator = styled.div`
  width: max-content;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 24px;
  color: ${({ color }) => color};
  background-color: ${({ bg }) => bg};
`;
const StyledTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  > svg {
    cursor: pointer;
  }
`;

const CashAndLoanApplication = observer(({ phrases }: any) => {
  const { loansStore }: any = useStores();
  const [pageSize, setPageSize] = useState(10);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loanId, setLoanId] = useState();
  const [filter, setFilter] = useState('');
  const [filterFrom, setFilterFrom]:any = useState();
  const [filterTo, setFilterTo]:any = useState();
  const [leadsValidity, setLeadsValidity]:any = useState();
  const [approvalStatus, setApprovalStatus]:any = useState();
  const [paymentStatus, setPaymentStatus]:any = useState();
  const [meetingStatus, setMeetingStatus]:any = useState();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState({});
  const [isStatusChangeModalopen, setIsStatusChangeModalOpen] = useState(false);
  const [statusType, setStatusType] = useState({ type: '', id: '', productType: '', meetingInfo: '' });
  const navigate = useNavigate();

  useEffect(() => {
    loansStore.getAll(0, 10);
  }, []);

  const headersData = [
    { key: 'id', header: 'ID' },
    { key: 'firstName', header: phrases.name },
    { key: 'surname', header: phrases.surname },
    { key: 'partnerId', header: phrases.sent_by_partner },
    { key: 'email', header: phrases.email_address },
    { key: 'phone', header: phrases.phone_number },
    { key: 'dateOfBirth', header: phrases.date_of_birth },
    { key: 'productId', header: phrases.loan_type },
    { key: 'loanAmount', header: phrases.loan_amount },
    { key: 'meetingStatus', header: phrases.meeting_status },
    { key: 'leadsValidity', header: phrases.leads_validity },
    { key: 'approvalStatus', header: phrases.approval_status },
  ];

  const flattenedData = Object.keys(loansStore.data).length
  && toJS(loansStore.data.content).map((ele) => {
    delete ele.client.id;
    if (ele.meeting) {
      ele.meeting.meetingId = ele.meeting.id;
      delete ele.meeting.id;
    }
    return { ...ele, ...ele.client, ...ele.meeting };
  });
  const formatDate = (date) => {
    const format = date.substring(0, 10).split('-').reverse().join('-');
    const event = new Date(format);
    return event.toLocaleString('en-GB', { year: 'numeric', day: 'numeric', month: 'long' });
  };

  const isAnyModalOpen = isDeleteModalOpen || isStatusChangeModalopen || isCreateModalOpen;

  return (
    <div className="data-table">
      <DeleteLoanModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        loanId={loanId}
        phrases={phrases}
        typeOfLoan="cash"
      />
      <StatusChangeModal
        isOpen={isStatusChangeModalopen}
        phrases={phrases}
        setIsOpen={setIsStatusChangeModalOpen}
        statusType={statusType}
        setStatusType={setStatusType}
      />
      <CreateNewMeetingModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        phrases={phrases}
        meetingInfo={meetingInfo}
      />
      {loansStore.isLoading && <DataTableSkeleton columnCount={8} rowCount={pageSize} />}
      {Object.keys(loansStore.data).length && (
        <DataTable
          headers={headersData}
          rows={flattenedData}
          isSortable
          isSelectable
          useStaticWidth
        >
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getBatchActionProps,
          }) => {
            const batchActionProps = getBatchActionProps();

            return (
              <TableContainer title="Cash loan leads">
                <TableToolbar {...getToolbarProps()}>
                  <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                    <TableToolbarSearch
                      tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      onChange={(v) => {
                        setTimeout(() => {
                          setFilter(v.target.value);
                        }, 1500);
                      }}
                      onClear={() => setFilter('')}
                    />
                    <TableToolbarMenu tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0} renderIcon={DocumentExport}>
                      <TableToolbarAction onClick={() => alert('Alert 1')}>
                        Action 1
                      </TableToolbarAction>
                      <TableToolbarAction onClick={() => alert('Alert 2')}>
                        Action 2
                      </TableToolbarAction>
                      <TableToolbarAction onClick={() => alert('Alert 3')}>
                        Action 3
                      </TableToolbarAction>
                    </TableToolbarMenu>
                    <FilterMenu setFilterFrom={setFilterFrom} setFilterTo={setFilterTo} comingFrom="loans" setApprovalStatus={setApprovalStatus} setLeadsValidity={setLeadsValidity} setPaymentStatus={setPaymentStatus} setMeetingStatus={setMeetingStatus} />
                  </TableToolbarContent>
                </TableToolbar>
                {Object.keys(loansStore.data).length && !loansStore.isLoading && (
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                        ))}
                        <TableHeader />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.cells.map((cell) => {
                            switch (cell.info.header) {
                              case 'productId':
                                return <TableCell>Cash loan</TableCell>;
                              case 'dateOfBirth':
                                return <TableCell>{formatDate(cell.value)}</TableCell>;
                              case 'firstName':
                              case 'surname':
                                return (
                                  <TableCell
                                    onClick={() => {
                                      const data = loansStore.data.content.find(
                                        ({ id }) => row.id === id,
                                      ) || {};
                                      navigate(`/cash-and-loan-application/deep-dive/${data.id}`, {
                                        replace: true,
                                      });
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {cell.value}
                                  </TableCell>
                                );
                              case 'meetingStatus':
                                return (
                                  <TableCell
                                    key={cell.id}
                                    onClick={() => {
                                      if (!cell.value) {
                                        const data = loansStore.data.content.find(({ id }) => row.id === id) || {};
                                        setMeetingInfo(data);
                                        setIsCreateModalOpen(true);
                                      } else {
                                        setIsStatusChangeModalOpen(true);
                                        setStatusType({ type: 'meeting', id: row.id, productType: row.cells[7].value, meetingInfo: loansStore.data.content.find((ele) => row.id === ele.id) });
                                      }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {(() => {
                                      switch (cell.value) {
                                        case 'scheduled':
                                          return (
                                            <StatusIndicator color="#121619" bg="#DDE1E6">
                                              Scheduled
                                            </StatusIndicator>
                                          );
                                        case 'not_attended':
                                          return (
                                            <StatusIndicator color="#DA1E28" bg="#FFF1F1">
                                              Not attended
                                            </StatusIndicator>
                                          );
                                        case undefined:
                                          return <TableCell>n/a</TableCell>;
                                        case 'completed':
                                          return (
                                            <StatusIndicator color="#095520" bg="#A8E5B8">
                                              Completed
                                            </StatusIndicator>
                                          );
                                        default:
                                          return (
                                            <TableCell>
                                              {cell.value}
                                            </TableCell>
                                          );
                                      }
                                    })()}
                                  </TableCell>
                                );
                              case 'leadsValidity':
                                return (
                                  <TableCell
                                    key={cell.id}
                                    onClick={() => {
                                      setIsStatusChangeModalOpen(true);
                                      setStatusType({
                                        type: 'leadsValidity',
                                        id: row.id,
                                        productType: row.cells[7].value,
                                        meetingInfo: '',
                                      });
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {(() => {
                                      switch (cell.value) {
                                        case 'na':
                                          return <StyledTableCell>n/a</StyledTableCell>;
                                        case 'valid':
                                          return (
                                            <StatusIndicator color="#095520" bg="#A8E5B8">
                                              Valid
                                            </StatusIndicator>
                                          );
                                        case 'other_agency':
                                          return (
                                            <StatusIndicator color="#DA1E28" bg="#FFF1F1">
                                              Other agency
                                            </StatusIndicator>
                                          );
                                        default:
                                          return (
                                            <StatusIndicator color="#DA1E28" bg="#FFF1F1">
                                              Branch
                                            </StatusIndicator>
                                          );
                                      }
                                    })()}
                                  </TableCell>
                                );
                              case 'approvalStatus':
                                return (
                                  <TableCell
                                    key={cell.id}
                                    onClick={() => {
                                      setIsStatusChangeModalOpen(true);
                                      setStatusType({
                                        type: 'approvalStatus',
                                        id: row.id,
                                        productType: row.cells[7].value,
                                        meetingInfo: '',
                                      });
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {(() => {
                                      switch (cell.value) {
                                        case 'na':
                                          return <StatusIndicator color="" bg="">n/a</StatusIndicator>;
                                        case 'application_completed':
                                          return (
                                            <StatusIndicator color="#161616" bg="#E0E0E0">
                                              Application completed
                                            </StatusIndicator>
                                          );
                                        case 'documents_collection':
                                          return (
                                            <StatusIndicator color="#121619" bg="#DDE1E6">
                                              Documents collection
                                            </StatusIndicator>
                                          );
                                        case 'loan_approved':
                                          return (
                                            <StatusIndicator color="##171414" bg="#E5E0DF">
                                              Loan approved
                                            </StatusIndicator>
                                          );
                                        case 'rejected':
                                          return (
                                            <StatusIndicator color="#DA1E28" bg="#FFF1F1">
                                              Rejected
                                            </StatusIndicator>
                                          );
                                        case 'in_progress':
                                          return (
                                            <StatusIndicator color="#0E6027" bg="#A7F0BA">
                                              In progress
                                            </StatusIndicator>
                                          );
                                        default:
                                          return (
                                            <StatusIndicator color="#0E6027" bg="#A7F0BA">
                                              Loan disbursed
                                            </StatusIndicator>
                                          );
                                      }
                                    })()}
                                  </TableCell>
                                );
                              default:
                                return <TableCell key={cell.id}>{cell.value}</TableCell>;
                            }
                          })}
                          <StyledTableCell>
                            <TrashCan
                              onClick={() => {
                                setIsDeleteModalOpen(true);
                                setLoanId(row.id);
                              }}
                            />
                            <Edit
                              onClick={() => navigate(`/cash-and-loan-application/${row.id}`, { replace: true })}
                            />
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            );
          }}
        </DataTable>
      )}
      {usePagination(phrases, loansStore, filter, filterFrom, filterTo, leadsValidity, approvalStatus, paymentStatus, meetingStatus, isAnyModalOpen)}
    </div>
  );
});

export default CashAndLoanApplication;
